<div class="container-fluid view-area">
  <div class="row">
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active btn-info" data-toggle="tab">Reset Password</a>
        </li>
      </ul>
    </div>
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">

          <div *ngIf="isError" class="alert alert-danger alert-dismissible">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Error!</strong> {{errorMessage}}
          </div>
          
          <div *ngIf="resetSuccessful" class="alert alert-info alert-dismissible">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Information!</strong> {{infoMessage}}<br>
            <button class="btn btn-info" (click)="goToLogin()">Login now</button>
          </div>

          <h5 *ngIf="!resetSuccessful" class="card-title text-center">Set new password</h5>
          <form *ngIf="!resetSuccessful" class="form-signin" (ngSubmit)="resetForm.form.valid && resetPassword()" #resetForm="ngForm">

            <div class="form-group">
              <label for="password">Enter new password</label>
              <input type="password" class="form-control" id="password" [(ngModel)]="model.password" name="location"
                #password="ngModel" required>
              <div *ngIf="resetForm.submitted" [hidden]="password.valid" class="alert alert-danger">
                Password is required
              </div>
            </div>

            <div class="form-group">
              <label for="confirmPassword">Confirm password</label>
              <input type="password" class="form-control" id="confirmPassword" [(ngModel)]="model.confirmPassword"
                name="confirmPassword" #confirmPassword="ngModel" required>
              <div *ngIf="resetForm.submitted" [hidden]="model.password == model.confirmPassword" class="alert alert-danger">
                Passwords do not match
              </div>
            </div>

            <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase"
              type="submit">{{btnText}}</button>
          </form>
        </div>

      </div>
    </div>
  </div>

  <jw-modal id="message-modal">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
            <div class="card-header bg-info text-white">Information message</div>
            <div class="card-body text-info">
                {{infoMessage}}
            </div>
          </div><br />
          <button class="btn btn-info" (click)="closeModalAndNavigate();">Close</button>
      </div>
    </div>
  </jw-modal>