<div class="container-fluid view-area">
  <div class="row">
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/currencies']">Currencies List</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active btn-info" data-toggle="tab">{{btnText}}</a>
        </li>
      </ul>
    </div>
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <div *ngIf="isError" class="alert alert-danger alert-dismissible">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Error!</strong> {{errorMessage}}
          </div>
          <h5 class="card-title text-center">Currency</h5>

          <form class="form-signin" (ngSubmit)="currencyForm.form.valid && saveCurrency(currencyForm)" #currencyForm="ngForm">

            <div class="form-group">
              <label for="currency">Code</label>
              <input type="text" class="form-control" id="currency" [(ngModel)]="model.currency" name="currency"
                #currency="ngModel" maxlength="3" required>
              <div *ngIf="currencyForm.submitted" [hidden]="currency.valid" class="alert alert-danger">
                Currency code is required
              </div>
            </div>

            <div class="form-group">
              <label for="currency">Currency</label>
              <input type="text" class="form-control" id="currencyName" [(ngModel)]="model.currencyName" name="currencyName"
                #currencyName="ngModel" maxlength="50" required>
              <div *ngIf="currencyForm.submitted" [hidden]="currency.valid" class="alert alert-danger">
                Currency name is required
              </div>
            </div>
            
            <div class="form-group">
              <div class="row">
                <div class="col-offset-2 col-md-9">
                  <div class="btn-group" data-toggle="buttons">
                    <button (click)="toggleDefault()" class="btn btn-success btn-sm">
                      <i *ngIf="!model.isDefault" class="fa fa-square text-white"></i>
                      <i *ngIf="model.isDefault" class="fa fa-check text-white"></i>
                    </button>
                    &nbsp;Is default currency?
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-offset-2 col-md-9">
                  <div class="btn-group" data-toggle="buttons">
                    <button (click)="toggleNational()" class="btn btn-success btn-sm">
                      <i *ngIf="!model.isNational" class="fa fa-square text-white"></i>
                      <i *ngIf="model.isNational" class="fa fa-check text-white"></i>
                    </button>
                    &nbsp;Is national currency?
                  </div>
                </div>
              </div>
            </div>

            <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase" type="submit">{{btnText}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>