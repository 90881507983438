<jw-modal id="projecti-info-modal">

  <!--Loading project data -->
  <div class="row">
    <div class="col-md-12">
      <span class="float-right margin-right-15">
        <button class="btn btn-danger" (click)="closeModal();">Close view</button>
      </span>
    </div>
  </div>
  <div class="row small">
    <div class="col margin-top-10">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">

            <h6 class="text-info"><b>Project data</b></h6>
            <table class="table table-striped">
              <tr>
                <td><b>Title: </b></td>
                <td>{{project.title}}</td>
                <td><b>Currency: </b></td>
                <td>{{project.defaultCurrency}}</td>
              </tr>
              <tr>
                <td><b>Description: </b></td>
                <td colspan="3">{{project.description}}</td>
              </tr>
            </table>

            <h6 class="text-info margin-top-10"><b>Project locations</b></h6>
            <table *ngIf="locations.length > 0" class="table table-striped">
              <tr>
                <th>Location</th>
              </tr>
              <tr *ngFor="let location of locations">
                <td>{{location.name}}</td>
              </tr>
            </table>
            <span class="text-info"><i>No locations found</i></span>

            <h6 class="text-info margin-top-10"><b>Project sectors</b></h6>
            <table *ngIf="sectors.length > 0" class="table table-striped">
              <tr>
                <th>Sector name</th>
                <th class="text-center">Funds percentage</th>
              </tr>
              <tr *ngFor="let sector of sectors">
                <td>{{sector.sectorName}}</td>
                <td class="text-center">{{sector.fundsPercentage}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="sectors.length == 0">
              <i>No sectors found</i>
            </span>

            <h6 class="text-info margin-top-10"><b>Budgets</b></h6>
            <table *ngIf="budgets.length > 0" class="table table-striped">
              <tr>
                <th>Type</th>
                <th>Dated</th>
                <th class="text-right">Amount</th>
              </tr>
              <tr *ngFor="let budget of budgets">
                <td>{{budget.budgetType}}</td>
                <td>{{getLongDateString(budget.endDate)}}</td>
                <td class="text-right">{{formatNumber(budget.amount)}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="budgets.length == 0">
              <i>No budgets found</i>
            </span>

            <h6 class="text-info margin-top-10"><b>Transactions</b></h6>
            <table *ngIf="transactions.length > 0" class="table table-striped">
              <tr>
                <th>Type</th>
                <th>Dated</th>
                <th class="text-right">Amount</th>
              </tr>
              <tr *ngFor="let transaction of transactions">
                <td>{{transaction.transactionType}}</td>
                <td>{{getLongDateString(transaction.dated)}}</td>
                <td class="text-right">{{formatNumber(transaction.amount)}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="transactions.length == 0">
              <i>No transactions found</i>
            </span>

            <h6 class="text-info margin-top-10"><b>Funders</b></h6>
            <table *ngIf="funders.length > 0" class="table table-striped">
              <tr>
                <th>Funder</th>
              </tr>
              <tr *ngFor="let org of funders">
                <td>{{org.name}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="funders.length == 0">
              <i>No funders found</i>
            </span>

            <h6 class="text-info margin-top-10"><b>Implementers</b></h6>
            <table *ngIf="implementers.length > 0" class="table table-striped">
              <tr>
                <th>Implementer</th>
              </tr>
              <tr *ngFor="let org of implementers">
                <td>{{org.name}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="funders.length == 0">
              <i>No implementers found</i>
            </span>

            <h6 class="text-info margin-top-10"><b>Project documents</b></h6>
            <table *ngIf="documents.length > 0" class="table table-striped">
              <tr>
                <th>Document title</th>
                <th>Url</th>
              </tr>
              <tr *ngFor="let document of documents">
                <td>{{document.documentTitle}}</td>
                <td>{{document.documentUrl}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="documents.length == 0">
              <i>No documents found</i>
            </span>

          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 margin-top-10">
      <span class="float-right margin-right-15">
        <button class="btn btn-danger" (click)="closeModal();">Close view</button>
      </span>
    </div>

  </div>

</jw-modal>