<div class="container-fluid">
  <div class="row">
    <nav class="navbar fixed-top navbar-expand-sm navbar-dark bg-info">
      <a class="navbar-brand" [routerLink]="['/home']">{{aimsTitle}}</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse">☰</button>
      <div class="collapse navbar-collapse" id="navbar-collapse">
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/home']" [ngClass]="{'active-link': isHomeActive == true}">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/projects']" [ngClass]="{'active-link': isProjectsActive == true}">View
              projects</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [ngClass]="{'active-link': isReportActive == true}"
              data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">View
              reports</a>
            <div class="dropdown-menu">
              <h5 class="dropdown-header">Projects</h5>
              <a class="dropdown-item" [routerLink]="['sectors-report']">Sectors report</a>
              <a class="dropdown-item" [routerLink]="['/locations-report']">Locations report</a>
              <a class="dropdown-item" [routerLink]="['/time-trend-report']">Time trend report</a>
              <a class="dropdown-item" [routerLink]="['/budget-report']">Budget report</a>
              <a class="dropdown-item" [routerLink]="['/envelope-report']">Envelope report</a>
              <a class="dropdown-item" [routerLink]="['/data-export']">Data export</a>
            </div>
          </li>
          <li *ngIf="isLoggedIn && !isUnAffiliated" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [ngClass]="{'active-link': isEntryActive == true}"
              data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Data entry</a>
            <div class="dropdown-menu">
              <h5 class="dropdown-header">Data entry</h5>
              <a class="dropdown-item" [routerLink]="['/new-project']">Add/edit project data</a>
              <a class="dropdown-item" [routerLink]="['/envelope']">Add/edit envelope data</a>
            </div>
          </li>
          <li *ngIf="isLoggedIn && !isUnAffiliated" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [ngClass]="{'active-link': isManagementActive == true}"
              data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Management</a>
            <div class="dropdown-menu">
              <h5 *ngIf="permissions.canManage" class="dropdown-header">Field management</h5>
              <a *ngIf="permissions.canEditLocation" class="dropdown-item" [routerLink]="['/locations']">Locations</a>
              <a *ngIf="permissions.canEditLocation" class="dropdown-item" [routerLink]="['/sublocations']">Sub-locations</a>
              <a *ngIf="permissions.canEditSector" class="dropdown-item" [routerLink]="['/sector-types']">Sector
                types</a>
              <a *ngIf="permissions.canEditSector" class="dropdown-item" [routerLink]="['/sectors']">Sectors</a>
              <a *ngIf="permissions.canEditSector" class="dropdown-item" [routerLink]="['/sector-mappings']">Sector mappings</a>
              <a *ngIf="permissions.canEditCurrency" class="dropdown-item" [routerLink]="['/currencies']">Currencies</a>
              <a *ngIf="permissions.canEditCurrency" class="dropdown-item"
                [routerLink]="['/exchange-rate-settings']">Exchange rates</a>
              <a *ngIf="permissions.canEditFundingType" class="dropdown-item" [routerLink]="['/funding-types']">Funding
                types</a>
              <a *ngIf="permissions.canEditYear" class="dropdown-item" [routerLink]="['/financial-year-settings']">Financial
                years</a>
              <!--<a *ngIf="permissions.canEditYear" class="dropdown-item" [routerLink]="['/financial-years']">Financial
                years</a>-->
              <a *ngIf="permissions.canEditCustomFields" class="dropdown-item" [routerLink]="['/markers']">Markers</a>
              <a *ngIf="permissions.canEditCustomFields" class="nav-link" class="dropdown-item" [routerLink]="['/help-text']">Help text</a>
              <div class="dropdown-divider"></div>
              <h5 class="dropdown-header">Notifications management</h5>
              <a class="dropdown-item" [routerLink]="['/notification']">Notifications</a>
              <a class="dropdown-item" [routerLink]="['/contact-messages']">Pending contact messages</a>
              <div class="dropdown-divider"></div>
              <h5 *ngIf="permissions.canManage" class="dropdown-header">Users & organizations management</h5>
              <h5 *ngIf="!permissions.canManage" class="dropdown-header">User management</h5>
              <a class="dropdown-item" [routerLink]="['/manage-account']">Account management</a>
              <a *ngIf="permissions.canManageUsers" class="dropdown-item" [routerLink]="['/manage-users']">Manage users</a>
              <a *ngIf="permissions.canEditOrganization" class="dropdown-item"
                [routerLink]="['/organization-types']">Organization types</a>
              <a *ngIf="permissions.canEditOrganization" class="dropdown-item"
                [routerLink]="['/organizations']">Organizations</a>
              <a *ngIf="permissions.canEditEnvelopeType" class="dropdown-item"
                [routerLink]="['/envelope-types']">Envelope types</a>
              <div class="dropdown-divider"></div>
              <h5 *ngIf="permissions.canManage" class="dropdown-header">AIMS management</h5>
              <a *ngIf="permissions.canDoHomePageSettings" class="dropdown-item"
                [routerLink]="['/home-page-settings']">Homepage text</a>
                <a *ngIf="permissions.canEditDocument" class="dropdown-item" [routerLink]="['/document-links']">Homepage
                  links</a>
              <a *ngIf="permissions.canDoSMTPSettings" class="dropdown-item" [routerLink]="['/smtp-settings']">Email
                settings</a>
              <a *ngIf="permissions.canDoIATISettings" class="dropdown-item" [routerLink]="['/iati-settings']">IATI
                settings</a>
              <a *ngIf="permissions.canEditEmailMessage" class="dropdown-item" [routerLink]="['/email-messages']">Email
                messages</a>
              <a *ngIf="permissions.canEditEmailMessage" class="dropdown-item" [routerLink]="['/email-users']">Email
                users</a>
              <a *ngIf="permissions.canEditSponsors" class="dropdown-item" [routerLink]="['/sponsors']">Sponsors
                management</a>
              <h5 *ngIf="permissions.canTakeBackup" class="dropdown-header">AIMS database management</h5>
              <a *ngIf="permissions.canTakeBackup"  class="dropdown-item" [routerLink]="['/data-backup']">
                Data backup & restore
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/contact']" [ngClass]="{'active-link': isContactActive == true}">Contact
              us</a>
          </li>
          <!--<li cls="nav-item active"> 
              <a class="nav-link" [routerLink]="['/data-import']">Data import</a>
          </li>-->
        <!--<li *ngIf="permissions.canTakeBackup" class="nav-item"> 
            <a [ngClass]="{'active-link': isBackupActive == true}" class="nav-link" [routerLink]="['/data-backup']">Data backup</a>
        </li> -->
         <!-- <li class="nav-item" *ngIf="permissions.canEditHelp">
            <a class="nav-link" [routerLink]="['/manage-help']" [ngClass]="{'active-link': isHelpActive == true}">Manage
              help</a>
          </li>-->
        </ul>
        <ul class="nav navbar-nav ml-auto">
          <li *ngIf="isLoggedIn && notificationsCount > 0" class="nav-item active">
            <a class="nav-link" [routerLink]="['/notification']">Notifications <span *ngIf="notificationsCount > 0"
                class="badge badge-pill badge-danger">{{notificationsCount}}</span></a>
          </li>
          <li *ngIf="isLoggedIn" class="nav-item"><span class="nav-link small highlight-color margin-top-5"
              title="You are logged in as {{loggedInAsFullName}}">Logged in as {{loggedInAs}}</span></li>
          <li *ngIf="!isLoggedIn" class="nav-item"> <a class="nav-link" [routerLink]="['/login']">Login / Register</a>
          <li *ngIf="isLoggedIn" class="nav-item"> <a class="nav-link" [routerLink]="" (click)="logout($event)">Logout</a>
        </ul>
      </div>
    </nav>
  </div>
</div>

<div class="container-fluid">
  <router-outlet></router-outlet>
</div>