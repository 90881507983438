<block-ui>
  <div *ngIf="currentTab == tabConstants.FINANCIALS" class="card padding-20 margin-top-5">
    
    <div class="col-lg-12 col-md-12 col-sm-12">
      <form class="form-signin" (ngSubmit)="saveDisbursements(financialsForm)" #financialsForm="ngForm">
        <div class="col-offset-2 col-lg-10 col-md-10">
          <div class="input-group form-inline mb-3">
            <label class="col-md-3">Currency</label>
            <input [disabled]="true" type="text" name="currency" class="form-control col-md-4"
            [(ngModel)]="disbursementModel.currency" #currency="ngModel" id="currency" required />
            <span class="margin-left-2 col-md-2" tooltip="{{projectHelp.projectCurrency}}" [options]="tooltipOptions">
              <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
            </span>
          </div>

          <div class="input-group form-inline mb-3">
            <label class="col-md-3">Project value</label>
            <input class="form-control col-md-4" type="text" [readonly]="true" name="projectValue"
              [(ngModel)]="disbursementModel.projectValue" #projectValue="ngModel" id="projectValue" required />
              <span class="margin-left-2 col-md-2" tooltip="{{projectHelp.projectValue}}" [options]="tooltipOptions">
                <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
              </span>
            </div>

          <div class="row">
            <div class="col-md-12">
              <div
                *ngIf="(financialsForm.submitted || disbursementsTotalOnLoad > 0 || disbursementsInvalidOnNext) && (disbursementsTotal != disbursementModel.projectValue)"
                class="alert alert-danger alert-dismissible">
                <button type="button" class="close" data-dismiss="alert">&times;</button>
                Disbursements must be equal to project total value.
              </div>
            </div>
          </div>

          <div *ngFor="let disbursement of projectDisbursements; let i = index;" class="form-group form-inline">
            <label class="col-md-3">
              {{disbursement.financialYear}}&nbsp;
              <span *ngIf="disbursement.disbursementType == 1">Actual</span>
              <span *ngIf="disbursement.disbursementType == 2">Planned</span>
            </label>
            <!--pattern="^\d*(\.\d{0,2})?$"-->
            <input type="text" pattern="[0-9]*"
              oninput="validity.valid || (value = this.value.slice(0, -1))"  maxlength="11" (input)="calculateDisbursementsTotal()"
              name="{{disbursement.year}}-{{disbursement.disbursementType}}" placeholder="Example (12345)" [(ngModel)]="disbursement.amount"
              class="form-control col-md-5" id="d-{{disbursement.year}}-{{disbursement.disbursementType}}" required />
          
            <span *ngIf="isSourceAvailable && i == 0" class="col-md-1">
              <button class="btn btn-success" title="Source data available" (click)="showSource()" type="button"><i
                  class="fa fa-database"></i></button>
            </span>
          
            <span class="margin-left-2 col-md-1" *ngIf="disbursement.disbursementType == disbursementTypeCodes.ACTUAL"
              tooltip="({{help.disbursementActual}} {{disbursement.financialYear}})" [options]="tooltipOptions">
              <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
            </span>

            <span class="margin-left-2 col-md-1" *ngIf="disbursement.disbursementType == disbursementTypeCodes.PLANNED" 
              tooltip="({{help.disbursementPlanned}} {{disbursement.financialYear}})" [options]="tooltipOptions">
              <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
            </span>
          </div>

          <div class="form-group form-inline">
            <label class="col-md-3">Total: </label>
            <input type="text" [readonly]="true" name="disbursementTotal" [(ngModel)]="disbursementsTotal"
              class="form-control col-md-5" />
          </div>
          
            <div class="form-group form-inline">
              <label class="col-md-3">&nbsp;</label>
              <button
                [class.disable]="(disbursementsTotal < disbursementModel.projectValue || disbursementsTotal > disbursementModel.projectValue)"
                type="submit" class="btn btn-info text-uppercase btn-block col-md-5">Save disbursements</button>
            </div>

            <div class="form-group form-inline">
              <label class="col-md-3">&nbsp;</label>
              <button class="btn btn-warning text-uppercase btn-block col-md-5" (click)="splitRemainingAmountEqually()" type="button">
                Split remaining amount equally
              </button>
            </div>

            <div class="form-group form-inline">
              <label class="col-md-3"></label>
              <button class="btn btn-info text-uppercase btn-block col-md-5" (click)="proceedToNext()" type="button">
                Next >>
              </button>
            </div>
          </div>

      </form>
    </div>
  </div>

  <div *ngIf="currentTab == tabConstants.FINANCIALS_SOURCE" class="row margin-top-10">
    <div class="col-lg-6 col-md-6 iati-box-medium small">
      <table class="table table-bordered">
        <tr *ngFor="let project of aimsProjects">
          <td>
            <b>Project Title: </b>{{project.title}}<br />
            <span class="badge badge-warning">AIMS</span>
            <table *ngIf="project.disbursements.length > 0" class="table table-bordered">
              <tr>
                <th>Year</th>
                <th>Amount</th>
                <th>Disbursement type</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let disbursement of project.disbursements">
                <td>
                  {{disbursement.year}}
                </td>
                <td>
                  {{disbursement.amount | number:0}}
                </td>
                <td>
                  {{disbursementTypeConstants[disbursement.disbursementType]}}
                </td>
                <td>
                  <button [class.disable]="disbursement.selected" id="adisbursement-{{project.id}}-{{disbursement.year}}-{{disbursement.disbursementType}}"
                    (click)="enterAIMSDisbursement($event)" class="btn btn-success btn-sm">Enter >></button>
                </td>
              </tr>
            </table>
            <div class="text-info" *ngIf="project.disbursements.length == 0"><i>No disbursements available</i>
            </div>
          </td>
        </tr>
      </table>

      <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
        <tr *ngFor="let project of iatiProjects">
          <td>
            <b>Project Title: </b>{{project.title}} <br />
            <span class="badge badge-warning">IATI</span>
            <table *ngIf="project.disbursementTransactions.length > 0" class="table table-bordered">
              <tr>
                <th>Type</th>
                <th>Currency</th>
                <th>Amount</th>
                <th>Dated</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let disbursement of project.disbursementTransactions">
                <td [ngClass]="{'bg-warning': !disbursement.transactionType}">
                  {{disbursement.transactionType}}
                </td>
                <td [ngClass]="{'bg-warning': !disbursement.currency}">
                  {{displayNull(disbursement.currency)}}
                </td>
                <td [ngClass]="{'bg-warning': !disbursement.amount}">
                  {{disbursement.amount | number:0}}
                </td>
                <td [ngClass]="{'bg-warning': !disbursement.dated}">
                  {{displayNull(disbursement.dated)}}
                </td>
                <td>
                  <button [class.disable]="disbursement.selected" id="idisbursement-{{project.id}}-{{disbursement.id}}" (click)="enterIATIDisbursement($event)"
                    class="btn btn-success btn-sm">Enter >></button>
                </td>
              </tr>
            </table>
            <div class="text-info" *ngIf="project.disbursementTransactions.length == 0"><i>No disbursements to show</i>
            </div>
      </table>
    </div>

    <div class="col-lg-6 col-md-6">

      <div class="box small">
        <div class="row">
          <div class="col-md-6">
            <span class="text-left">
              <h6>Project disbursements</h6>
            </span>
          </div>
          <div class="col-md-6">
            <span class="float-right">
              <button type="button" (click)="showFinancials()" class="btn btn-warning btn-sm text-uppercase">Go
                back</button>
            </span>
          </div>
        </div>

        <div *ngIf="(disbursementsTotal < disbursementModel.projectValue || disbursementsTotal > disbursementModel.projectValue)"
          class="alert alert-danger alert-dismissible margin-top-5">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          <span class="text-left">Disbursements must be equal to project total value.</span>
        </div>

        <div class="col-lg-12 col-md-12 margin-top-10">

          <div class="form-group form-inline">
            <label class="col-md-4">Project value</label>
            <input class="form-control col-md-8" type="text" [readonly]="true" name="projectValue"
              [(ngModel)]="disbursementModel.projectValue" #projectValue="ngModel" id="projectValue" required />
          </div>
          <div *ngFor="let disbursement of projectDisbursements;" class="form-group form-inline">
            <label class="col-md-4">
              {{disbursement.year}}&nbsp;
              <span *ngIf="disbursement.disbursementType == 1">Actual</span>
              <span *ngIf="disbursement.disbursementType == 2">Planned</span>
            </label>
            <input type="text" pattern="[0-9]*" oninput="validity.valid || (value = this.value.slice(0, -1))" maxlength="11" (change)="manageSourceDisbursementsSelection(disbursement.year, disbursement.amount, disbursement.disbursementType)"
              name="{{disbursement.year}}-{{disbursement.disbursementType}}" [(ngModel)]="disbursement.amount"
              class="form-control col-md-8" id="d-{{disbursement.year}}-{{disbursement.disbursementType}}" required />
            <!--<input type="text" pattern="^\d*(\.\d{0,2})?$" maxlength="11" (change)="manageSourceDisbursementsSelection(disbursement.year, disbursement.amount, disbursement.disbursementType)"
              name="{{disbursement.year}}-{{disbursement.disbursementType}}" [(ngModel)]="disbursement.amount"
              class="form-control col-md-8" id="d-{{disbursement.year}}-{{disbursement.disbursementType}}" required /> -->
          </div>

          <div class="form-group form-inline">
            <label class="col-md-4">Total: </label>
            <input type="text" [readonly]="true" name="disbursementTotal" [(ngModel)]="disbursementsTotal"
              class="form-control col-md-8" />
          </div>

          <div class="form-group">
            <label class="col-md-4">&nbsp;</label>
            <button
              [class.disable]="(disbursementsTotal < disbursementModel.projectValue || disbursementsTotal > disbursementModel.projectValue)"
              type="button" class="btn btn-info text-uppercase" (click)="saveDisbursements(null)">Save disbursements</button>
          </div>
        </div>
      </div>

    </div>

  </div>
</block-ui>
<error-modal message={{errorMessage}}></error-modal>