<div class="container-fluid view-area">
    <block-ui>

        <div class="row">
            <div class="col-md-6">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active btn-info" data-toggle="tab">Send email to users</a>
                      </li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="card card-signin my-5">
                <div class="card-body">
                  <div class="col-md-12">
                    <div class="row margin-top-10">
                      <div class="col-md-4">
                        <ng-multiselect-dropdown [placeholder]="'-- Select manager email/s --'" [data]="managerUsersList"
                          [(ngModel)]="emailModel.selectedManagers" [settings]="usersSettings" name="selectedManagers"
                          (onSelect)="onManagerSelect($event)" (onDeSelect)="onManagerDeSelect($event)"
                          (onSelectAll)="onManagerSelectAll($event)" #selectedManagers="ngModel">
                        </ng-multiselect-dropdown>
                      </div>
                      <div class="col-md-4">
                        <select name="usersFilterDropDown" class="form-control" [(ngModel)]="selectedUserFilter" (change)="filterUsers()" #userFilterConstant="ngModel">
                          <option *ngFor="let filter of userFilters" value="{{filter.id}}">
                            {{filter.label}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <ng-multiselect-dropdown [placeholder]="'-- Select user email/s --'" [data]="filteredUsersList"
                          [(ngModel)]="emailModel.selectedUsers" [settings]="usersSettings" (onSelect)="onUserSelect($event)"
                          (onDeSelect)="onUserDeSelect($event)" (onSelectAll)="onUserSelectAll($event)" name="selectedUsers"
                          #selectedUsers="ngModel">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
                  </div>
                  <form class="form-signin" (ngSubmit)="emailForm.form.valid && sendEmailMessage(emailForm)"
                    #emailForm="ngForm">
      
                    <div class="form-group margin-top-20">
                      <label>Subject</label>
                      <input type="text" class="form-control" id="subject" [(ngModel)]="emailModel.subject" name="subject"
                        #subject="ngModel" maxlength="100" required>
                      <div *ngIf="emailForm.submitted" [hidden]="subject.valid" class="alert alert-danger">
                        Subject is required
                      </div>
                    </div>
      
                    <div class="form-group">
                      <label>Email title</label>
                      <input type="text" class="form-control" id="title" [(ngModel)]="emailModel.title" name="title"
                        #title="ngModel" maxlength="100" required>
                      <div *ngIf="emailForm.submitted" [hidden]="title.valid" class="alert alert-danger">
                        Title is required
                      </div>
                    </div>
      
                    <div class="form-group">
                      <label>
                        Message
                        <span class="text-info">
                          (Max characters: {{messageLimit}} Left: {{messageLimitLeft}})
                        </span>
                        <span class="text-warning">
                          <b>Formatting help: </b> Use &#x3C;br&#x3E; to insert new line. For inserting heading use header tag from h1 (Largest) to h6 (Smallest). For example, &#x3C;h6&#x3E;Heading&#x3C;/h6&#x3E; and just after continue inserting the text.
                        </span>
                      </label>
                      <textarea rows="10" class="form-control" id="message" [(ngModel)]="emailModel.message" name="message"
                        #message="ngModel" maxlength="{{messageLimit}}" (input)="getMessageLimitInfo()" required></textarea>
                      <div *ngIf="emailForm.submitted" [hidden]="message.valid" class="alert alert-danger">
                        Message is required
                      </div>
                    </div>
      
                    <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase"
                      type="submit">{{btnText}}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>

    </block-ui>
</div>

<info-modal title="Email message" message="{{infoMessage}}"></info-modal>
<error-modal message="{{errorMessage}}"></error-modal>