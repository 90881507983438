<div class="container-fluid view-area">
  
  <div class="row">

    <div class="col-lg-3 col-md-3 col-xs-6">
      <div class="card bg-light">
        <div class="card-body text-info padding-10">
          <div class="col-md-12">
              <div class="font-management-menu-item text-center">
                <a [routerLink]="['/project-report']" class="text-info">
                  Sector-wise projects report
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--End of row-->
  </div>

</div>