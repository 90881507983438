<div class="container-fluid view-area">
  <div class="row">
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/envelope-types']">Envelope types List</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active btn-info" data-toggle="tab">Add/Edit envelope type</a>
        </li>
      </ul>
    </div>
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <div *ngIf="isError" class="alert alert-danger alert-dismissible">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Error!</strong> {{errorMessage}}
          </div>
          <h5 class="card-title text-center">{{btnText}}</h5>

          <form class="form-signin" (ngSubmit)="envelopeForm.form.valid && saveEnvelopeType(envelopeForm)" #envelopeForm="ngForm">
            <div class="form-group">
              <label for="typeName">Type name</label>
              <input type="text" class="form-control" id="typeName" [(ngModel)]="model.typeName" name="typeName"
                #typeName="ngModel" maxlength="50" required>
              <div *ngIf="envelopeForm.submitted" [hidden]="typeName.valid" class="alert alert-danger">
                Envelope type is required
              </div>
            </div>
            <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase" type="submit">{{btnText}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<error-modal message={{errorMessage}}></error-modal>