<div class="container-fluid view-area">
  
<div class="row">
  <div class="col-md-6">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active btn-info" data-toggle="tab" >Sectors list</a>
      </li>
      <!--<li class="nav-item">
        <a class="nav-link active text-info" [routerLink]="['/sector-mappings']" data-toggle="tab" >Sectors mappings</a>
      </li>-->
      <li *ngIf="permissions.canEditSector" class="nav-item">
        <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/manage-sector']">Create new</a>
      </li>
    </ul>
  </div>
  <div class="col-md-6">
    <div class="input-group mb-3">
      <select placeholder="Select sector type" class="form-control" id="sectorTypeId" [(ngModel)]="model.sectorTypeId"
        name="sectorTypeId" #sectorTypeId="ngModel" (change)="getSectorsForType()">
        <option *ngFor="let sectorType of sectorTypesList" [value]="sectorType.id">{{sectorType.typeName}}</option>
      </select>&nbsp;
      <input type="text" placeholder="{{inputTextHolder}}" class="form-control" id="criteria" (input)="searchSectors()"
        [(ngModel)]="criteria" name="criteria" #criteriaModel="ngModel" onkeypress="return event.keyCode != 13;"
        maxlength="50" required>
      <div class="input-group-append">
        <button class="btn btn-outline-info" (click)="searchSectors()" type="button"><i class="fa fa-search"></i></button>
      </div>
    </div>
    </div>
</div>

<div class="row text-center" *ngIf="isLoading">
  <div class="col-md-12">
    <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading" class="row">
  <div *ngIf="filteredSectorsList.length > 0" class="col margin-top-10">
    <table class="table table-bordered table-stripped">
      <tr>
        <th>Sector name</th>
        <th>Sector type</th>
        <th *ngIf="selectedSectorTypeId == defaultSectorTypeId">Actions</th>
      </tr>
      <tr *ngFor="let sector of filteredSectorsList | paginate: { itemsPerPage: pagingSize, currentPage: p }">
        <td>{{sector.sectorName}}</td>
        <td>{{sector.sectorType}}</td>
        <td>
          <a title="Edit" (click)="edit(sector.id)" class="btn btn-success"><i class="fa fa-edit color-white"></i></a>
          <a [class.disable]="sector.isUnAttributed" title="Delete" (click)="delete(sector.id)" class="btn btn-danger margin-left-2"><i class="fa fa-trash color-white"></i></a>
        </td>
      </tr>
    </table>
    <pagination-controls (pageChange)="p=$event"></pagination-controls>
  </div>
  <div *ngIf="filteredSectorsList.length == 0" class="col-md-12 text-info">
    <i>No sectors to show</i>
  </div>
</div>
</div>