<jw-modal id="sublocation-modal">

    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header bg-info text-white">Sublocations settings for {{locationName}}</div>
                <div class="card-body text-info">
                    <div *ngIf="isError" class="alert alert-danger alert-dismissible">
                        <button type="button" class="close" data-dismiss="alert">&times;</button>
                        <strong>Error!</strong> {{errorMessage}}
                    </div>

                    <div class="row text-center" *ngIf="isLoading">
                        <div class="col-md-12">
                          <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
                        </div>
                      </div>
                    
                    <div  class="form-group">
                        <form *ngIf="!isLoading" class="form-signin" (ngSubmit)="sublocationForm.form.valid && setSubLocations()" #sublocationForm="ngForm">
                            <div class="form-group">
                                <ng-multiselect-dropdown class="col-sm-8" [placeholder]="'Select sub-locations'"
                                    [settings]="subLocationsSettings" [data]="subLocations" 
                                    [(ngModel)]="selectedSubLocations"
                                     name="sublocations"
                                    #selectedOptions="ngModel" title="Select/Unselect sub-locations to set">
                                </ng-multiselect-dropdown>
                            </div>
    
                            <button [class.disable]="isBtnDisabled" (click)="setSubLocations()" class="btn btn-info text-uppercase"
                                type="button">{{btnText}}</button> &nbsp;
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 margin-top-10">
            <span class="margin-left-15">
              <button class="btn btn-danger" (click)="closeModal();">Close</button>
            </span>
          </div>
    </div>
        
</jw-modal>