<div class="container-fluid view-area">
  <block-ui>
    <div class="row">
      <div class="col-md-4">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Sub locations list</a>
          </li>
          <li *ngIf="permissions.canEditLocation" class="nav-item">
            <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/manage-sublocation']">Create new</a>
          </li>
        </ul>
      </div>
      <div class="col-md-4">
        <div class="input-group mb-3">
          <select placeholder="Select location" class="form-control" id="locationId" [(ngModel)]="selectedLocationId"
            name="locationId" #locationId="ngModel" (change)="filterLocations()">
            <option value="0">--Select location--</option>
            <option *ngFor="let loc of locationsList" [value]="loc.id">{{loc.location}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Enter sub-location name" (input)="searchSubLocations()"
            [(ngModel)]="criteria" name="criteria" #criteriaModel="ngModel" onkeypress="return event.keyCode != 13;"
            maxlength="50" aria-describedby="basic-addon2">
          <div class="input-group-append">
            <button class="btn btn-outline-info" (click)="searchSubLocations()" type="button"><i
                class="fa fa-search"></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="row text-center" *ngIf="isLoading">
      <div class="col-md-12">
        <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
      </div>
    </div>

    <div *ngIf="!isLoading" class="row">
      <div *ngIf="filteredSubLocationsList.length > 0" class="col margin-top-10">
        <table class="table table-bordered table-stripped">
          <tr>
            <th>Location name</th>
            <th *ngIf="permissions.canEditLocation">Actions</th>
          </tr>
          <tr *ngFor="let loc of filteredSubLocationsList | paginate: { itemsPerPage: pagingSize, currentPage: p }">
            <td>{{loc.subLocation}}</td>
            <td *ngIf="permissions.canEditLocation">
              <a title="Edit" (click)="edit(loc.id)" class="btn btn-success"><i class="fa fa-edit color-white"></i></a>
              &nbsp;
              <a [class.disable]="loc.isUnAttributed" title="Delete" (click)="delete(loc.id)" class="btn btn-danger"><i
                  class="fa fa-trash color-white"></i></a>
            </td>
          </tr>
        </table>
        <pagination-controls (pageChange)="p=$event"></pagination-controls>
      </div>
      <div *ngIf="filteredSubLocationsList.length == 0 && !isLoading" class="col-md-12 text-info">
        <i>No locations to show</i>
      </div>
    </div>

  </block-ui>
</div>

<error-modal message="{{errorMessage}}"></error-modal>