<div class="container-fluid view-area">
  <block-ui>
    <div class="row">
      <div class="col-md-6">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Notifications</a>
          </li>
        </ul>
      </div>

      <div class="col-md-6">
        <div class="float-right form-inline">
          <label><b><i>Notification type:&nbsp;</i></b></label>
          <select class="form-control" id="displayOption" [(ngModel)]="displayOption" name="displayOption"
            #displayOptionModel="ngModel" (change)="showNotificationType()">
            <option *ngFor="let type of notificationTypes" value="{{type.id}}">
              {{type.text}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center" *ngIf="isLoading">
        <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
      </div>

      <div *ngIf="!isLoading && (displayOption == notificationTypeCodes.USER_REGISTRATIONS || displayOption == notificationTypeCodes.ALL)" class="col-md-12 margin-top-10">
        <h6>New registration notifications</h6>
        <table class="table table-striped">
          <tr *ngFor="let notification of notifications; let i = index">
            <td width="70%">
              <span class="badge badge-info lead">
                {{i + 1}}
              </span>
              <span class="text-info margin-left-5">
                {{notification.message}}<br />
              </span>
              <span class="text-info">
                <b>Organization name:</b> {{notification.organization}} <br />
                <span *ngIf="notification.email"><b>User email:</b> {{notification.email}} <br /></span>
              </span>
              <span class="text-warning">
                <b><i>Dated: {{getLongDateString(notification.dated)}}</i></b>
              </span>
            </td>
            <td width="30%" *ngIf="notification.notificationType == 1">
              <span class="float-right">
                  <button class="btn btn-warning btn-sm text-uppercase"
                  (click)="activateUserAccount($event, notification.treatmentId, notification.id)">Approve</button>
                  &nbsp;
                  <button class="btn btn-success btn-sm text-uppercase"
                  (click)="activateUserWithInactiveOrganization($event, notification.treatmentId, notification.id)">Approve as Unaffiliated</button>
              </span>
            </td>
          </tr>
        </table>
        <table *ngIf="notifications.length == 0" class="table table-bordered">
          <tr>
            <td class="text-info">
                <i>No notifications to show</i>
            </td>
          </tr>
        </table>
      </div>

      <div *ngIf="!isLoading && (displayOption == notificationTypeCodes.MEMBERSHIP_REQUESTS || displayOption == notificationTypeCodes.ALL)" class="col-md-12 margin-top-10">
        <h6>Project membership requests</h6>
        <table *ngIf="projectRequests.length > 0" class="table table-striped">
          <tr *ngFor="let request of projectRequests; let i = index">
            <td width="70%">
              <span class="badge badge-info lead">
                {{i + 1}}
              </span>
              <span class="text-info margin-left-5">
                {{request.project}}<br />
              </span>
              <span class="text-info">
                <b>Requesting organization: </b>{{request.userOrganization}} <br />
                <b>Requesting user: </b>{{request.userEmail}} <br />
                <b>Requesting role: </b>{{request.membershipType}}<br />
              </span>
              <span class="text-warning">
                <b><i>Dated: {{getLongDateString(request.dated)}}</i></b>
              </span>
            </td>
            <td width="30%">
              <span class="float-right">
                  <button class="btn btn-warning btn-sm text-uppercase" id="btna-{{i}}-{{request.projectId}}-{{request.userId}}-{{request.membershipTypeId}}"
                  (click)="approveRequest($event)">Approve</button>&nbsp;
                <button class="btn btn-danger btn-sm text-uppercase" id="btnd-{{i}}-{{request.projectId}}-{{request.userId}}-{{request.membershipTypeId}}"
                  (click)="unApproveRequest($event)">Unapprove</button>
              </span>
            </td>
          </tr>
        </table>
        <table *ngIf="projectRequests.length == 0" class="table table-bordered">
            <tr>
              <td class="text-info">
                  <i>No project memebership requests to show</i>
              </td>
            </tr>
          </table>
      </div>

      <div *ngIf="!isLoading && (displayOption == notificationTypeCodes.PROJECT_DELETION_REQUESTS || displayOption == notificationTypeCodes.ALL)" class="col-md-12 margin-top-10">
        <h6>Project deletion requests</h6>
        <table *ngIf="projectDeletionRequests.length > 0" class="table table-striped">
          <tr *ngFor="let request of projectDeletionRequests; let i = index">
            <td width="70%">
              <span class="badge badge-info lead">
                {{i + 1}}
              </span>
              <span class="text-info margin-left-5">
                {{request.project}}<br />
              </span>
              <span class="text-info">
                <b>Requesting organization: </b>{{request.userOrganization}} <br />
                <b>Requesting user: </b>{{request.userEmail}} <br />
              </span>
              <span class="text-warning">
                <b><i>Dated: {{getLongDateString(request.requestedOn)}}</i></b>
              </span>
            </td>
            <td width="30%" *ngIf="request.status == deletionStatus.REQUESTED">
              <span class="float-right">
                  <button class="btn btn-warning btn-sm text-uppercase" id="btnda-{{i}}-{{request.projectId}}-{{request.userId}}"
                  (click)="approveDeletionRequest($event)">Approve request</button>&nbsp;
                <button class="btn btn-danger btn-sm text-uppercase" id="btndd-{{i}}-{{request.projectId}}-{{request.userId}}"
                  (click)="unApproveDeletionRequest($event)">Cancel request</button>
              </span>
            </td>
            <td width=30% *ngIf="request.status == deletionStatus.APPROVED">
              <span class="float-right">
                  <button class="btn btn-danger btn-sm" id="btndel-{{i}}-{{request.projectId}}-{{request.userId}}"
                  (click)="deleteProject($event)">Delete project</button>
              </span>
            </td>
          </tr>
        </table>
        <table *ngIf="projectDeletionRequests.length == 0" class="table table-bordered">
            <tr>
              <td class="text-info">
                  <i>No project deletion requests to show</i>
              </td>
            </tr>
          </table>
      </div>

      <div *ngIf="!isLoading && (displayOption == notificationTypeCodes.MERGE_ORGS_REQUESTS || displayOption == notificationTypeCodes.ALL)" class="col-md-12 margin-top-10">
        <!--<p *ngIf="organizationMergeRequests.length > 0" class="text-info">
          This section lists all the requests for organizations merge where you are member of an
          organization. Please accept or reject a merge, so that AIMS takes the required steps.
        </p>-->
        <h6>Organizations merge requests</h6>
        <table *ngIf="organizationMergeRequests.length > 0" class="table table-striped small">
          <tr>
            <th>Organizations to merge</th>
            <th class="text-right">Action</th>
          </tr>
          <tr *ngFor="let request of organizationMergeRequests">
            <td width="70%">
              <div [innerHTML]="formatMergeOrganizations(request.organizations)">
              </div>
            </td>
            <td width="30%">
              <span class="float-right">
                <button class="btn btn-warning btn-sm text-uppercase" id="btnmerge-approve-{{request.id}}"
                (click)="approveOrganizationsMergeRequest($event)">Approve request</button>&nbsp;
              <button class="btn btn-danger btn-sm text-uppercase" id="btnmerge-reject-{{request.id}}"
                (click)="unApproveOrganizationsMergeRequest($event)">Reject request</button>
            </span>
            </td>
          </tr>
        </table>
        <table *ngIf="organizationMergeRequests.length == 0" class="table table-bordered">
          <tr>
            <td class="text-info">
                <i>No merge organizations requests to show</i>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </block-ui>
</div>

<info-modal message={{infoMessage}}></info-modal>
<error-modal message={{errorMessage}}></error-modal>

<jw-modal id="readonly-message-modal">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
          <div class="card-header bg-info text-white">Information Message</div>
          <div class="card-body text-info">
              {{infoMessage}}
          </div>
        </div><br />
    </div>
  </div>
</jw-modal>