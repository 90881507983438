<div class="container-fluid view-area">
  <block-ui>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Time trend report</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div *ngIf="!isLoading" class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form class="form-signin" (ngSubmit)="searchProjectsByCriteriaReport()" #searchForm="ngForm">
              <div class="row">
                <div class="col-md-6">
                  <ng-multiselect-dropdown [placeholder]="'-- All project/s --'" title="AIMS Projects" 
                    [settings]="projectsSettings"
                    [data]="projects"
                    [(ngModel)]="model.selectedProjects" name="selectedProjects"
                    (onSelect)="onProjectSelect($event)" (onDeSelect)="onProjectDeSelect($event)"
                    (onSelectAll)="onProjectSelectAll($event)" (onDeSelectAll)="onProjectDeSelectAll($event)"
                    #selectedProjects="ngModel">
                  </ng-multiselect-dropdown>
                </div>
                <div class="col-md-6">
                  <table>
                    <tr>
                      <td>
                        Period from:&nbsp;
                      </td>
                      <td>
                        <select class="form-control" id="startingYear" title="Starting financial year"
                          [(ngModel)]="model.startingYear" name="startingYear" #startingYear="ngModel"
                          (change)="onChangeStartingYear()" required>
                          <option value="0">--Start--</option>
                          <option *ngFor="let year of yearsList" [value]="year.financialYear">{{year.label}}
                          </option>
                        </select>
                      </td>
                      <td>&nbsp;&nbsp;</td>
                      <td>
                        <select class="form-control" id="endingYear" title="Ending financial year"
                          [(ngModel)]="model.endingYear" name="endingYear" #endingYear="ngModel"
                          (change)="onChangeEndingYear()" required>
                          <option value="0">--End--</option>
                          <option *ngFor="let year of yearsList" [value]="year.financialYear">{{year.label}}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row margin-top-10">
                <div class="col-lg-3 col-md-3">
                  <select class="form-control" id="sectorLevel" [(ngModel)]="model.sectorLevel" name="sectorLevel"
                    title="Sector level" #sectorLevel="ngModel" required
                    (change)="manageSectorLevel();onChangeParentSector();">
                    <option value="0">--Sector levels--</option>
                    <option *ngFor="let level of sectorLevels" [value]="level.id">{{level.level}}
                    </option>
                  </select>
                </div>
                <div [class.disable]="(model.sectorLevel == 4)" class="col-lg-3 col-md-3">
                  <ng-multiselect-dropdown [placeholder]="'-- All sector/s --'" title="sector/s" 
                    [settings]="sectorsSettings"
                    [data]="sectorsList"
                    [(ngModel)]="model.selectedSectors" name="selectedSectors"
                    (onSelect)="onSectorSelect($event)" (onDeSelect)="onSectorDeSelect($event)"
                    (onSelectAll)="onSectorSelectAll($event)" (onDeSelectAll)="onSectorDeSelectAll($event)"
                    #selectedSectors="ngModel">
                  </ng-multiselect-dropdown>
                </div>
                <div class="col-md-6">
                  <ng-multiselect-dropdown [placeholder]="'-- All organization/s --'" title="Organization/s"
                    [settings]="organizationsSettings"
                    [data]="organizationsList" [(ngModel)]="model.selectedOrganizations"
                    (onSelect)="onOrganizationSelect($event)"
                    (onDeSelect)="onOrganizationDeSelect($event)" (onSelectAll)="onOrganizationSelectAll($event)"
                    (onDeSelectAll)="onOrganizationDeSelectAll($event)" name="selectedOrganizations"
                    #selectedOrganizations="ngModel">
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="row margin-top-10">

                <div class="col-lg-3 col-md-3">
                  <select class="form-control" title="Location" (change)="changeLocation();" id="selectedLocation"
                    [(ngModel)]="model.locationId" name="selectedLocation" #selectedLocation="ngModel">
                    <option value="0">--All location/s--</option>
                    <option *ngFor="let location of locationsList" value="{{location.id}}">
                      {{location.location}}
                    </option>
                  </select>
                </div>

                <div class="col-lg-3 col-md-3">
                  <ng-multiselect-dropdown [placeholder]="'-- All sub-location/s --'" title="Location/s"
                    [settings]="subLocationsSettings"
                    [data]="filteredSubLocationsList" [(ngModel)]="model.selectedSubLocations" 
                    name="selectedSubLocations" (onSelect)="onSubLocationSelect($event)"
                    (onDeSelect)="onSubLocationDeSelect($event)" (onSelectAll)="onSubLocationSelectAll($event)"
                    (onDeSelectAll)="onSubLocationDeSelectAll($event)"
                    #selectedSubLocations="ngModel">
                  </ng-multiselect-dropdown>
              </div>

                <div class="col-lg-3 col-md-3">
                  <select class="form-control" id="markerId" [(ngModel)]="model.markerId" name="markerId"
                    title="Select a marker" (change)="getSelectedMarkerValues()" #sectorId="ngModel">
                    <option value="0">--Select marker--</option>
                    <option *ngFor="let marker of markersList" [value]="marker.id">{{marker.fieldTitle}}
                    </option>
                  </select>
                </div>
                
                <div class="col-lg-3 col-md-3">
                  <ng-multiselect-dropdown [placeholder]="'-- All values --'" title="Marker value/s"
                    [settings]="markerValuesSettings"
                    [data]="markerValues" [(ngModel)]="model.markerValues" 
                    name="selectedMarkerValues" #selectedMarkerValues="ngModel">
                  </ng-multiselect-dropdown>
                </div>

              </div>

              <div class="row margin-top-10">
                <div class="col-lg-3 col-md-3">
                  <select class="form-control" id="markerId2" [(ngModel)]="model.markerId2" name="markerId2"
                    title="Select marker two" (change)="getSelectedMarkerValuesTwo()" #markerId1="ngModel">
                    <option value="0">--Select marker two--</option>
                    <option *ngFor="let marker of markersList" [value]="marker.id">{{marker.fieldTitle}}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3 col-md-3">
                  <ng-multiselect-dropdown [placeholder]="'-- All values --'" title="Marker-2 value/s"
                    [settings]="markerValuesSettings"
                    [data]="markerValues" [(ngModel)]="model.markerValues2" 
                    name="selectedMarkerValuesTwo" #selectedMarkerValues="ngModel">
                  </ng-multiselect-dropdown>
                </div>
                <div class="col-lg-6 col-md-6">
                  <select [class.disable]="manualExRate == 0" class="form-control" title="Currency for reporting"
                    (change)="selectCurrency()" id="selectedCurrency" [(ngModel)]="model.selectedCurrency"
                    name="selectedCurrency" #selectedCurrency="ngModel">
                    <option *ngFor="let currency of currenciesList" value="{{currency.currency}}">
                      {{currency.currencyName}}
                    </option>
                  </select>&nbsp;
                  <span *ngIf="manualExRate > 0 && model.selectedCurrency == nationalCurrencyName" class="text-warning">
                    <b><i>Exchange rate: 1 {{defaultCurrency}} = {{manualExRate}} {{nationalCurrencyName}} on
                        {{datedToday}}</i></b>
                  </span>
                  <span *ngIf="manualExRate == 0 && !isDataLoading" class="text-danger">
                    <i>No exchange rate available, contact Administrator.</i>
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <span [class.disable]="!defaultCurrency" class="float-right">
                    <button type="submit" class="btn btn-info margin-top-2">{{btnReportText}}</button>
                    <button *ngIf="reportDataList && reportDataList.reportSettings" type="button"
                      class="btn btn-danger margin-top-2 margin-left-2" (click)="generatePDF()">Download PDF</button>
                    <a *ngIf="excelFile" href="{{excelFile}}"
                      class="btn btn-success margin-top-2 margin-left-2">Download
                      spreadsheet</a>
                    <button *ngIf="reportDataList && reportDataList.reportSettings" type="button"
                      class="btn btn-warning margin-top-2 margin-left-2" (click)="printReport()">Print report</button>
                    <button *ngIf="isAnyFilterSet" type="button" class="btn btn-primary margin-top-2 margin-left-2"
                      (click)="resetFilters()">Reset filters</button>
                  </span>
                </div>
              </div>

              <div class="row margin-top-10">
                <div class="col-lg-12 col-md-12">
                  <span *ngIf="!defaultCurrency && !isDataLoading" class="text-danger">
                    Default currency is not set and it may produce errors on reports.
                    Please contact administrator.
                  </span>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <div *ngIf="isLoading" class="col-md-12 text-center">
        <img src="assets/images/loader-md.gif" /><span>&nbsp;Setting up report...</span>
      </div>
    </div>

    <div *ngIf="!isLoading" class="row margin-top-10">
      <div *ngIf="reportDataList && reportDataList.reportSettings" class="col-md-12">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="col-md-12">
              <form class="form-inline float-right">
                <label>Chart type to display:&nbsp;</label>
                <select class="form-control" id="chartType" [(ngModel)]="model.chartType" name="chartType"
                  #chartType="ngModel" (change)="manageDataToDisplay()" required>
                  <option *ngFor="let chart of chartTypesList" value="{{chart.type}}">
                    {{chart.title}}
                  </option>
                </select>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="reportDataList && reportDataList.yearlyProjectsList" id="rpt-timetrend-pdf-view" class="col-md-12">
      <div class="col-md-12 text-center margin-top-10">
        <h5>{{reportDataList.reportSettings.title}} <small>(Currency: {{this.selectedCurrencyName}})</small></h5>
      </div>
      <div *ngIf="reportDataList.yearlyProjectsList && chartData.length > 0 && showChart" class="col-md-12">

        <div *ngIf="reportDataList && reportDataList.reportSettings && chartData.length > 0"
          class="col-md-12 margin-top-10">
          <div class="offset-2 col-md-8">
            <canvas *ngIf="chartType == chartTypes.BAR" id="chart" baseChart [datasets]="chartData"
              [labels]="chartLabels" [options]="chartOptions" [legend]="chartLegend" [chartType]="chartType"
              ></canvas>

            <canvas *ngIf="chartType == chartTypes.LINE" id="chart" baseChart [datasets]="chartData"
              [labels]="chartLabels" [options]="lineChartOptions" [legend]="chartLegend" [chartType]="chartType"
              ></canvas>
          </div>
        </div>
      </div>

      <div *ngIf="reportDataList.reportSettings" class="row margin-top-10" id="rpt-time-trend-report">

        <div *ngIf="reportDataList && reportDataList.yearlyProjectsList" class="col-md-12">
          <table class="table table-striped table-condensed table-fixed small" style="margin-bottom: 0rem !important">
            <thead>
              <tr>
                <th width="30%">Project/s for year</th>
                <th class="text-center" width="20%">Funders</th>
                <th class="text-center" width="20%">Implementers</th>
                <!--<th class="text-right" width="15%">Project value</th>-->
                <th class="text-right" width="15%">Actual disbursements</th>
                <th class="text-right" width="15%">Planned disbursements</th>
              </tr>
            </thead>
          </table>
          <div *ngFor="let yearProject of reportDataList.yearlyProjectsList; let i = index;">
            <div class="row">
              <table class="table table-condensed table-fixed small margin-bottom-none row-highlight">
                <tbody>
                  <tr class="row-highlight" title="Click here to view detail"
                    (click)="displayHideRow(yearProject.year)">
                    <td width="70%">
                      <span *ngIf="!yearProject.isDisplay"><i class="fa fa-plus-square"></i></span>
                      <span *ngIf="yearProject.isDisplay"><i class="fa fa-minus-square"></i></span>
                      &nbsp;&nbsp;<b>{{yearProject.year}}</b>
                    </td>
                    <td class="text-right" width="15%">
                      {{formatNumber(yearProject.totalActualDisbursements)}}
                    </td>
                    <td class="text-right" width="15%">
                      {{formatNumber(yearProject.totalPlannedDisbursements)}}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="child table table-striped table-condensed table-fixed small" *ngIf="yearProject.isDisplay">
                <tbody>
                  <tr *ngFor="let project of yearProject.projects">
                    <td width="30%">{{project.title}}</td>
                    <td class="text-center" width="20%">{{project.funders}}</td>
                    <td class="text-center" width="20%">{{project.implementers}}</td>
                    <td class="text-right" width="15%">
                      {{formatNumber(project.actualDisbursements)}}<br />
                    </td>
                    <td class="text-right" width="15%">
                      {{formatNumber(project.plannedDisbursements)}}
                    </td>
                  </tr>
                  <tr class="child" [hidden]="!project.isDisplay" *ngIf="yearProject.projects.length == 0">
                    <td width="100%" colspan="6">
                      <span class="text-info"><i>No projects found</i></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <table class="table table-stiped table-condensed table-fixed small">
            <thead>
              <tr>
                <th width="30%">&nbsp;</th>
                <th width="15%">&nbsp;</th>
                <th width="15%">&nbsp;</th>
                <th class="text-right" width="15%">
                  <b>
                    &nbsp;
                    <!--<i>Total: {{formatNumber(getGrandTotalProjectValueForYear())}}</i>-->
                  </b>
                </th>
                <th class="text-right" width="15%">
                  <b>
                    <i>
                      Total: {{formatNumber(getGrandTotalDisbursementForYear())}}
                    </i>
                  </b>
                </th>
                <th class="text-right" width="15%">
                  <b>
                    <i>
                      Total: {{formatNumber(getGrandTotalPlannedDisbursementForYear())}}
                    </i>
                  </b>
                </th>
              </tr>
            </thead>
          </table>
        </div>

      </div>

      <div class="col-md-12 small">
        <div *ngIf="reportDataList && reportDataList.reportSettings" class="text-center margin-top-20 text-info">
          Report link: <a class="text-info"
            href="{{reportDataList.reportSettings.reportUrl}}">{{reportDataList.reportSettings.reportUrl}}</a>
        </div>
        <div class="text-center margin-top-5">
          <span><i>Generated on: {{getTodaysDate()}}</i></span>
        </div>
      </div>

    </div>

  </block-ui>
</div>
<error-modal message={{errorMessage}}></error-modal>