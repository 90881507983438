<jw-modal id="project-report-modal">

    <div class="container-fluid view-area-modal small">
        <block-ui>
          <!--Loading project data -->
          <div class="row">
            <div class="col margin-top-10">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-default">
                    <span class="float-left">
                      <h6>Project detail</h6>
                    </span>
      
                    <span class="float-right margin-left-5">
                      <button class="btn btn-danger btn-sm" (click)="closeModal()">
                        Close report
                      </button>
                    </span>
                    <span class="float-right margin-left-5">
                      <button class="btn btn-primary btn-sm" (click)="printProfile()">
                        Print profile
                      </button>
                    </span>
                    <span class="float-right margin-left-5">
                      <button [class.disable]="excelFile == null" class="btn btn-danger btn-sm" (click)="generatePDF()">
                        Download PDF
                      </button>
                    </span>
                    <span class="float-right margin-left-5">
                      <a href="{{excelFile}}" [class.disable]="excelFile == null" class="btn btn-success btn-sm">
                        Download spreadsheet
                      </a>
                    </span>
                    <span class="float-right margin-left-5" *ngIf="!isLoading && isLoggedIn && isShowDeleteProject(projectId)">
                      <button class="btn btn-danger btn-sm" (click)="makeDeleteRequest(projectId)">
                        Request deletion
                      </button>
                    </span>
                    <span *ngIf="!isLoading && isLoggedIn && canJoinProject()" class="float-right margin-left-5">
                      <button class="btn btn-info btn-sm" (click)="applyForProjectMembership()">
                        Join project
                      </button>
                    </span>
                    <span class="float-right margin-left-5" *ngIf="!isLoading">
                      <button class="btn btn-warning btn-sm" (click)="contactProject()">
                        Suggest a change
                      </button>
                    </span>
                  </div>
      
                  <div class="card-body">
                    <div class="row text-center" *ngIf="isLoading">
                      <div class="col-md-12">
                        <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div *ngIf="deletionSuccess" class="alert alert-success alert-dismissible">
                        <button type="button" class="close" data-dismiss="alert">&times;</button>
                        <strong>Information!</strong> {{successMessage}}
                      </div>
                    </div>
                    <div *ngIf="!isLoading" id="rpt-project" class="container-fluid">
                      <div class="col-lg-12 col-md-12 text-center">
                        <h6 class="text-info">Project profile report</h6>
                      </div>
      
                      <div class="row margin-top-20">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <table class="table table-striped">
                            <tr>
                              <td width="15%"><b>Project title: </b></td>
                              <td>{{projectData.title}}</td>
                              <td><b>Project last updates: </b></td>
                              <td>
                                {{formatDateUKStyle(projectData.dateUpdated)}}
                                <ng-container *ngIf="projectData.lastUpdatedByOrganization">
                                  <span class="text-info">&nbsp;by&nbsp;{{projectData.lastUpdatedByOrganization}}</span>
                                </ng-container>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Start date: </b></td>
                              <td>{{formatDateUKStyle(projectData.startDate)}}</td>
                              <td><b>End date: </b></td>
                              <td>{{formatDateUKStyle(projectData.endDate)}}</td>
                            </tr>
                            <tr>
                              <td><b>Starting year: </b></td>
                              <td>{{projectData.startingFinancialYear}}</td>
                              <td><b>Ending year: </b></td>
                              <td>{{projectData.endingFinancialYear}}</td>
                            </tr>
                            <tr>
                              <td><b>Project value: </b></td>
                              <td>{{formatNumber(projectData.projectValue)}}</td>
                              <td><b>Currency: </b></td>
                              <td>{{projectData.projectCurrency}}</td>
                            </tr>
                            <tr>
                              <td><b>Description: </b></td>
                              <td colspan="3">
                                {{projectData.description}}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
        
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <h6 class="text-info">Project locations</h6>
                          <table *ngIf="projectLocations.length > 0" class="table table-striped">
                            <tbody>
                              <tr>
                                <th>Location</th>
                                <th>Funds percentage</th>
                              </tr>
                              <tr *ngFor="let location of projectLocations">
                                <td>{{location.name}}</td>
                                <td>{{location.fundsPercentage}}%</td>
                              </tr>
                            </tbody>
                          </table>
                          <p *ngIf="projectLocations.length == 0" class="text-info"><i>No project locations found.</i></p>
                        </div>
        
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <h6 class="text-info">Project sectors</h6>
                            <table *ngIf="projectSectors.length > 0" class="table table-striped">
                              <tbody>
                                <tr>
                                  <th>Sector</th>
                                  <th>Funds percentage</th>
                                </tr>
                                <tr *ngFor="let sector of projectSectors">
                                  <td>{{sector.name}}</td>
                                  <td>{{sector.fundsPercentage}}%</td>
                                </tr>
                              </tbody>
                            </table>
                            <p *ngIf="projectSectors.length == 0" class="text-info"><i>No project sectors found.</i></p>
                        </div>
                      </div>
        
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <h6 class="text-info">Project funders</h6>
                            <table *ngIf="projectFunders.length > 0" class="table table-striped">
                              <tbody>
                                <tr>
                                  <th>Funder name</th>
                                </tr>
                                <tr *ngFor="let funder of projectFunders">
                                  <td>{{funder.name}}</td>
                                </tr>
                              </tbody>
                            </table>
                            <p *ngIf="projectFunders.length == 0" class="text-info"><i>No project funders found.</i></p>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <h6 class="text-info">Project implementers</h6>
                              <table *ngIf="projectImplementers.length > 0" class="table table-striped">
                                <tbody>
                                  <tr>
                                    <th>Implementer</th>
                                  </tr>
                                  <tr *ngFor="let implementer of projectImplementers">
                                    <td>{{implementer.name}}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <p *ngIf="projectImplementers.length == 0" class="text-info">
                                <i>No project implementers found.</i>
                              </p>
                        </div>
        
                      </div>
        
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <h6 class="text-info">Project disbursements</h6>
                            <table *ngIf="projectDisbursements.length > 0" class="table table-striped">
                              <tbody>
                                <tr>
                                  <th>Type</th>
                                  <th>Financial year</th>
                                  <th>Amount</th>
                                  <th>Currency</th>
                                  <th>Exchange rate</th>
                                </tr>
                                <tr *ngFor="let disbursement of projectDisbursements">
                                  <td>{{disbursement.disbursementType}}</td>
                                  <td>{{disbursement.financialYear}}</td>
                                  <td>{{formatNumber(disbursement.amount)}}</td>
                                  <td>{{disbursement.currency}}</td>
                                  <td>{{disbursement.exchangeRate}}</td>
                                </tr>
                              </tbody>
                            </table>
                            <p *ngIf="projectDisbursements.length == 0" class="text-info">
                              <i>No project disbursements found.</i>
                            </p>
                        </div>
        
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <h6 class="text-info">Project documents</h6>
                              <table *ngIf="projectDocuments.length > 0" class="table table-striped">
                                <tbody>
                                  <tr>
                                    <th>Document Title</th>
                                    <th>Url</th>
                                  </tr>
                                  <tr *ngFor="let document of projectDocuments">
                                    <td>{{document.documentTitle}}</td>
                                    <td>{{document.documentUrl}}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <p *ngIf="projectDocuments.length == 0" class="text-info">
                                <i>No project documents found.</i>
                              </p>
                        </div>
                      </div>
      
                      <div class="row">
                        <div class="col-md-12 col-lg-12">
                          <h6 class="text-info">Project markers</h6>
                          <table *ngIf="projectMarkers.length > 0" class="table table-striped table-condensed">
                            <tbody>
                              <tr>
                                <th>Marker</th>
                                <th>Values</th>
                              </tr>
                              <tr *ngFor="let marker of projectMarkers">
                                <td>{{marker.marker}}</td>
                                <td>{{displayFieldValues(marker.values)}}</td>
                              </tr>
                            </tbody>
                          </table>
                          <p *ngIf="projectMarkers.length == 0 && !isExcelGenerating" class="text-info">
                              <i>No project markers found.</i>
                            </p>
                        </div>
                      </div>
      
                      <div class="col-md-12">
                          <div *ngIf="projectProfileLink" class="text-center margin-top-20 text-info">
                            Report link: <a class="text-info" href="{{projectProfileLink}}">{{projectProfileLink}}</a>
                          </div>
                          <div *ngIf="dated" class="text-center margin-top-5">
                            <span><i>Generated on: {{dated}}</i></span>
                          </div>
                        </div>
                    </div>  
                    
                    <div class="col-lg-12 col-md-12">
                      <span class="float-right">
                        <button class="btn btn-danger btn-sm" (click)="closeModal()">
                          Close report
                        </button>
                      </span>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
      
        </block-ui>
        <join-project-modal [projectId]="projectId"></join-project-modal>
        <!--<info-modal message={{successMessage}}></info-modal>-->
      </div>

</jw-modal>
