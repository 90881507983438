<div class="container-fluid view-area">
  <div class="row">
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/email-messages']">Email messages List</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active btn-info" data-toggle="tab">{{btnText}}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title text-center">Email message</h5>
          
          <div class="row">
              <div class="col-md-8">
                  <form class="form-signin" (ngSubmit)="messageForm.form.valid && saveEmailMessage(messageForm)" #messageForm="ngForm">
                    <div class="form-group">
                      <label for="messageType">Definition</label>
                      <select class="form-control" id="messageType" [(ngModel)]="model.messageType" name="messageType"
                        #messageType="ngModel" required>
                        <option *ngFor="let type of messagesList" value={{type.messageType}}>{{type.typeDefinition}}</option>
                      </select>
                      <div *ngIf="messageForm.submitted" [hidden]="messageType.valid" class="alert alert-danger">
                        Message type is required
                      </div>
                    </div>

                    <div class="form-group">
                        <label>Subject</label>
                        <input type="text" class="form-control" id="subject" [(ngModel)]="model.subject" name="subject"
                          #subject="ngModel" maxlength="100" required>
                        <div *ngIf="messageForm.submitted" [hidden]="subject.valid" class="alert alert-danger">
                          Subject is required
                        </div>
                      </div>
        
                    <div class="form-group">
                      <label for="message">
                        Message &nbsp; 
                        <span class="text-warning">
                          Use the starting tag &lt;p&gt; and ending &lt;/p&gt; for different paragraphs. For example &lt;p&gt;text&lt;/p&gt;
                        </span>
                      </label>
                      <textarea rows="15" class="form-control" id="message" [(ngModel)]="model.message" name="message"
                        #message="ngModel" maxlength="2000" required></textarea>
                      <div *ngIf="messageForm.submitted" [hidden]="message.valid" class="alert alert-danger">
                        Message is required
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="footerMessage">Footer message</label>
                      <input type="text" class="form-control" id="footerMessage" [(ngModel)]="model.footerMessage" name="footerMessage"
                          #footerMessage="ngModel" maxlength="100">
                      <div *ngIf="messageForm.submitted" [hidden]="footerMessage.valid" class="alert alert-danger">
                        Footer message is required
                      </div>
                    </div>
        
                    <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase" type="submit">{{btnText}}</button>
                  </form>
                </div>
                <div class="col-md-4 small">
                  <div class="alert alert-info">
                    <h6>Message preview</h6>
                    <p>Dear User, </p>
                    <div [innerHTML]="model.message">
                    </div>
                    <p class="text-success">
                      <i>Facts produced by the system</i>
                    </p>
                    <p *ngIf="model.footerMessage">
                      {{model.footerMessage}}
                    </p>
                    <p *ngIf="!model.footerMessage">
                      If you need further assistance, please submit a help request via the contact form in the AIMS.
                      <i><small class="text-success">(If you do not set footer, this message will appear)</small></i>
                    </p>
                      <h6>AIMS Support Team</h6>
                  </div>
                </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>