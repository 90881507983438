<block-ui>

  <div *ngIf="currentTab == tabConstants.SECTORS_LOCATIONS" class="box">
    <div class="row">
      <div class="col-md-12">
        <h6>Project sectors</h6>
      </div>
    </div>

    <div class="row">
      <form class="form-signin col-lg-12 col-md-12" (ngSubmit)="sectorsForm.form.valid && addSector(sectorsForm)"
        #sectorsForm="ngForm">
        <div class="row">

          <div class="col-md-3 col-lg-3">
            <div class="input-group form-inline mb-3">
              <select
                [ngClass]="{'col-sm-10': isSectorsSourceAvailable == false, 'col-sm-8': isSectorsSourceAvailable == true}"
                class="form-control" name="sectorType" (change)="getTypeSectorsList()"
                [(ngModel)]="sectorModel.sectorTypeId" #sectorTypeId="ngModel" required>
                <option value="null">--Sector type--</option>
                <option *ngFor="let type of sectorTypesList" [value]="type.id">{{type.typeName}}</option>
              </select>
              <button *ngIf="isSectorsSourceAvailable" class="btn btn-success col-sm-2" title="Source data available"
                (click)="showSectorsSource()" type="button"><i class="fa fa-database"></i></button>
              <span class="margin-left-2" tooltip="{{sectorHelp.sectorType}}" [options]="tooltipOptions">
                <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
              </span>
            </div>
            <div *ngIf="sectorsForm.submitted" [hidden]="sectorTypeId.valid" class="alert alert-danger">
              Sector type is required
            </div>
          </div>

          <div [class.disable]="(sectorModel.sectorTypeId == defaultSectorTypeId)" class="col-md-3 col-lg-3">
            <div class="input-group form-inline mb-3">
              <ng-multiselect-dropdown class="w-100 col-sm-10" [placeholder]="'--Sector--'" title="Sector"
                [settings]="sectorsSettings"
                [data]="typeSectorsList" [(ngModel)]="sectorModel.selectedSector" 
                (onDeSelect)="onDeSelectSector()" (onSelect)="getSectorMappings()"
                name="selectedSector" #selectedSector="ngModel">
              </ng-multiselect-dropdown>
              <span class="col-sm-2" tooltip="{{sectorHelp.sector}}" [options]="tooltipOptions">
                <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
              </span>
              <div *ngIf="sectorsForm.submitted" [hidden]="selectedSector != null" class="alert alert-danger">
                Sector is required
              </div>
            </div>
          </div>

          <div class="col-md-3 col-lg-3">
            <div [class.disable]="sectorModel.sectorTypeId == null || sectorModel.sectorTypeId == 'null'" class="input-group form-inline mb-3">
              <ng-multiselect-dropdown class="col-sm-10" [placeholder]="'-- NDP sector/s --'" title="NDP sector/s"
                [settings]="sectorsSettings"
                [data]="ndpSectorsList" [(ngModel)]="sectorModel.selectedMapping" 
                name="selectedMappingSector" #selectedMappingSector="ngModel">
              </ng-multiselect-dropdown>
              <span class="col-sm-2" tooltip="{{sectorHelp.mappingSector}}" [options]="tooltipOptions">
                <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
              </span>
            </div>

            <div class="col" *ngIf="mappingsCount > 0">
              <span *ngIf="showMappingManual" class="float-left">
                <button type="button" class="btn btn-info btn-sm" (click)="setManualMappings()">Map manual</button>
              </span>
              <span *ngIf="showMappingAuto" class="float-left">
                <button type="button" class="btn btn-warning btn-sm" (click)="setAutomaticMappings()">Map
                  auto</button>
              </span>
              <span *ngIf="showMappingManual" class="float-left text-success small margin-left-5">
                {{mappingsCount}} mapping/s found
              </span>
            </div>
            <div
              *ngIf="mappingsCount == 0 && sectorModel.sectorTypeId != defaultSectorTypeId && sectorModel.selectedSector"
              class="col text-warning small">
              <i>No mappings found</i></div>
            <div *ngIf="sectorsForm.submitted" [hidden]="selectedMappingSector != null" class="alert alert-danger">
              Mapping is required
            </div>
          </div>

          <div class="col-md-3 col-lg-3">
            <div class="input-group mb-3 form-inline">
              <input type="number" class="form-control col-sm-5" id="fundsPercentage"
                [(ngModel)]="sectorModel.fundsPercentage" placeholder="Percentage" name="fundsPercentage"
                #fundsPercentage="ngModel" min="1" max="100" required>
              <span class="col-sm-3" tooltip="{{sectorHelp.percentage}}" [options]="tooltipOptions">
                <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
              </span>
              <button [class.disable]="(sectorModel.fundsPercentage < 1 || sectorModel.fundsPercentage > 100) || 
                (!sectorModel.selectedMapping || (sectorModel.selectedMapping && sectorModel.selectedMapping.length == 0)) 
                || calculateSectorPercentage() >= 100 || (sectorModel.sectorTypeId != defaultSectorTypeId && 
                (!sectorModel.selectedSector) || (sectorModel.selectedSector && sectorModel.selectedSector.length == 0))"
                class="col-sm-4 btn btn-sm btn-warning text-uppercase" type="submit">
                Add sector
              </button>
            </div>
            <div *ngIf="sectorsForm.submitted" [hidden]="(fundsPercentage.value > 0 && fundsPercentage.value < 101)"
              class="alert alert-danger">
              Valid values are (1 to 100)
            </div>
          </div>

        </div>

      </form>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12">
        <span class="float-right"><button *ngIf="areUnSavedSectors()" (click)="saveProjectSectors()"
            class="btn btn-info text-uppercase btn-sm">Save sectors</button></span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="sectors-list-box-small">
          <table *ngIf="currentProjectSectors.length > 0" class="table table-striped small margin-top-5">
            <thead>
              <tr>
                <th>Sector</th>
                <th class="text-center">Percentage</th>
                <th class="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sector of currentProjectSectors">
                <td>{{sector.sector}}</td>
                <td class="text-center">{{sector.fundsPercentage}}</td>
                <td class="text-center">
                  <button *ngIf="sector.saved" title="Delete sector" (click)="deleteProjectSector(sector.sectorId)"
                    class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                  <button *ngIf="!sector.saved && sector.sectorId" title="Delete sector"
                    (click)="removeProjectSector(sector.sectorId, false)" class="btn btn-warning btn-sm"><i
                      class="fa fa-remove"></i></button>
                  <button *ngIf="!sector.saved && !sector.sectorId" title="Remove sector"
                    (click)="removeProjectSector(sector.mappingId, true)" class="btn btn-warning btn-sm"><i
                      class="fa fa-remove"></i></button>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td class="text-center">
                  <span *ngIf="currentProjectSectors.length > 0" class="text-info"><b>Total</b>:
                    {{calculateSectorPercentage()}}%
                  </span>
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

  </div>

  <div *ngIf="currentTab == tabConstants.SECTORS_LOCATIONS" class="box">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <form class="form-signin" (ngSubmit)="locationsForm.form.valid && addLocation(locationsForm)"
          #locationsForm="ngForm">
          <h6>Project locations</h6>

          <div class="row">
            <div class="col-md-4 col-lg-4">
              <div class="input-group form-inline mb-3">
                <select
                  [ngClass]="{'col-sm-10': isSectorsSourceAvailable == false, 'col-sm-8': isSectorsSourceAvailable == true}"
                  class="form-control" name="locationId" [(ngModel)]="locationModel.locationId" #locationId="ngModel"
                  (change)="manageSubLocations()"
                  required>
                  <option value="null">--Location--</option>
                  <option *ngFor="let location of locationsList" [value]="location.id">{{location.location}}</option>
                </select>
                <button *ngIf="isLocationsSourceAvailable" class="btn btn-success col-sm-1"
                  title="Source data available" (click)="showLocationsSource()" type="button"><i
                    class="fa fa-database"></i></button>
                <span class="col-sm-2" tooltip="{{locationHelp.location}}" [options]="tooltipOptions">
                  <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
                </span>
              </div>
              <div *ngIf="locationsForm.submitted" [hidden]="locationId.valid" class="alert alert-danger">
                Location is required
              </div>
            </div>

            <div class="col-md-4 col-lg-4">
              <ng-multiselect-dropdown [placeholder]="'--Select sub-locations--'" [settings]="subLocationsSettings"
                [data]="filteredSubLocationsList" [(ngModel)]="locationModel.selectedSubLocations" name="sublocations" #selectedOptions="ngModel"
                title="Select/Unselect sub-locations to set">
              </ng-multiselect-dropdown>
            </div>

            <div class="col-md-4 col-lg-4">
              <div class="input-group form-inline mb-3">
                <input type="number" class="form-control col-md-7" [(ngModel)]="locationModel.fundsPercentage"
                  placeholder="Percentage" name="lfundsPercentage" #lfundsPercentage="ngModel" min="1" max="100"
                  required>

                <span class="col-md-2" tooltip="{{locationHelp.percentage}}" [options]="tooltipOptions">
                  <button class="btn btn-info" type="button"><i class="fa fa-question-circle"></i></button>
                </span>
                <button [class.disable]="calculateLocationPercentage() >= 100 || !locationModel.locationId 
                  || !locationModel.fundsPercentage || (locationModel.fundsPercentage && (locationModel.fundsPercentage < 0 || locationModel.fundsPercentage > 100))"
                  class="btn btn-sm btn-warning text-uppercase col-md-3 col-md-2" type="submit">
                  Add location
                </button>
              </div>
              <div *ngIf="locationsForm.submitted"
                [hidden]="(lfundsPercentage.value > 0 && lfundsPercentage.value < 101)" class="alert alert-danger">
                Valid values are (1 to 100)
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-lg-12">
            <div class="float-right">
              <button *ngIf="areUnSavedLocations()" (click)="saveProjectLocations()" class="btn btn-info btn-sm text-uppercase">Save
                locations</button>
            </div>
          </div>

          <div class="col-lg-12 col-md-12">
            <div class="sectors-list-box-small">
              <table *ngIf="currentProjectLocations.length > 0" class="table table-striped small margin-top-5">
                <thead>
                  <tr>
                    <th>Location</th>
                    <th class="text-center">Percentage</th>
                    <th class="text-center">Sub-locations</th>
                    <th class="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let location of currentProjectLocations">
                    <td>{{location.location}}</td>
                    <td class="text-center">{{location.fundsPercentage}}</td>
                    <td>
                      <ng-container *ngIf="!location.saved">
                        <span class="float-left">
                          {{displaySubLocations(location.subLocations)}}
                        </span>
                        <span class="float-right">
                          <button type="button" title="Manage sub-locations" (click)="openSubLocationsForLocation(location.locationId)"
                            class="btn btn-info btn-sm"><i class="fa fa-list-alt"></i></button>
                        </span>
                      </ng-container>
                      <ng-container *ngIf="location.saved">
                        <div class="text-center">
                          {{displaySubLocations(location.subLocations)}}
                        </div>
                      </ng-container>
                      
                    </td>
                    <td class="text-center">
                      <button *ngIf="location.saved" title="Delete location"
                        (click)="deleteProjectLocation(location.locationId)" class="btn btn-danger btn-sm"><i
                          class="fa fa-trash"></i></button>
                      <button *ngIf="!location.saved" title="Remove location"
                        (click)="removeProjectLocation(location.locationId)" class="btn btn-warning btn-sm"><i
                          class="fa fa-remove"></i></button>
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <th class="text-center">
                      <span *ngIf="currentProjectLocations.length > 0" class="text-info"><b>Total</b>:
                        {{calculateLocationPercentage()}}%</span>
                    </th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                  </tr>
                </tbody>
              </table>
              
            </div>

            <div class="col-md-12">
              <span class="float-right">
                <button class="margin-left-2 btn btn-info text-uppercase" (click)="proceedToMarkers()" type="button">
                  Next >>
                </button>
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentTab == tabConstants.SECTORS_SOURCE" class="row">
    <div class="col-lg-8 col-md-8">

      <div class="box">
        <h6>Source for sectors</h6>

        <div class="iati-box small">
          <table class="table table-bordered iati-box">
            <tr *ngFor="let project of aimsProjects">
              <td>
                <b>Project title: </b>{{project.title}} <br />
                <span class="badge badge-warning">AIMS</span>
                <table *ngIf="project.sectors.length > 0" class="table table-bordered">
                  <tr>
                    <th>Sector</th>
                    <th>Funds %</th>
                    <th>Action</th>
                  </tr>
                  <tr *ngFor="let sector of project.sectors">
                    <td>
                      {{sector.sector}}
                    </td>
                    <td>
                      <input [class.disable]="checkIfSectorInActionList(sector.sectorId)" type="number"
                        name="afpercent-{{project.id}}-{{sector.sectorId}}-" class="form-control"
                        [(ngModel)]="sector.fundsPercentage" required />
                    </td>
                    <td [class.disable]="checkIfSectorAdded(sector.sector)">
                      <button *ngIf="!checkIfSectorAdded(sector.sectorName)"
                        id="psector-{{project.id}}-{{sector.sectorId}}"
                        (click)="addSourceSectorToList(project.id, sector.sectorId, sourceTypes.AIMS)"
                        class="btn btn-success btn-sm"><i
                          [className]="checkIfSectorInActionList(sector.sectorId) ? 'fa fa-check' : 'fa fa-square'"></i></button>
                    </td>
                  </tr>
                </table>
                <div class="text-info" *ngIf="project.sectors.length == 0"><i>No sectors available</i></div>
              </td>
            </tr>
          </table>

          <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
            <tr *ngFor="let project of iatiProjects">
              <td>
                <b>Project title: </b>{{project.title}}<br />
                <span class="badge badge-warning">IATI</span>
                <table *ngIf="project.sectors.length > 0" class="table table-bordered">
                  <tr>
                    <th>Sector</th>
                    <th>NDP Sector</th>
                    <th>Funds %</th>
                    <th>Action</th>
                  </tr>
                  <tr *ngFor="let sector of project.sectors">
                    <td>
                      {{sector.sectorName}}
                    </td>
                    <td>
                      <span class="text-info" *ngIf="isNdpSectorsLoading">Loading NDP sectors...</span>
                      <select *ngIf="!isNdpSectorsLoading" class="form-control" name="ndp"
                        [(ngModel)]="sector.mappingId">
                        <option *ngFor="let s of ndpSectorsList" [value]="s.id">
                          {{s.sectorName}}
                        </option>
                      </select>
                    </td>
                    <td>
                      <input [class.disable]="checkIfSectorInActionList(sector.sectorId)" type="number"
                        name="{{sector.sectorId}}-{{i}}" class="form-control" [(ngModel)]="sector.fundsPercentage"
                        required />
                    </td>
                    <td [class.disable]="isNdpSectorsLoading || !sector.mappingId || !sector.fundsPercentage">
                      <button *ngIf="!checkIfSectorAdded(sector.sectorName)"
                        id="psector-{{project.id}}-{{sector.sectorId}}"
                        (click)="addSourceSectorToList(project.id, sector.mappingId, sourceTypes.IATI, sector.code, sector.sectorName)"
                        class="btn btn-success btn-sm"><i
                          [className]="checkIfSectorInActionList(sector.mappingId) ? 'fa fa-check' : 'fa fa-square'"></i></button>
                    </td>
                  </tr>
                </table>
                <div class="text-info" *ngIf="project.sectors.length == 0"><i>No sectors available</i></div>
              </td>
            </tr>
          </table>
        </div>
      </div>

    </div>

    <div class="col-lg-4 col-md-4">
      <div class="box">
        <div class="row">
          <div class="col-md-6">
            <h6>Project sectors</h6>
          </div>
          <div class="col-md-6">
            <div class="text-right">
              <button class="btn btn-warning btn-sm text-uppercase" (click)="showSectorsLocations()">
                Go back
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <span *ngIf="currentProjectSectors.length > 0" class="float-left text-info col-md-6"><b>Percentage</b>:
            {{calculateSectorPercentage()}}%
          </span>
          <span class="col-md-6 text-right"><button *ngIf="areUnSavedSectors()" (click)="saveProjectSectors()"
              class="btn btn-info btn-sm text-uppercase">Save sectors</button>
          </span>
        </div>

        <table *ngIf="currentProjectSectors.length > 0" class="table table-striped small margin-top-5">
          <thead>
            <tr>
              <th>Sector</th>
              <th class="text-center">Percentage</th>
              <th class="text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sector of currentProjectSectors">
              <td>{{sector.sector}}</td>
              <td class="text-center">{{sector.fundsPercentage}}</td>
              <td class="text-center">
                <button *ngIf="sector.saved" title="Delete sector" (click)="deleteProjectSector(sector.sectorId)"
                  class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
                <button *ngIf="!sector.saved && sector.sectorId" title="Delete sector"
                  (click)="removeProjectSector(sector.sectorId, false)" class="btn btn-warning btn-sm"><i
                    class="fa fa-remove"></i></button>
                <button *ngIf="!sector.saved && !sector.sectorId" title="Remove sector"
                  (click)="removeProjectSector(sector.mappingId, true)" class="btn btn-warning btn-sm"><i
                    class="fa fa-remove"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <span *ngIf="currentProjectSectors.length == 0" class="text-info"><i>No saved sectors found</i></span>

        <h6>Sectors from source</h6>
        <table *ngIf="sourceSectorsList.length > 0" class="table small">
          <thead>
            <tr>
              <th>Sector</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tr *ngFor="let sector of sourceSectorsList">
            <td>{{sector.sector}}</td>
            <td>{{sector.fundsPercentage}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <span class="text-right">
                <button [class.disable]="sourceSectorPercentage < 1 || sourceSectorPercentage > 100" type="button"
                  (click)="saveSourceSectors()" class="btn btn-info text-uppercase">
                  Save sectors
                </button>
              </span>
            </td>
          </tr>
        </table>
        <span *ngIf="sourceSectorsList.length == 0" class="text-info"><i>No sectors added from sources yet</i></span>
      </div>
    </div>

  </div>

  <div *ngIf="currentTab == tabConstants.LOCATIONS_SOURCE" class="row">

    <div class="col-lg-12 col-md-12">

      <div class="box">
        <div class="row">
          <div class="col-md-6">
            <h6>Project locations</h6>
          </div>
          <div class="col-md-6">
            <div class="text-right">
              <button class="btn btn-warning btn-sm text-uppercase" (click)="showSectorsLocations()">
                Go back
              </button>
            </div>
          </div>
        </div>

        <div class="iati-box small">
          <table class="table table-bordered">
            <tr *ngFor="let project of aimsProjects">
              <td>
                <b>Project title: </b>{{project.title}}<br />
                <span class="badge badge-warning">AIMS</span>
                <table *ngIf="project.locations.length > 0" class="table table-bordered">
                  <tr>
                    <th>Location</th>
                    <th>Funds %</th>
                  </tr>
                  <tr *ngFor="let location of project.locations">
                    <td>
                      {{location.location}}
                    </td>
                    <td>
                      {{location.fundsPercentage}}
                    </td>
                  </tr>
                </table>
                <div class="text-info" *ngIf="project.locations.length == 0"><i>No locations available</i></div>
              </td>
            </tr>
          </table>

          <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
            <tr *ngFor="let project of iatiProjects">
              <td>
                <b>Project title: </b>{{project.title}}<br />
                <span class="badge badge-warning">IATI</span>
                <table *ngIf="project.locations.length > 0" class="table table-bordered">
                  <tr>
                    <th>Location</th>
                  </tr>
                  <tr *ngFor="let location of project.locations">
                    <td>
                      {{location.name}}
                    </td>
                  </tr>
                </table>
                <div class="text-info" *ngIf="project.locations.length == 0"><i>No locations available</i></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

  </div>

</block-ui>

<sublocation-modal *ngIf="isShowSubLocationsSettings" 
  [subLocations]="selectedSubLocations" 
  [selectedSubLocations]="settledSublocations"
  [locationId]="selectedLocationId"
  [locationName]="selectedLocationName"
  (updatedSubLocations)="updateSubLocationsForLocation($event)">
</sublocation-modal>
<error-modal message={{errorMessage}}></error-modal>