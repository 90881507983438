<div class="container-fluid view-area">
  <div class="row">
    <div class="col-md-6">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active btn-info" data-toggle="tab" >Organizations List</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/merge-organizations']">Merge Organizations</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <select class="form-control" name="organizationType" [(ngModel)]="organizationTypeId" 
        (change)="searchOrganizations()" #organizationType="ngModel">
        <option value="0">--Organization type--</option>
        <option *ngFor="let type of organizationTypes" value="{{type.id}}">
          {{type.typeName}}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Enter organization name" [(ngModel)]="criteria"
        (input)="searchOrganizations()" onkeypress="return event.keyCode != 13;">
        <div class="input-group-append">
          <button class="btn btn-outline-info" (click)="searchOrganizations()" type="button"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>
  </div>

  <div class="row text-center" *ngIf="isLoading">
    <div class="col-md-12">
      <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading" class="col-md-12">
    <div *ngIf="filteredOrganizationsList.length > 0" class="margin-top-10">
      <table  class="table table-bordered table-stripped">
        <tr>
          <th>Organization name</th>
          <th>Type</th>
          <th>
            <button title="Sort by updated on a recent date" (click)="sortOrgsByDateUpdatedDesc()" class="btn btn-info btn-sm"><i class="fa fa-arrow-down color-white"></i></button>&nbsp;
            Date updated&nbsp;
            <button title="Sort by updated on a least recent date" (click)="sortOrgsByDateUpdatedAsc()" class="btn btn-info btn-sm"><i class="fa fa-arrow-up color-white"></i></button>&nbsp;
          </th>
          <th>Actions</th>
        </tr>
        <tr *ngFor="let org of filteredOrganizationsList | paginate: { itemsPerPage: pagingSize, currentPage: p }">
          <td>{{org.organizationName}}</td>
          <td>{{org.organizationType}}</td>
          <td>{{formatDateUKStyle(org.dateUpdated)}}</td>
          <td>
            <a title="Edit" (click)="edit(org.id)" class="btn btn-success"><i class="fa fa-edit color-white"></i></a>
            &nbsp;
            <a title="Delete" (click)="delete(org.id)" class="btn btn-danger"><i class="fa fa-trash color-white"></i></a>
          </td>
        </tr>
      </table>
      <pagination-controls (pageChange)="p=$event"></pagination-controls>
    </div>
    <div class="col-md-12 margin-top-10" *ngIf="filteredOrganizationsList.length == 0" class="text-info margin-left-10">
          <i>No organizations to show</i>
    </div>
  </div>
</div>