<block-ui>


<div class="box margin-top-10">
    <div class="row small">
      <div class="col-md-6">
        <h6>Markers</h6>
        <div *ngFor="let field of markersList" class="sectors-list-box-small margin-top-10">
          <h6>{{field.fieldTitle}}</h6>
          <div>
            <select class="form-control" *ngIf="checkFieldType(field.fieldType) == 'Dropdown'"
              [(ngModel)]="fieldModel.dropdownId" (change)="selectFieldValue(field.fieldType, -1, field.id, $event)"
              name="drp-{{field.id}}">
              <option value="null">--Select One--</option>
              <option *ngFor="let val of field.values" value="{{val.id}}">{{val.value}}</option>
            </select>

            <table *ngIf="checkFieldType(field.fieldType) == 'Radio'" class="table">
              <tr>
                <td *ngFor="let val of field.values">
                  <input type="radio" name="rd-options-{{field.id}}" id="rd-{{val.id}}" [value]="val.isSelected"
                    (click)="selectFieldValue(field.fieldType, val.id, field.id, $event)" />&nbsp;{{val.value}}
                </td>
              </tr>
            </table>

            <table *ngIf="checkFieldType(field.fieldType) == 'Checkbox'" class="table">
              <tr>
                <td *ngFor="let val of field.values">
                  <input type="checkbox" name="chk-{{val.id}}" value="{{val.id}}" [(ngModel)]="val.isSelected"
                    [checked]="checkIfSelected(field.id, val.id)"
                    (click)="selectFieldValue(field.fieldType, val.id, field.id, $event)" />&nbsp;{{val.value}}
                </td>
              </tr>
            </table>

            <div *ngIf="checkFieldType(field.fieldType) == 'Text'" class="form-group">
              <input class="form-control" type="text" maxlength="100" id="txt-{{field.id}}" [(ngModel)]="field.values"
                (blur)="selectFieldValue(field.fieldType, 1, field.id, $event, true)" />
            </div>

            <span class="float-right margin-top-10">
                <table>
                  <tr>
                    <td>
                      <span tooltip="{{field.help}}" [options]="tooltipOptions">
                        <button class="btn btn-info btn-sm" type="button"><i class="fa fa-question-circle"></i></button>
                      </span>
                    </td>
                    <td>
                      <button class="btn btn-info btn-sm btn-block text-uppercase" (click)="saveProjectMarkers(field.id)"
                    type="button">Save Field</button>
                    </td>
                  </tr>
                </table>
            </span>

          </div>
        </div>
        <div *ngIf="markersList.length == 0">
          <span class="text-info"><i>No markers avaialble to show</i></span>
        </div>
      </div>

      <div class="col-md-6">
        <div class="sectors-list-box">
            <div class="row">
                <div class="col-sm-6">
                    <h6 class="card-title">Project markers</h6>
                </div>
                <div class="col-sm-6">
                    
                </div>
            </div>
          
          <table *ngIf="currentProjectMarkers.length > 0" class="table table-bordered margin-top-10">
            <tr>
              <th>Marker</th>
              <th>Values</th>
              <th>Actions</th>
            </tr>
            <tr *ngFor="let field of currentProjectMarkers">
              <td>{{field.fieldTitle}}</td>
              <td>{{displayFieldValues(field.values)}}</td>
              <td>
                <button id="ffield-{{projectId}}-{{field.markerId}}" (click)="deleteProjectMarker($event)"
                  class="btn btn-danger btn-sm">
                  <i id="ifield-{{projectId}}-{{field.markerId}}" class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </table>
          <span *ngIf="currentProjectMarkers.length == 0" class="text-info">
            <i>No markers set for this project yet</i>
          </span>
        </div>
        <div class="row margin-top-10">
            <div class="col-md-12">
                <span class="float-right">
                    <button class="btn btn-info text-uppercase" (click)="proceedToFinish()" type="button">
                        Next >>
                    </button>
                </span>
            </div>
        </div>
      </div>
    </div>
  </div>

</block-ui>