<div class="container-fluid view-area">

  <block-ui>
    <div class="row">
      <div class="col-md-6">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a [ngClass]="{'btn-info': currentTab == 'messages', 'text-info': currentTab != 'messages'}" href="#"
              class="nav-link text-info" (click)="showMessages()" data-toggle="tab">Email messages list</a>
          </li>
          <!--<li class="nav-item">
            <a [ngClass]="{'btn-info': currentTab == 'email', 'text-info': currentTab != 'email'}" href="#"
              class="nav-link text-info" (click)="showEmail()" data-toggle="tab">Send email</a>
          </li>-->
          <!--<li *ngIf="permissions.canEditLocation" class="nav-item">
            <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/manage-email-message']">Create new message</a>
          </li>-->
        </ul>
      </div>
      <div class="col-md-6">
        <div *ngIf="currentTab == 'messages'" class="input-group mb-3">
          <input type="text" placeholder="{{inputTextHolder}}" class="form-control" id="criteria"
            (input)="searchMessages()" [(ngModel)]="criteria" name="criteria" #criteriaModel="ngModel"
            onkeypress="return event.keyCode != 13;" required>
          <div class="input-group-append">
            <button class="btn btn-outline-info" (click)="searchMessages()" type="button"><i
                class="fa fa-search"></i></button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab == 'messages'" class="row">

      <div class="text-center" *ngIf="isLoading">
        <div class="col-md-12">
          <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
        </div>
      </div>

      <div *ngIf="!isLoading" class="col-md-12">
        <div *ngIf="filteredEmailMessagesList.length > 0" class="col margin-top-10">
          <table class="table table-bordered table-stripped">
            <tr>
              <th>Subject</th>
              <th>Message</th>
              <th>Footer message</th>
              <th *ngIf="permissions.canEditEmailMessage">Actions</th>
            </tr>
            <tr *ngFor="let msg of filteredEmailMessagesList | paginate: { itemsPerPage: pagingSize, currentPage: p }">
              <td>{{msg.subject}}</td>
              <td>{{msg.message | truncate}} <a href="#" title="View message detail" id="msg-{{msg.id}}"
                  (click)="viewEmailMessage($event)" class="text-info"><b>View detail</b></a><br /></td>
              <td>{{msg.footerMessage}}</td>
              <td *ngIf="permissions.canEditEmailMessage">
                <a title="Edit" (click)="edit(msg.id)" class="btn btn-info"><i class="fa fa-edit color-white"></i></a>
                <!--&nbsp;
              <a title="Delete" (click)="delete(msg.id)" class="btn btn-danger"><i class="fa fa-trash color-white"></i></a>-->
              </td>
            </tr>
          </table>
          <pagination-controls (pageChange)="p=$event"></pagination-controls>
        </div>
        <div *ngIf="filteredEmailMessagesList.length == 0" class="col-md-12 text-info">
          <i>No messages to show</i>
        </div>
      </div>

    </div>

  </block-ui>
</div>

<info-modal title="Email message" message="{{infoMessage}}"></info-modal>
<error-modal message="{{errorMessage}}"></error-modal>