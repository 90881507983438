<div class="container-fluid view-area">

  <block-ui>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Delete/Re-assigning sector</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/sectors']">Sectors list</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="card card-signin my-5">
          <div class="card-body iati-box">
            <h5 class="card-title text-center">Effected projects list</h5>
            <div *ngIf="isLoading">
              Loading projects...
            </div>
            <table *ngIf="projectsList.length > 0" class="table table-bordered">
              <tr *ngFor="let project of projectsList; let number = index">
                <td>
                  <span class="float-left">
                      <span class="badge badge-info">{{number + 1}}</span> {{project.title}}
                  </span>
                </td>
              </tr>
            </table>
            <span *ngIf="projectsList.length == 0" class="text-info">
              No projects to show
            </span>
          </div>
        </div>
      </div>

      <!--Sectors box from AIMS-->
      <div class="col-md-6 col-sm-6">
        <div class="card card-signin my-5">
          <div class="card-body iati-box">
            <form class="form-signin" (ngSubmit)="sectorForm.form.valid && deleteAndMergeSector()"
              #sectorForm="ngForm">
              <div class="form-group">
                <label for="name">New mapping sector</label>
                <select class="form-control" id="sectorId" [(ngModel)]="model.sectorId"
                  name="sectorId" #sectorId="ngModel" required>
                  <option *ngFor="let sec of sectorsList" [value]="sec.id">
                    {{sec.sectorName}}</option>
                </select>
              </div>

              <h5 class="card-title text-center">Delete confirmation</h5>
                <div class="form-group">
                  <p class="text-info">
                    You can assign the new sector to effected project/s. It is recommended to assign a new
                    mapping to the deleting sector that has dependent projects or remove the sector from
                    dependent projects first. You can safely delete a sector that does not have dependent 
                    projects.
                  </p>
                </div>
                <button [class.disable]="isLoading" class="btn btn-lg btn-danger btn-block text-uppercase" type="submit">Delete Now</button>
              </form>
          </div>
        </div>
      </div>
    </div>

  </block-ui>
</div>

<error-modal message={{errorMessage}}></error-modal>
