<div class="container-fluid view-area">
  <block-ui>

    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/organizations']">Organizations list</a>
              </li>
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Merge organizations</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 margin-top-10">
        <div class="input-group" [ngClass]="{disabledDiv: isTextReadOnly}">
          <input type="text" placeholder="{{inputTextHolder}}" class="form-control" id="title"
            (input)="filterOrganizations()" [(ngModel)]="model.title" name="title" #title="ngModel"
            onkeypress="return event.keyCode != 13;" required>
          <span class="input-group-btn">
            <button class="btn btn-outline-info btn-lg" type="button">
              <i class="fa fa-search"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="col-md-6 margin-top-10">
        <div class="float-right">
          <table>
            <tr>
                <td>
                    <button [class.disable]='(selectedOrganizations.length != 1)'
                      class="btn btn-warning btn-lg pull-right float-right" (click)="renameOrganization()">Rename
                      organization</button>
                  </td>
              <td>
                <button [class.disable]='(selectedOrganizations.length < 2)'
                  class="btn btn-info btn-lg pull-right float-right" (click)="checkIfOrganizationsHaveUsers()">Merge
                  organizations</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <!--IATI Activity Box-->
      <div class="col-md-6 col-sm-6">
        <div class="card card-signin my-5">
          <div class="card-body iati-box">
            <h5 class="card-title text-center">Organizations listing</h5>
            <div *ngIf="isLoading">
              Loading organizations...
            </div>
            <table *ngIf="filteredOrganizationsList.length > 0" class="table table-bordered">
              <tr *ngFor="let organization of filteredOrganizationsList">
                <td>
                  <span class="float-left">
                    {{organization.organizationName}}
                  </span>
                  <span class="float-right margin-right-15">
                    <button [class.disable]="isOrganizationAppliedForMerge(organization.id)" type="button" id="select-{{organization.id}}" class="btn btn-success btn-sm"
                      (click)="selectOrganization($event)">Select >></button>
                  </span>
                </td>
              </tr>
            </table>
            <span *ngIf="filteredOrganizationsList.length == 0 && !isLoading" class="text-info">
              No organizations to show
            </span>
          </div>
        </div>
      </div>

      <!--Organizations box from AIMS-->
      <div class="col-md-6 col-sm-6">
        <div class="card card-signin my-5">
          <div class="card-body iati-box">
            <form class="form-signin" (ngSubmit)="organizationForm.form.valid"
              #organizationForm="ngForm">
              <div class="form-group">
                <label>Organization type</label>
                <select name="organizationType" class="form-control" [(ngModel)]="model.organizationTypeId" #organizationType="ngModel" >
                  <option value="0">--Select one--</option>
                  <option *ngFor="let type of organizationTypes" value={{type.id}}>
                    {{type.typeName}}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="name">New name for organization/s</label>
                <input type="text" placeholder="Enter new name for merged organizations" class="form-control" id="name" maxlength="200" [(ngModel)]="model.name" name="name"
                  #title="ngModel" required>
                <div *ngIf="organizationForm.submitted" [hidden]="name.valid" class="alert alert-danger">
                  New name for merge is required
                </div>
              </div>

            </form>

            <h5 class="card-title text-center">Selected organizations</h5>
            <div>
              <div *ngIf="selectedOrganizations.length > 1" class="text-info">
                For organizations merge please indicate an organization from the list 
                for envelope data adjustments. Envelope data for the selected organization will 
                be assigned to the newly created organization after merge is completed 
                successfully.
              </div>
              <table *ngIf="selectedOrganizations.length > 0" class="table table-bordered">
                <tr *ngFor="let organization of selectedOrganizations">
                  <td>
                    <span class="float-left">
                      {{organization.organizationName}}<br />
                    </span>
                    <span class="float-right margin-right-15">
                      <span *ngIf="checkIfOrganizationHasEnvelope(organization.id)">
                        <span *ngIf="selectedOrganizations.length > 1 && envelopeOrganizationId == organization.id" class="margin-right-2">
                          <button [class.disable]="true" *ngIf="selectedOrganizations.length > 1" type="button" id="eorg-{{organization.id}}"
                            class="btn btn-warning btn-sm">
                            <i class="fa fa-check"></i>&nbsp;Envelope selected
                          </button>
                        </span>
                        <span *ngIf="selectedOrganizations.length > 1 && envelopeOrganizationId != organization.id" class="margin-right-2">
                          <button *ngIf="selectedOrganizations.length > 1" type="button" id="eorg-{{organization.id}}"
                            class="btn btn-warning btn-sm" (click)="setEnvelopeOrganization(organization.id)">
                            Use envelope
                          </button>
                        </span>
                      </span>
                      
                      <button type="button" id="dselect-{{organization.id}}" class="btn btn-success btn-sm"
                        (click)="deSelectOrganization($event)">De-select</button>
                    </span>
                  </td>
                </tr>
              </table>
              <span *ngIf="selectedOrganizations.length == 0" class="text-info">
                No organizations to show
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--End of Boxes row-->

  </block-ui>
</div>

<error-modal message={{errorMessage}}></error-modal>
<info-modal message={{successMessage}}></info-modal>