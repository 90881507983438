<div class="container-fluid view-area">

    <block-ui *ngIf="!isCreateContactEmails">

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a [ngClass]="{'btn-info': currentTab == userTypeConstants.MANAGER_USER, 'text-info': currentTab != userTypeConstants.MANAGER_USER}"
                            href="#" class="nav-link text-info" (click)="showManagerUsers()" data-toggle="tab">Manager
                            users</a>
                    </li>
                    <li class="nav-item">
                        <a [ngClass]="{'btn-info': currentTab == userTypeConstants.STANDARD_USER, 'text-info': currentTab != userTypeConstants.STANDARD_USER}"
                            href="#" class="nav-link text-info" (click)="showStandardUsers()" data-toggle="tab">Standard
                            users</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-6">
                <span class="float-right">
                    <button class="btn btn-info" (click)="exportContactEmails()">Export emails</button>
                </span>
            </div>
            <!--<div class="col-lg-6 col-md-6">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Enter email/organization to search" [(ngModel)]="criteria"
                    (input)="searchUsers()" onkeypress="return event.keyCode != 13;">
                    <div class="input-group-append">
                      <button class="btn btn-outline-info" (click)="searchUsers()" type="button"><i class="fa fa-search"></i></button>
                    </div>
                  </div>
            </div>-->
        </div>

        <div class="row text-center" *ngIf="isManagersLoading || isStandardUsersLoading">
            <div class="col-md-12">
              <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading users...</span>
            </div>
          </div>

        <div *ngIf="currentTab == userTypeConstants.MANAGER_USER && !isManagersLoading" class="row">
            <div class="col-lg-12 col-md-12">
                <div>
                    <table *ngIf="managerUsers.length > 0" class="table table-striped">
                        <tr>
                            <th>Email</th>
                            <th>Organization</th>
                            <th>Action</th>
                        </tr>
                        <tr *ngFor="let user of managerUsers | paginate: { itemsPerPage: pagingSize, currentPage: m, id: 'm'}">
                            <td>{{user.email}}</td>
                            <td>{{user.organization}}</td>
                            <td [class.disable]="loggedInUserId == user.id">
                                <button id="demote-{{user.id}}" (click)="demoteUser(user.id)" class="btn btn-info btn-sm">Demote</button>
                            </td>
                        </tr>
                    </table>
                    <pagination-controls (pageChange)="m = $event" id="m"></pagination-controls>
                </div>
                <div *ngIf="managerUsers.length == 0">
                    <span class="text-info">
                        <i>No manager users to show</i>
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="currentTab == userTypeConstants.STANDARD_USER && !isStandardUsersLoading" class="row">
            <div class="col-lg-12 col-md-12">
                <div *ngIf="standardUsers.length > 0">
                    <table  class="table table-striped">
                        <tr>
                            <th>Email</th>
                            <th>Organization</th>
                            <th>Action</th>
                        </tr>
                        <tr *ngFor="let user of standardUsers | paginate: { itemsPerPage: pagingSize, currentPage: s, id: 's' }">
                            <td>{{user.email}}</td>
                            <td>{{user.organization}}</td>
                            <td [class.disable]="loggedInUserId == user.id">
                                <button id="promote-{{user.id}}" (click)="promoteUser(user.id)" class="btn btn-info btn-sm">Promote</button>
                            </td>
                        </tr>
                    </table>
                    <pagination-controls (pageChange)="s = $event" id="s"></pagination-controls>
                </div>
                
                <div *ngIf="standardUsers.length == 0">
                    <span class="text-info">
                        <i>No manager users to show</i>
                    </span>
                </div>
            </div>
        </div>
    </block-ui>

    <div *ngIf="isCreateContactEmails">
        <contact-emails [emails]="selectedEmails" [userEmail]="userEmail" (showUsers)="showUsers($event)">
        </contact-emails>
    </div>
    
</div>