import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-iati',
  templateUrl: './document-iati.component.html',
  styleUrls: ['./document-iati.component.css']
})
export class DocumentIatiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
