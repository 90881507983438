export const Messages = {
    USER_REGISTRATION_MESSAGE: "Your registration has been sent to other users in your organisation for approval, or if you setup a new organisation, then it has been sent to the AIMS management for approval.",
    NEW_RECORD: ' added successfully',
    RECORD_UPDATED: ' updated successfully',
    RECORD_DELETED: ' deleted successfully',
    PROJECT_DEPENDENCY: 'You need to create and save a project before this action.',
    ENTITY_EXISTS: ' already exists',
    INVALID_ORG_NAME: 'Enter valid organization name having at least length of 2 characters',
    NEGATIVE_DISBURSEMENT: 'Disbursement value cannot be in negative',
    ALREADY_IN_LIST: ' is already added to list.',
    INVALID_INPUT: 'Data is not in valid format for submission',
    EX_RATE_REQUIREMENTS: 'Date, currency, and exchange rate source are required to fetch exchange rate',
    SAVED_SUCCESSFULLY: ' saved successfully',
    INVALID_DATE: 'Enter valid date',
    INVALID_DATE_RANGE: 'Start date cannot be greater than end date',
    INVALID_YEAR: 'Invalid year provided',
    MERGE_ORG_REQUESTED: 'Merge request for the selected organizations is forwarded successfully to other members of the organizations. You will be notified about any changes to the status of request.',
    INVALID_START_END_YEAR: 'Starting year cannot be greater than ending year',
    INVALID_FUTURE_DATE: 'You cannot select a date in future',
    PASSWORD_RESET_SENT: 'Password reset link sent to the provided email Id',
    EMAIL_NOT_FOUND: 'We could not find the provided email id in our records',
    PERCENTAGE_RANGE: ' must have value between 1 and 100',
    INVALID_ATTEMPT: 'You made an invalid attempt for this action',
    CANNOT_BE_ZERO: 'cannot be less than or equal to zero',
    INVALID_PERCENTAGE: 'Percentage total cannot be greater than 100%',
    PERCENT_MUST_EQUAL_HUNDRED: 'Percentage must be equal to 100%',
    INVALID_STARTEND_DATE: 'Start date cannot be greater than end date',
    INVALID_API_KEY: 'API key cannot be set as null',
    INVALID_EXRATE_SAVE: 'You must provide some updated exchange rates to save',
    INVALID_ENVELOPE: 'The sum of all actual allocations shall not be less than total committed funds',
    NULL_VALUE: 'Value cannot be set null for ',
    INVALID_TRANSACTION_DATE: ' date must be between project start and end date',
    INVALID_RADIO_VALUES: 'Provide values of both options for radio button',
    INVALID_OPTIONS_LIST: 'Provide at least one option for list/dropdown/checkbox',
    INVALID_OPTION_VALUE: 'You must provide at least single value for the selected field',
    ATLEAST_PROJECT_MERGE: 'At least two projects are required for merge',
    ATLEAST_ORGANIZATION_MERGE: 'At least two organizations are required for merge',
    INVALID_PROJECT_MERGE: 'Two or more projects must be selected to complete the merge process',
    INVALID_DISBURSEMENT: 'You cannot add more disbursements than the project total value. Please increase the project funding amount before adding more disbursements.',
    PASSWORD_UPDATED: 'Your password updated successfully. You can now login with your new password',
    ACCOUNT_DELETED: 'Your account is deleted successfully. In order to use AIMS again, register again with your Organization',
    INVALID_SECTOR_MAPPING: 'Select a sector before saving the mappings',
    EX_RATE_NOT_FOUND: 'Exchange rate could not be found for the selected source. Please select another source or contact administrator',
    INVALID_ENVELOPE_MANUAL_AMOUNT: 'Manually entered amount cannot be less than total expected amount',
    SELECT_IMPLEMENTER_FROM_LIST: 'You must select an implementer from the list',
    INVALID_IMPLEMENTER: 'Select a valid implementer name',
    DELETION_REQUEST_INFO: 'Request for deletion of project is successfuly forwarded for approval',
    ONLY_ONE_ORGANIZATION_RENAME: 'You must select only one organization for merge',
    PROJECT_FOR_INFORMATION_REQUIRED: 'Select a project before your proceed with the information request',
    MEMBERSHIP_REQUEST_MESSAGE: 'Your request is submitted successfully, you will get a notification about the status of this request through email',
    DATA_BACKUP_MESSAGE: 'Data backup is performed successfully for the current state of the database.',
    DATA_RESTORE_MESSAGE: 'Data restore is performed successfully using the selected backup file',
    INVALID_DISBURSEMENT_SPLIT: 'All the amounts are entered manually, split feature cannot be applied.',
    ORG_NOT_PROVIDED_PROJECT: 'Please provide your organization as funder/implementer',
    INVALID_PROJECT_VALUE_DISBURSEMENTS: 'Project value must be equal to disbursements total value. Adjust disbursements using financials tab and/or adjust project value here.',
    ORGANIZATIONS_MERGED: 'Organizations merged. System will logout you to bring your updated data on the next login',
    UNSAVED_DISBURSEMENTS: 'There are unsaved disbursements on this page. Please save disbursements before moving forward',
    ORG_CREATED_ADD_ANOTHER: 'Organization created successfully. You can now add another if required',
    START_YEAR_GREATER_THAN_ERROR: 'Starting financial year cannot be greater than ending financial year',
    LATEST_IATI_LOAD_MESSAGE: 'IATI Settings are updated. Active source for the IATI is changed, it will load the IATI for the settings. It is recommended not to change IATI settings until IATI is loaded. Please be patient, it will take some time.'
}