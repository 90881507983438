<div class="container-fluid view-area">
  
  <div class="row">
    <div class="col-md-6">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active btn-info" data-toggle="tab" >Envelope types list</a>
        </li>
        <li *ngIf="permissions.canEditEnvelopeType" class="nav-item">
          <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/manage-envelope-type']">Create new</a>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <div class="input-group">
        <input type="text" placeholder="{{inputTextHolder}}" class="form-control" id="criteria"
          (input)="searchEnvelopeTypes()" [(ngModel)]="criteria" name="criteria" #criteriaModel="ngModel"
          onkeypress="return event.keyCode != 13;" maxlength="15" required>
        <span class="input-group-btn">
          <button class="btn btn-outline-info btn-lg" type="button">
            <i class="fa fa-search"></i>
          </button>
        </span>
      </div>
    </div>
  </div>
  
  <div class="row text-center" *ngIf="isLoading">
    <div class="col-md-12">
      <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
    </div>
  </div>
  
  <div *ngIf="!isLoading" class="row">
    <div *ngIf="filteredEnvelopeTypes.length > 0" class="col margin-top-10">
      <table class="table table-bordered table-stripped">
        <tr>
          <th>Type name</th>
          <th *ngIf="permissions.canEditEnvelopeType">Actions</th>
        </tr>
        <tr *ngFor="let type of filteredEnvelopeTypes | paginate: { itemsPerPage: pagingSize, currentPage: p }">
          <td>{{type.typeName}}</td>
          <td *ngIf="permissions.canEditEnvelopeType">
            <a title="Edit" (click)="edit(type.id)" class="btn btn-success"><i class="fa fa-edit color-white"></i></a>
            &nbsp;
            <a title="Delete" (click)="delete(type.id)" class="btn btn-danger"><i class="fa fa-trash color-white"></i></a>
          </td>
        </tr>
      </table>
      <pagination-controls (pageChange)="p=$event"></pagination-controls>
    </div>
    <div *ngIf="filteredEnvelopeTypes.length == 0" class="col-md-12 text-info">
      <i>No envelope types to show</i>
    </div>
  </div>
  </div>
  