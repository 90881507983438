<div class="container-fluid view-area">
  <block-ui>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Locations report</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div *ngIf="!isLoading" class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <form class="form-signin" (ngSubmit)="searchProjectsByCriteriaReport()" #searchForm="ngForm">
              <div class="row">
                <div class="col-md-6">
                  <ng-multiselect-dropdown [placeholder]="'-- All project/s --'" title="AIMS Projects" 
                    [settings]="projectsSettings"
                    [data]="projects"
                    [(ngModel)]="model.selectedProjects" name="selectedProjects"
                    #selectedProjects="ngModel" (onSelect)="onProjectSelect($event)" 
                    (onDeSelect)="onProjectDeSelect($event)"
                    (onSelectAll)="onProjectSelectAll($event)"
                    (onDeSelectAll)="onProjectDeSelectAll($event)">
                  </ng-multiselect-dropdown>
                </div>
                <div class="col-md-6">
                  <table>
                    <tr>
                      <td>
                        Period from:&nbsp;
                      </td>
                      <td>
                        <select class="form-control" id="startingYear" title="Starting financial year"
                          [(ngModel)]="model.startingYear" name="startingYear" #startingYear="ngModel" 
                          (change)="onChangeStartingYear()" required>
                          <option value="0">--Start--</option>
                          <option *ngFor="let year of yearsList" [value]="year.financialYear">{{year.label}}
                          </option>
                        </select>
                      </td>
                      <td>&nbsp;&nbsp;</td>
                      <td>
                        <select class="form-control" id="endingYear" title="Ending financial year"
                          [(ngModel)]="model.endingYear" name="endingYear" #endingYear="ngModel"
                          (change)="onChangeEndingYear()" required>
                          <option value="0">--End--</option>
                          <option *ngFor="let year of yearsList" [value]="year.financialYear">{{year.label}}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row margin-top-10">
                <div [class.disable]="model.noLocationOption != 1" class="col-md-3">
                  <ng-multiselect-dropdown [placeholder]="'-- All location/s --'" title="Location/s"
                    [settings]="locationsSettings"
                    [data]="locationsList" [(ngModel)]="model.selectedLocations" 
                    name="selectedLocations" (onSelect)="onLocationSelect($event)"
                    (onDeSelect)="onLocationDeSelect($event)" (onSelectAll)="onLocationSelectAll($event)"
                    (onDeSelectAll)="onLocationDeSelectAll($event)"
                    #selectedLocations="ngModel">
                  </ng-multiselect-dropdown>
                </div>
                <div [class.disable]="model.noLocationOption != 1" class="col-md-3">
                  <ng-multiselect-dropdown [placeholder]="'-- All sub-location/s --'" title="Location/s"
                    [settings]="subLocationsSettings"
                    [data]="filteredSubLocationsList" [(ngModel)]="model.selectedSubLocations" 
                    name="selectedSubLocations" (onSelect)="onSubLocationSelect($event)"
                    (onDeSelect)="onSubLocationDeSelect($event)" (onSelectAll)="onSubLocationSelectAll($event)"
                    (onDeSelectAll)="onSubLocationDeSelectAll($event)"
                    #selectedSubLocations="ngModel">
                  </ng-multiselect-dropdown>
                </div>
                <div class="col-md-6">
                  <ng-multiselect-dropdown [placeholder]="'-- All organization/s --'" title="Organization/s"
                    [settings]="organizationsSettings"
                    [data]="organizationsList" [(ngModel)]="model.selectedOrganizations"
                    (onSelect)="onOrganizationSelect($event)"
                    (onDeSelect)="onOrganizationDeSelect($event)" (onSelectAll)="onOrganizationSelectAll($event)"
                    (onDeSelectAll)="onOrganizationDeSelectAll($event)"
                    name="selectedOrganizations" #selectedOrganizations="ngModel">
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="row margin-top-10">
                <div class="col-lg-3 col-md-3">
                  <select class="form-control" id="sectorLevel" [(ngModel)]="model.sectorLevel" name="sectorLevel"
                    title="Sector level" #sectorLevel="ngModel" (change)="manageSectorLevel();onChangeParentSector();">
                    <option value="0">--Sector levels--</option>
                    <option *ngFor="let level of sectorLevels" [value]="level.id">{{level.level}}
                    </option>
                  </select>
                </div>
                <div [class.disable]="(model.sectorLevel == 0)" class="col-lg-3 col-md-3">
                  <select class="form-control" id="sectorId" [(ngModel)]="model.sectorId" name="sectorId"
                    title="Select a sector" #sectorId="ngModel" >
                    <option value="0">--Select sector--</option>
                    <option *ngFor="let sector of sectorsList" [value]="sector.id">{{sector.sectorName}}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3 col-md-3">
                  <select class="form-control" id="markerId" [(ngModel)]="model.markerId" name="markerId"
                    title="Select a marker" (change)="getSelectedMarkerValues()" #sectorId="ngModel">
                    <option value="0">--Select marker--</option>
                    <option *ngFor="let marker of markersList" [value]="marker.id">{{marker.fieldTitle}}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3 col-md-3">
                  <ng-multiselect-dropdown [placeholder]="'-- All values --'" title="Marker value/s"
                    [settings]="markerValuesSettings"
                    [data]="markerValues" [(ngModel)]="model.markerValues" 
                    name="selectedMarkerValues" #selectedMarkerValues="ngModel">
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="row margin-top-10">
                <div class="col-lg-3 col-md-3">
                  <select class="form-control" id="markerId2" [(ngModel)]="model.markerId2" name="markerId2"
                    title="Select marker two" (change)="getSelectedMarkerValuesTwo()" #markerId1="ngModel">
                    <option value="0">--Select marker two--</option>
                    <option *ngFor="let marker of markersList" [value]="marker.id">{{marker.fieldTitle}}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3 col-md-3">
                  <ng-multiselect-dropdown [placeholder]="'-- All values --'" title="Marker-2 value/s"
                    [settings]="markerValuesSettings"
                    [data]="markerValues" [(ngModel)]="model.markerValues2" 
                    name="selectedMarkerValuesTwo" #selectedMarkerValues="ngModel">
                  </ng-multiselect-dropdown>
                </div>
                <div class="col-md-6">
                  <select [class.disable]="manualExRate == 0" class="form-control" title="Currency for reporting"
                    (change)="selectCurrency();currencyChanged();" id="selectedCurrency" [(ngModel)]="model.selectedCurrency"
                    name="selectedCurrency" #selectedCurrency="ngModel">
                    <option *ngFor="let currency of currenciesList" value="{{currency.currency}}">
                      {{currency.currencyName}}
                    </option>
                  </select>&nbsp;
                  <span *ngIf="manualExRate > 0 && model.selectedCurrency == nationalCurrencyName" class="text-warning">
                    <b><i>Exchange rate: 1 {{defaultCurrency}} = {{manualExRate}} {{nationalCurrencyName}} on
                        {{datedToday}}</i></b>
                  </span>
                  <span *ngIf="manualExRate == 0 && !isDataLoading" class="text-danger">
                    <i>No exchange rate available, contact Administrator.</i>
                  </span>
                </div>
              </div>

              <div class="row margin-top-10">
                <div class="col-md-12">
                  <div [class.disable]="!defaultCurrency">
                    <div class="float-right">
                      <button type="submit" class="btn btn-info margin-top-2">{{btnReportText}}</button>
                      <button *ngIf="reportDataList && reportDataList.reportSettings" type="button" class="btn btn-danger margin-left-2 margin-top-2"
                        (click)="generatePDF()">Download PDF</button>
                      <a *ngIf="excelFile" href="{{excelFile}}" class="btn btn-success margin-top-2 margin-left-2">Download spreadsheet</a>
                      <button *ngIf="reportDataList && reportDataList.reportSettings" type="button"
                        class="btn btn-warning margin-left-2 margin-top-2" (click)="printReport()">Print report</button>
                        <button *ngIf="isAnyFilterSet" type="button"
                        class="btn btn-primary margin-top-2 margin-left-2" (click)="resetFilters()">Reset filters</button>
                    </div>
                </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <div *ngIf="isLoading" class="col-lg-12 col-md-12 text-center">
        <img src="assets/images/loader-md.gif" /><span>&nbsp;Setting up report...</span>
      </div>
    </div>

    <div *ngIf="!isLoading" class="row margin-top-10">
      <div *ngIf="reportDataList && reportDataList.reportSettings" class="col-md-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
              <form class="form-inline float-right">
                <label>Chart type to display:&nbsp;</label>
                <select class="form-control" id="chartType" [(ngModel)]="model.chartType" name="chartType"
                  #chartType="ngModel" (change)="manageDataToDisplay()" required>
                  <option *ngFor="let chart of chartOptions" value="{{chart.id}}">
                    {{chart.title}}
                  </option>
                </select>
                <label>&nbsp;Data on chart:&nbsp;</label>
                <select [class.disable]="model.chartTypeName == chartTypes.STACKEDBAR" class="form-control" id="dataOption" [(ngModel)]="model.selectedDataOption" name="dataOption"
                  #dataOption="ngModel" (change)="manageDataToDisplay()" required>
                  <option value="null">-- Chart data option --</option>
                  <option *ngFor="let option of dataOptions" value="{{option.id}}">
                    {{option.value}}
                  </option>
                </select>
              </form>
          </div>
        </div>
      </div>
    </div>

    <div id="rpt-location-pdf-view" *ngIf="reportDataList && reportDataList.reportSettings"
      class="col-lg-12 col-md-12 margin-top-20">

      <div *ngIf="reportDataList.reportSettings && chartData.length > 0 && showChart" class="col-md-12 text-center">
        <h5>Locations report <small>(Currency: {{this.selectedCurrencyName}})</small></h5>
      </div>

      <div *ngIf="reportDataList.reportSettings && stackedChartData.length > 0 && isShowStackedChart"
        class="col-lg-12 col-md-12 margin-top-20">
        <div *ngIf="model.chartTypeName == chartTypes.STACKEDBAR" style="display: block;"
          class="canvas-container mx-auto col-lg-8 col-md-8">
            <canvas id="chart" baseChart [datasets]="stackedChartData" [labels]="stackedChartLabels" [options]="stackedChartOptions"
            [legend]="chartLegend" [chartType]="barChartType" (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        </div>
      </div>
      <div *ngIf="reportDataList.reportSettings && chartData.length > 0 && showChart" class="col-md-12">
        <div *ngIf="model.chartTypeName == chartTypes.BAR" style="display: block;"
          class="canvas-container mx-auto col-md-8">
          <canvas id="chart" baseChart [datasets]="chartData" [labels]="chartLables" [options]="barChartOptions"
            [legend]="chartLegend" [chartType]="model.chartTypeName" (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        </div>

        <div *ngIf="model.chartTypeName == chartTypes.LINE" style="display: block;"
          class="canvas-container mx-auto col-md-8">
          <canvas id="chart" baseChart [datasets]="chartData" [labels]="chartLables" [options]="barChartOptions"
            [legend]="chartLegend" [chartType]="model.chartTypeName" (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        </div>

        <div *ngIf="model.chartTypeName == chartTypes.RADAR" style="display: block;"
          class="canvas-container mx-auto col-md-8">
          <canvas id="chart" baseChart [datasets]="chartData" [labels]="chartLables" [options]="radarChartOptions"
            [chartType]="model.chartTypeName"></canvas>
        </div>

        <div *ngIf="model.chartTypeName == chartTypes.DOUGHNUT" style="display: block;"
          class="canvas-container mx-auto col-md-8">
          <canvas id="chart" baseChart [data]="doughnutChartData" [labels]="chartLables" [options]="pieChartOptions"
            [chartType]="model.chartTypeName" (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        </div>

        <div *ngIf="model.chartTypeName == chartTypes.PIE" style="display: block;"
          class="canvas-container mx-auto col-md-8">
          <canvas id="chart" baseChart [data]="chartData" [labels]="chartLables" [options]="pieChartOptions"
            [chartType]="model.chartTypeName" (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
        </div>

        <div *ngIf="model.chartTypeName == chartTypes.POLAR" style="display: block;"
          class="canvas-container mx-auto col-md-10">
          <canvas id="chart" baseChart [data]="chartData" [labels]="chartLables" [legend]="chartLegend"
            [chartType]="model.chartTypeName" (chartHover)="chartHovered($event)" [options]="pieChartOptions"
            (chartClick)="chartClicked($event)"></canvas>
        </div>
      </div>

      <div *ngIf="reportDataList.reportSettings" class="row margin-top-10" id="rpt-location-project">

        <div *ngIf="reportDataList && reportDataList.locationProjectsList" class="col-md-12">
          <table class="table table-striped table-condensed table-fixed small" style="margin-bottom: 0rem !important">
            <thead>
              <tr>
                <th width="15%">Project</th>
                <th class="text-center" width="15%">Funders</th>
                <th class="text-center" width="15%">Implementers</th>
                <th class="text-right" width="15%">Actual disbursements</th>
                <th class="text-right" width="15%">Planned disbursements</th>
              </tr>
            </thead>
          </table>

          <div *ngFor="let locationProject of reportDataList.locationProjectsList; let i = index;">
            <table class="table table-condensed table-fixed small row-highlight" style="margin-bottom: 0rem !important">
              <tbody>
                <tr class="row-highlight" title="Click here to view detail"
                  (click)="displayHideRow(locationProject.locationName)">
                  <td colspan="3">
                    <span *ngIf="!locationProject.isDisplay"><i class="fa fa-plus-square"></i></span>
                    <span *ngIf="locationProject.isDisplay"><i class="fa fa-minus-square"></i></span>
                    &nbsp;
                    <b>
                      <i>
                        {{locationProject.locationName}}
                      </i>
                    </b>
                  </td>
                  <td class="text-right">
                    {{formatNumber(locationProject.actualDisbursements)}}
                  </td>
                  <td class="text-right">
                    {{formatNumber(locationProject.plannedDisbursements)}}
                  </td>
                </tr>
                <tr [hidden]="!locationProject.isDisplay" class="child"
                  *ngFor="let project of locationProject.projects">
                  <td width="25%">{{project.title}}</td>
                  <td class="text-center word-wrap" width="15%">{{project.funders}}</td>
                  <td class="text-center word-wrap" width="15%">{{project.implementers}}</td>
                  <td class="text-right" width="15%">
                    {{formatNumber(project.actualDisbursements)}}<br />
                  </td>
                  <td class="text-right" width="15%">
                    {{formatNumber(project.plannedDisbursements)}}
                  </td>
                </tr>

                <tr class="child" [hidden]="!locationProject.isDisplay" *ngIf="locationProject.projects.length == 0">
                  <td width="100%" colspan="6">
                    <span class="text-info"><i>No projects found</i></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table class="table table-condensed table-striped small table-fixed">
              <tr>
                <th width="15%">&nbsp;</th>
                <th width="15%">&nbsp;</th>
                <th width="15%">&nbsp;</th>
                <th class="text-right" width="15%">
                  <b>
                    <i>
                      Total: {{formatNumber(getGrandTotalActualDisbursementForLocation())}}
                    </i>
                  </b>
                </th>
                <th class="text-right" width="15%">
                  <b>
                    <i>
                      Total: {{formatNumber(getGrandTotalPlannedDisbursementForLocation())}}
                    </i>
                  </b>
                </th>
              </tr>
            </table>
          </div>

        </div>

        <div class="col-md-12 small">
          <div *ngIf="reportDataList && reportDataList.reportSettings" class="text-center margin-top-20 text-info">
            Report link: <a class="text-info" href="{{reportDataList.reportSettings.reportUrl}}">{{reportDataList.reportSettings.reportUrl}}</a>
          </div>
          <div class="text-center margin-top-5">
            <span><i>Generated on: {{getTodaysDate()}}</i></span>
          </div>
        </div>
        
      </div>
    </div>

  </block-ui>
</div>

<error-modal message={{errorMessage}}></error-modal>