<div class="container-fluid view-area">
  <block-ui>

    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <!--<li class="nav-item">
            <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/sectors']">Sectors list</a>
          </li>-->
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Sector mappings</a>
          </li>
        </ul>
      </div>
    </div>

      <!--Sectors box from AIMS-->
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="card card-signin my-5 margin-top-10">
          <div class="card-body">
            <p class="text-info lead text-center">
              Select a sector type to see sectors list and mappings for NDP sectors. You may assign/update 
              the mapping using this section. 
            </p>
            <div class="row">
              <div [class.disable]="isLoading" class="form-group col-lg-4 col-md-4">
                <select type="text" class="form-control" id="name" (change)="getSectorsForType()" maxlength="200"
                  [(ngModel)]="model.sectorTypeId" name="sectorTypeId" #sectorTypeId="ngModel" required>
                  <option value="null">--Sector type--</option>
                  <option *ngFor="let sType of sectorTypes" value={{sType.id}}>{{sType.typeName}}</option>
                </select>
              </div>

              <div [class.disable]="model.sectorTypeId == null || model.sectorTypeId == 'null'" class="form-group col-lg-4 col-md-4">
                <input type="text" placeholder="Enter sector name to search" name="criteria" id="criteria" maxlength="25"
                [(ngModel)]="criteria" #otherSectorCriteria="ngModel" (input)="filterSectors()"
                onkeypress="return event.keyCode != 13;" />
              </div>

              <div [class.disable]="model.sectorTypeId == null || model.sectorTypeId == 'null'" class="form-group col-lg-4 col-md-4">
                <button
                        id="btn-show-unmapped"
                        (click)="updateUnMappedStatus()"
                        class="btn btn-success btn-sm"><i
                          [className]="showUnmappedOnly ? 'fa fa-check' : 'fa fa-square'"></i></button>&nbsp;{{unMappedText}}
              </div>
            </div>

            <div class="iati-box">
              <table *ngIf="filteredSectorsForType.length > 0" class="table table-bordered">
                <tbody>
                  <tr>
                    <th width="70%">Sector</th>
                    <th width="30%">Mapped to Somali sector</th>
                  </tr>
                  <tr *ngFor="let sector of filteredSectorsForType">
                    <td [class.disable]="checkIfSectorMapped(sector.id)">
                      <span class="float-left">
                        {{sector.sectorWithCode}}<br />
                      </span>
                    </td>
                    <td>
                      <select class="form-control" name="sector" (change)="addOrUpdateSectorMapping(sector.id)" [(ngModel)]="sector.mappingId" #mappingId="ngModel">
                        <option value="null">--Un-mapped--</option>
                        <option *ngFor="let sec of defaultSectors" value={{sec.id}}>
                          {{sec.sectorName}}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
                
              </table>
              <span *ngIf="filteredSectorsForType.length == 0 && isLoadingSectorsForType == false" class="text-info">
                <i>No sectors to show</i>
              </span>
              <span *ngIf="isLoadingSectorsForType" class="text-info">
                Wait loading...
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--End of Boxes row-->

  </block-ui>
</div>

<error-modal message={{errorMessage}}></error-modal>