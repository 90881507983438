<div class="container-fluid view-area">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card card-body">
          <h5 class="card-title text-center">Change Password</h5>
          <form [formGroup]="frmChangePassword" (submit)="submit()">
            <div class="form-group">
              <label for="password" [ngClass]="frmChangePassword.controls['password'].invalid ? '' : ''">Password</label>
              <input id="password" formControlName="password" type="password" class="form-control" [ngClass]="frmChangePassword.controls['password'].invalid ? 'is-invalid' : ''">
              <!-- <label class="text-danger col" *ngIf="frmChangePassword.controls['password'].hasError('required')">
                Password is Required!
              </label> -->
              <label class="col" [ngClass]="frmChangePassword.controls['password'].hasError('required') || frmChangePassword.controls['password'].hasError('minlength')  ? 'alert alert-danger' : 'alert alert-success'">
                <i class="material-icons">{{ frmChangePassword.controls['password'].hasError('required') ||
                  frmChangePassword.controls['password'].hasError('minlength') ? 'cancel' :
                  'check_circle' }}</i>
                Must be at least 4 characters
              </label>
            </div>
            <div class="example-form">
              <label for="confirmPassword" [ngClass]="frmChangePassword.controls['confirmPassword'].invalid ? '' : ''">Confirm
                Password</label>
              <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
                [ngClass]="frmChangePassword.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
              <label class="alert alert-danger" *ngIf="frmChangePassword.controls['confirmPassword'].hasError('required')">
                Password is required
              </label>
              <label class="alert alert-danger" *ngIf="frmChangePassword.controls['confirmPassword'].hasError('NoPassswordMatch')">
                Passwords do not match
              </label>
            </div>
             <p></p>
            <div class="form-group">
            <button [class.disable]="isBtnDisabled" type="submit" class="btn btn-lg btn-info btn-block text-uppercase">{{btnText}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>