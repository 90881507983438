<div class="container-fluid view-area">

    <block-ui>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active btn-info" data-toggle="tab">Export emails</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-6">
                <span class="float-right">
                    <button class="btn btn-info" (click)="showUsersManager()">Manage users</button>
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>Email from</label>
                    <input type="text" #txtEmailFrom class="form-control" [(ngModel)]="userEmail" /><br />
                    <button type="button" class="btn btn-info" (click)="copyEmailFrom()">Copy email</button>&nbsp;
                    <span *ngIf="emailCopied" class="text-warning"><i>Email copied</i></span>
                </div>
                <div class="form-group">
                    <label>Receivers email list</label>
                    <textarea #txtEmails rows="10" class="form-control">{{emailStr}}</textarea><br />
                    <button type="button" class="btn btn-info" (click)="copyEmails()">Copy emails</button>&nbsp;
                    <span *ngIf="emailsCopied" class="text-warning"><i>Emails copied</i></span>
                </div>
            </div>
        </div>

    </block-ui>
</div>