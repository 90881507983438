<div class="container-fluid view-area">
    <div class="row">
      <div class="col-md-6">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab" >Organizations types list</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/manage-organization-type']">Add organization type</a>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Enter organization type name" [(ngModel)]="criteria"
          (input)="searchOrganizationTypes()" onkeypress="return event.keyCode != 13;">
          <div class="input-group-append">
            <button class="btn btn-outline-info" (click)="searchOrganizationTypes()" type="button"><i class="fa fa-search"></i></button>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row text-center" *ngIf="isLoading">
      <div class="col-md-12">
        <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
      </div>
    </div>
  
    <div *ngIf="!isLoading" class="col-md-12">
      <div *ngIf="filteredOrganizationTypesList.length > 0" class="margin-top-10">
        <table  class="table table-bordered table-stripped">
          <tr>
            <th>Type name</th>
            <th>Actions</th>
          </tr>
          <tr *ngFor="let org of filteredOrganizationTypesList | paginate: { itemsPerPage: pagingSize, currentPage: p }">
            <td>{{org.typeName}}</td>
            <td>
              <a title="Edit" (click)="edit(org.id)" class="btn btn-success"><i class="fa fa-edit color-white"></i></a>
              &nbsp;
              <a title="Delete" (click)="delete(org.id)" class="btn btn-danger"><i class="fa fa-trash color-white"></i></a>
            </td>
          </tr>
        </table>
        <pagination-controls (pageChange)="p=$event"></pagination-controls>
      </div>
      <div class="col-md-12 margin-top-10" *ngIf="filteredOrganizationTypesList.length == 0" class="text-info margin-left-10">
            <i>No organization types to show</i>
      </div>
    </div>
  </div>