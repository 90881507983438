<div class="container-fluid view-area">
  <div class="row">
    <div class="col-md-6">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active btn-info" data-toggle="tab">Sector types list</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/manage-sectortype']">Create new</a>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Enter sector type name" [(ngModel)]="criteria"
          aria-describedby="basic-addon2" onkeypress="return event.keyCode != 13;" (input)="searchSectorTypes()">
        <div class="input-group-append">
          <button class="btn btn-outline-info" (click)="searchSectorTypes()" type="button"><i
              class="fa fa-search"></i></button>
        </div>
      </div>
    </div>
  </div>

  <div class="row text-center" *ngIf="isLoading">
    <div class="col-md-12">
      <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading" class="row">
    <div *ngIf="filteredSectorTypesList.length > 0" class="col margin-top-10">
      <table class="table table-bordered table-stripped">
        <tr>
          <th>Sector type name</th>
          <th>Default sector</th>
          <th>Source</th>
          <th>IATI code</th>
          <th>IATI source URL</th>
          <th>Actions</th>
        </tr>
        <tr *ngFor="let sector of filteredSectorTypesList | paginate: { itemsPerPage: pagingSize, currentPage: p }">
          <td>{{sector.typeName}}</td>
          <td>{{isTrue(sector.isPrimary)}}</td>
          <td>{{isSourceType(sector.isSourceType)}}</td>
          <td>{{displayIATICode(sector.iatiCode)}}</td>
          <td>{{sector.sourceUrl}}</td>
          <td>
            <a title="Rename" (click)="edit(sector.id)" class="btn btn-info"><i class="fa fa-edit color-white"></i></a>
            <a *ngIf="!sector.isSourceType" title="Delete" (click)="delete(sector.id)" class="btn btn-danger margin-left-2"><i class="fa fa-trash color-white"></i></a>
          </td>
        </tr>
      </table>
      
      <pagination-controls (pageChange)="p=$event"></pagination-controls>
    </div>
    <div *ngIf="filteredSectorTypesList.length == 0" class="text-info">
      <i>No sector types to show</i>
    </div>
  </div>
</div>