<jw-modal id="project-info-modal">

  <!--Loading project data -->
  <div class="row">
    <div class="col-md-12">
      <span class="float-right margin-right-15">
        <button *ngIf="isShowContact" class="btn btn-warning" (click)="contactProject();">Request a
          change</button>&nbsp;
        <button class="btn btn-danger" (click)="closeModal();">Close view</button>
      </span>
    </div>
  </div>
  <div class="row small">
    <div class="col margin-top-10">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <span class="float-left">
              <h6 class="text-info"><b>Project data</b></h6>
            </span>
            <table class="table table-striped">
              <tr>
                <td><b>Title: </b></td>
                <td colspan="3">{{project.title}}</td>
              </tr>
              <tr>
                <td><b>Start date: </b></td>
                <td>{{formatDateUKStyle(project.startDate)}}</td>
                <td><b>End date: </b></td>
                <td>{{formatDateUKStyle(project.endDate)}}</td>
              </tr>
              <tr>
                <td><b>Description: </b></td>
                <td colspan="3">
                  {{project.description}}
                </td>
              </tr>
            </table>

            <div class="row">
              <div class="col-md-6">
                <h6 class="text-info margin-top-10"><b>Project sectors</b></h6>
                <table *ngIf="sectors.length > 0" class="table table-striped">
                  <tr>
                    <th>Sector</th>
                    <th class="text-center">Funds percentage</th>
                  </tr>
                  <tr *ngFor="let sector of sectors">
                    <td>{{sector.sector}}</td>
                    <td class="text-center">{{sector.fundsPercentage}}</td>
                  </tr>
                </table>
                <span class="text-info" *ngIf="sectors.length == 0">
                  <i>No sectors found</i>
                </span>
              </div>

              <div class="col-md-6">
                <h6 class="text-info margin-top-10">Project locations</h6>
                <table *ngIf="locations.length > 0" class="table table-striped">
                  <tr>
                    <th>Location</th>
                    <th class="text-center">Funds percentage</th>
                  </tr>
                  <tr *ngFor="let location of locations">
                    <td>{{location.location}}</td>
                    <td class="text-center">{{location.fundsPercentage}}</td>
                  </tr>
                </table>
                <span *ngIf="locations.length == 0" class="text-info"><i>No locations found</i></span>

              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 class="text-info margin-top-10"><b>Project funders</b></h6>
                <table *ngIf="funders.length > 0" class="table table-striped">
                  <tr>
                    <th>Funder</th>
                  </tr>
                  <tr *ngFor="let funder of funders">
                    <td>{{funder.funder}}</td>
                  </tr>
                </table>
                <span class="text-info" *ngIf="funders.length == 0">
                  <i>No funders found</i>
                </span>
              </div>
              <div class="col-md-6">
                <h6 class="text-info margin-top-10"><b>Project implementers</b></h6>
                <table *ngIf="implementers.length > 0" class="table table-striped">
                  <tr>
                    <th>Implementor</th>
                  </tr>
                  <tr *ngFor="let implementer of implementers">
                    <td>{{implementer.implementer}}</td>
                  </tr>
                </table>
                <span class="text-info" *ngIf="implementers.length == 0">
                  <i>No implementers found</i>
                </span>
              </div>
            </div>

            <h6 class="text-info margin-top-10"><b>Project disbursements</b></h6>
            <table *ngIf="disbursements.length > 0" class="table table-striped">
              <tr>
                <th>Year</th>
                <th>Type</th>
                <th class="text-center">Amount</th>
                <th class="text-center">Currency</th>
                <th class="text-center">Exchange rate</th>
              </tr>
              <tr *ngFor="let disbursement of disbursements">
                <td>{{disbursement.year}}</td>
                <td>{{disbursementTypeConstants[disbursement.disbursementType]}}</td>
                <td class="text-center">{{disbursement.amount | number:0}}</td>
                <td class="text-center">{{disbursement.currency}}</td>
                <td class="text-center">{{disbursement.exchangeRate}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="disbursements.length == 0">
              <i>No disbursements found</i>
            </span>

            <h6 class="text-info margin-top-10"><b>Project documents</b></h6>
            <table *ngIf="documents.length > 0" class="table table-striped">
              <tr>
                <th>Document title</th>
                <th>Url</th>
              </tr>
              <tr *ngFor="let document of documents">
                <td>{{document.documentTitle}}</td>
                <td>{{document.documentUrl}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="documents.length == 0">
              <i>No documents found</i>
            </span>

            <h6 class="text-info margin-top-10"><b>Project markers</b></h6>
            <table *ngIf="markers.length > 0" class="table table-striped">
              <tr>
                <th>Marker name</th>
                <th>Value/s</th>
              </tr>
              <tr *ngFor="let field of markers">
                <td>{{field.fieldTitle}}</td>
                <td>{{displayFieldValues(field.values)}}</td>
              </tr>
            </table>
            <span class="text-info" *ngIf="markers.length == 0">
              <i>No markers found</i>
            </span>

          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 margin-top-10">
      <span class="float-right margin-right-15">
        <button *ngIf="isShowContact" class="btn btn-warning" (click)="contactProject();">Request a
          change</button>&nbsp;
        <button class="btn btn-danger" (click)="closeModal();">Close view</button>
      </span>
    </div>

  </div>

</jw-modal>