<div class="container-fluid view-area">

  <block-ui>

    <div class="row">
      <div class="col-lg-12 col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a [ngClass]="{'btn-info': currentTab == 'basic', 'text-info': currentTab != 'basic'}" href="#"
              class="nav-link text-info" (click)="showBasicInfo()" data-toggle="tab">Basic information</a>
          </li>
          <li *ngIf="activeProjectId != 0" class="nav-item">
            <a [ngClass]="{'btn-info': currentTab == 'financials', 'text-info': currentTab != 'financials'}" href="#"
              class="nav-link text-info" (click)="showFinancials()" data-toggle="tab">Financials</a>
          </li>
          <li *ngIf="activeProjectId != 0" class="nav-item">
            <a [ngClass]="{'btn-info': currentTab == 'sectors', 'text-info': currentTab != 'sectors'}" href="#"
              class="nav-link text-info" (click)="showSectors()" data-toggle="tab">Sectors & Locations</a>
          </li>
          <li *ngIf="activeProjectId != 0" class="nav-item">
            <a [ngClass]="{'btn-info': currentTab == 'markers', 'text-info': currentTab != 'markers'}" href="#"
              class="nav-link text-info" (click)="showMarkers()" data-toggle="tab">Markers</a>
          </li>
          <li *ngIf="activeProjectId != 0" class="nav-item">
            <a [ngClass]="{'btn-info': currentTab == 'finish', 'text-info': currentTab != 'finish'}" href="#"
              class="nav-link text-info" (click)="showFinish()" data-toggle="tab">Finish</a>
          </li>
        </ul>
      </div>
    </div>


    <div *ngIf="!isProjectLoading && currentTab == tabConstants.BASIC" class="col-lg-12 col-md-12">
      <basic-data [(projectData)]="projectData" [(projectDocuments)]="currentProjectDocuments"
        [(projectFunders)]="currentProjectFunders" [(projectImplementers)]="currentProjectImplementers"
        [financialYears]="financialYears" [exchangeRates]="exchangeRates" [organizationsList]="organizationsList"
        [fundingTypesList]="fundingTypesList" [currenciesList]="currenciesList" [(projectId)]="activeProjectId"
        [aimsProjects]="aimsProjects" [iatiProjects]="iatiProjects"
        [projectDisbursements]="currentProjectDisbursements"
        (projectFundersChanged)="updateProjectFunders($event)"
        (projectImplementersChanged)="updateProjectImplementers($event)"
        (projectCreated)="updateProjectId($event)"
        (proceedToFinancials)="showFinancials()"
        (projectDocumentsChanged)="updateProjectDocuments($event)"
        (disbursementsChanged)="updateProjectDisbursements($event)">
      </basic-data>
    </div>

    <div *ngIf="!isProjectLoading && currentTab == tabConstants.FINANCIALS" class="col-lg-12 col-md-12">
      <financials [projectCurrency]="projectData.projectCurrency" [projectValue]="projectData.projectValue"
        [exchangeRate]="projectData.exchangeRate" [exchangeRates]="exchangeRates" [currenciesList]="currenciesList"
        [projectDisbursements]="currentProjectDisbursements" [startingYear]="projectData.startingFinancialYear"
        [endingYear]="projectData.endingFinancialYear" [projectId]="activeProjectId"
        [aimsProjects]="aimsProjects" [iatiProjects]="iatiProjects"
        (proceedToSectors)="showSectors()"
        (disbursementsChanged)="updateProjectDisbursements($event)">
      </financials>
    </div>

    <div *ngIf="!isProjectLoading && currentTab == tabConstants.SECTORS" class="col-lg-12 col-md-12">
      <project-sectors [sectorTypesList]="sectorTypesList" [sectorsList]="sectorsList"
        [defaultSectorsList]="defaultSectorsList" [defaultSectorTypeId]="defaultSectorTypeId"
        [projectId]="activeProjectId" [defaultSectorType]="defaultSectorType" [locationsList]="locationsList"
        [currentProjectSectors]="currentProjectSectors" [currentProjectLocations]="currentProjectLocations"
        [aimsProjects]="aimsProjects" [iatiProjects]="iatiProjects" [subLocationsList]="subLocationsList"
        (proceedToNext)="showMarkers()"
        (projectSectorsChanged)="updateProjectSectors($event)"
        (projectLocationsChanged)="updateProjectLocations($event)"
        >
      </project-sectors>
    </div>

    <div *ngIf="!isProjectLoading && currentTab == tabConstants.MARKERS" class="col-lg-12 col-md-12">
      <project-markers [currentProjectMarkers]="currentProjectMarkers" [markersList]="markersList"
        [projectId]="activeProjectId"
        (projectMarkersChanged)="updateProjectMarkers($event)"
        (proceedToNext)="showFinish()"
      >
      </project-markers
      >
    </div>

    <div *ngIf="!isProjectLoading && currentTab == tabConstants.FINISH" class="col-lg-12 col-md-12">
      <project-finish [viewProject]="projectData" [viewProjectFunders]="currentProjectFunders"
        [viewProjectImplementers]="currentProjectImplementers" [viewProjectDocuments]="currentProjectDocuments"
        [viewProjectDisbursements]="currentProjectDisbursements" [viewProjectMarkers]="currentProjectMarkers"
        [viewProjectSectors]="currentProjectSectors" [viewProjectLocations]="currentProjectLocations"
        [projectId]="activeProjectId">
      </project-finish>
    </div>

  </block-ui>
</div>

<error-modal message="{{errorMessage}}">
</error-modal>