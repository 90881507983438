<jw-modal id="info-modal">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
          <div class="card-header bg-info text-white">{{title}}</div>
          <div class="card-body text-info">
              {{message}}
          </div>
        </div><br />
        <button class="btn btn-info" (click)="closeModal();">Close</button>
    </div>
  </div>
</jw-modal>