<div class="container-fluid view-area">
  <div class="row">
      <div class="col-lg-3 col-md-3 col-xs-6">
        <div class="card bg-light">
          <div class="card-body text-info padding-10">
            <div class="col-md-12">
                <div class="font-management-menu-item text-center">
                  <a [routerLink]="['/locations']" class="text-info">
                    Locations
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="col-lg-3 col-md-3 col-xs-6">
          <div class="card bg-light">
            <div class="card-body text-info padding-10">
              <div class="col-md-12">
                  <div class="font-management-menu-item text-center text-primary">
                    <a [routerLink]="['/organizations']" class="text-info">
                      Organizations
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-xs-6">
          <div class="card bg-light">
            <div class="card-body text-info padding-10">
              <div class="col-md-12">
                  <div class="font-management-menu-item text-center text-primary">
                    <a [routerLink]="['/sector-types']" class="text-info">
                      Sector Types
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-xs-6">
          <div class="card bg-light">
            <div class="card-body text-info padding-10">
              <div class="col-md-12">
                  <div class="font-management-menu-item text-center text-primary">
                    <a [routerLink]="['/sector-categories']" class="text-info">
                      Sector Categories
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
  
    </div>
  
    <div class="row margin-top-20">

        <div class="col-lg-3 col-md-3 col-xs-6">
            <div class="card bg-light">
              <div class="card-body text-info padding-10">
                <div class="col-md-12">
                    <div class="font-management-menu-item text-center text-primary">
                      <a [routerLink]="['/sector-subcategories']" class="text-info">
                        Sector Subcategories
                      </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-xs-6">
              <div class="card bg-light">
                <div class="card-body text-info padding-10">
                  <div class="col-md-12">
                      <div class="font-management-menu-item text-center text-primary">
                        <a [routerLink]="['/sectors']" class="text-info">
                          Sectors
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-xs-6">
              <div class="card bg-light">
                <div class="card-body text-info padding-10">
                  <div class="col-md-12">
                      <div class="font-management-menu-item text-center text-primary">
                        <a [routerLink]="['/projects']" class="text-info">
                          Projects
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    </div>