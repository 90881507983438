<div class="container-fluid view-area">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card card-body">
          <div *ngIf="isError" class="alert alert-danger alert-dismissible">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Error!</strong> {{errorMessage}}
          </div>
          <div *ngIf="isInfo" class="alert alert-info alert-dismissible">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Information!</strong> {{infoMessage}}
          </div>
          <h5 class="card-title text-center">Forgot Password</h5>
          <form class="form-signin" (ngSubmit)="resetForm.form.valid && sendPasswordResetLink(resetForm)" #resetForm="ngForm">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="Email" [(ngModel)]="model.email" name="Email"
                #Email="ngModel" required email>
              <div *ngIf="resetForm.submitted" [hidden]="Email.valid" class="alert alert-danger">
                Enter valid email address
              </div>
            </div>

            <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase"
              type="submit">{{btnText}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>