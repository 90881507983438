<jw-modal id="error-modal">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
          <div class="card-header bg-danger text-white">Error message</div>
          <div class="card-body text-danger">
              {{message}}
          </div>
        </div><br />
        <button class="btn btn-danger" (click)="closeModal();">Close</button>
    </div>
  </div>
</jw-modal>