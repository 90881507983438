<div class="container-fluid view-area">
  <block-ui>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Data import</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <div *ngIf="isError" class="alert alert-danger alert-dismissible">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Error!</strong> {{errorMessage}}
            </div>
            <div *ngIf="isInfo" class="alert alert-info alert-dismissible">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Information!</strong> {{infoMessage}}
            </div>

            <div>
              <h5 class="card-title text-center">Upload data file 2017</h5>
              <div class="row">
                <input type="file" accept=".xlsx" #fileold placeholder="Choose file"
                  (change)="uploadFileOld(fileold.files)" style="display:none;">
                <button type="button" class="btn btn-lg btn-info btn-block text-uppercase"
                  (click)="fileold.click()">Click
                  and select file</button>
              </div>
              <div class="row">
                <span class="upload" *ngIf="progressOld > 0">
                  Uploaded {{progressOld}}%
                </span>
                <span class="upload" *ngIf="messageOld">
                  {{messageOld}}
                </span>
              </div>

              <h5 class="card-title text-center margin-top-50">Upload data file 2018</h5>
              <div class="row">
                <input type="file" accept=".xlsx" #filenew placeholder="Choose file"
                  (change)="uploadFileNew(filenew.files)" style="display:none;">
                <button type="button" class="btn btn-lg btn-info btn-block text-uppercase"
                  (click)="filenew.click()">Click
                  and select file</button>
              </div>
              <div class="row">
                <span class="upload" *ngIf="progressNew > 0">
                  Uploaded {{progressNew}}%
                </span>
                <span class="upload" *ngIf="messageNew">
                  {{messageNew}}
                </span>
              </div>

              <h5 class="card-title text-center margin-top-50">Upload latest data file</h5>
              <div class="row">
                <input type="file" accept=".xlsx" #filelatest placeholder="Choose file"
                  (change)="uploadFileLatest(filelatest.files)" style="display:none;">
                <button type="button" class="btn btn-lg btn-info btn-block text-uppercase"
                  (click)="filelatest.click()">Click
                  and select file</button>
              </div>
              <div class="row">
                <span class="upload" *ngIf="progressLatest > 0">
                  Uploaded {{progressLatest}}%
                </span>
                <span class="upload" *ngIf="messageNew">
                  {{messageLatest}}
                </span>
              </div>

              <h5 class="card-title text-center margin-top-50">Upload envelope data file</h5>
              <div class="row">
                <input type="file" accept=".xlsx" #fileenvelope placeholder="Choose file"
                  (change)="uploadFileEnvelope(fileenvelope.files)" style="display:none;">
                <button type="button" class="btn btn-lg btn-info btn-block text-uppercase"
                  (click)="fileenvelope.click()">Click
                  and select file</button>
              </div>
              <div class="row">
                <span class="upload" *ngIf="progressLatest > 0">
                  Uploaded {{progressLatest}}%
                </span>
                <span class="upload" *ngIf="messageNew">
                  {{messageLatest}}
                </span>
              </div>

              <h5 class="card-title text-center margin-top-50">Upload organization types data file</h5>
              <div class="row">
                <input type="file" accept=".xlsx" #fileorgtypes placeholder="Choose file"
                  (change)="uploadFileOrganizationTypes(fileorgtypes.files)" style="display:none;">
                <button type="button" class="btn btn-lg btn-info btn-block text-uppercase"
                  (click)="fileorgtypes.click()">Click
                  and select file</button>
              </div>
              <div class="row">
                <span class="upload" *ngIf="progressLatest > 0">
                  Uploaded {{progressLatest}}%
                </span>
                <span class="upload" *ngIf="messageNew">
                  {{messageLatest}}
                </span>
              </div>

              <h5 class="card-title text-center margin-top-50">Upload fix for Ghost organizations</h5>
              <div class="row">
                <input type="file" accept=".xlsx" #fileghost placeholder="Choose file"
                  (change)="uploadFileFixGhostOrgs(fileghost.files)" style="display:none;">
                <button type="button" class="btn btn-lg btn-info btn-block text-uppercase"
                  (click)="fileghost.click()">Click
                  and select file</button>
              </div>
              <div class="row">
                <span class="upload" *ngIf="progressLatest > 0">
                  Uploaded {{progressLatest}}%
                </span>
                <span class="upload" *ngIf="messageNew">
                  {{messageLatest}}
                </span>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

  </block-ui>
</div>