<div class="container-fluid view-area">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/sponsors']">Sponsors list</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Create new</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <h5 class="card-title text-center">Manage sponsor logo</h5>
  
            <form class="form-signin" (ngSubmit)="sponsorForm.form.valid && saveSponsor()" #sponsorForm="ngForm">
              <div class="form-group">
                <label for="text">Select logo to upload</label>
                <label class="btn btn-default">
                    <input type="file" (change)="selectFile($event)" />
                  </label>
              </div>
              <div class="form-group">
                <label for="text">Sponsor name: </label>
                <input type="text" class="form-control" id="sponsorName" [(ngModel)]="model.sponsorName" name="sponsorName"
                  #sponsorName="ngModel" required>
                <div *ngIf="sponsorForm.submitted" [hidden]="sponsorName.valid" class="alert alert-danger">
                  Sponsor name is required
                </div>
              </div>
  
              <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase"
                type="submit">{{btnText}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <error-modal message="{{errorMessage}}"></error-modal>