<div class="container-fluid view-area">
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card card-signin my-5">
            <div class="card-body">
              <div *ngIf="isError" class="alert alert-danger alert-dismissible">
                <button type="button" class="close" data-dismiss="alert">&times;</button>
                <strong>Error!</strong> {{errorMessage}}
              </div>
              <h5 class="card-title text-center">Log in</h5>
              <form class="form-signin" (ngSubmit)="loginForm.form.valid && authenticateUser()" #loginForm="ngForm">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" class="form-control" id="Email" [(ngModel)]="model.Email" name="Email" #Email="ngModel"
                    required email>
                  <div *ngIf="loginForm.submitted" [hidden]="Email.valid" class="alert alert-danger">
                    Enter valid email address
                  </div>
                </div>
      
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" class="form-control" id="Password" [(ngModel)]="model.Password" name="Password"
                    #Password="ngModel" required>
                  <div *ngIf="loginForm.submitted" [hidden]="Password.valid" class="alert alert-danger">
                    Password is required
                  </div>
                </div>
      
                <div class="custom-control custom-checkbox mb-3">
                  <!--<input type="checkbox" class="custom-control-input" id="customCheck1">
                  <label class="custom-control-label" for="customCheck1">Remember password</label>-->
                </div>
                <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase" type="submit">{{btnText}}</button>
                <hr />
                Do not have account? <a [routerLink]="['/user-registration']" class="text-info">Register now</a><br>
                Forgot password? <a [routerLink]="['/forgot-password']" class="text-info">Click here</a>
              </form>
            </div>
          </div>
        </div>
      </div>
</div>