<jw-modal id="project-description">
  <div class="row">
    <div class="col-md-12">
      <span class="float-right">
        <button *ngIf="isShowContact" class="btn btn-warning" (click)="contactProject(currentProjectId);">Request a change</button>&nbsp;
        <button class="btn btn-danger" (click)="closeModal('project-description');">Close</button>
      </span>
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title text-center">{{selectedProjectTitle}}</h5>
          <div>
            <table class="table">
              <tr>
                <td><b>Project value: </b>
                  <span *ngIf="selectedProjectValue != 0">
                    {{selectedProjectCurrency}} {{formatProjectValue(selectedProjectValue)}}
                  </span>
                  <span *ngIf="selectedProjectValue == 0">
                    N/a
                  </span>
                </td>
                <td><b>Start date: </b>{{formatDateUKStyle(selectedProjectStartDate)}}</td>
                <td><b>End date: </b>{{formatDateUKStyle(selectedProjectEndDate)}}</td>
              </tr>
            </table>
          </div>
          <div>
            <p>
              <b>Description:</b> {{selectedProjectDescription}}
            </p>
            <h6>Member organizations</h6>
            <table *ngIf="selectedProjectOrganizations.length > 0" class="table table-bordered">
              <tr *ngFor="let org of selectedProjectOrganizations">
                <td>{{org.name}}</td>
              </tr>
            </table>
            <span *ngIf="selectedProjectOrganizations.length == 0" class="text-info">
              <i>No organizations found</i>
            </span>

            <h6>Sectors</h6>
            <table *ngIf="selectedProjectSectors.length > 0" class="table table-bordered">
              <tr *ngFor="let sector of selectedProjectSectors">
                <td>{{sector.name}}</td>
              </tr>
            </table>
            <span *ngIf="selectedProjectSectors.length == 0" class="text-info">
              <i>No sectors found</i>
            </span>

            <h6>Locations</h6>
            <table *ngIf="selectedProjectLocations.length > 0" class="table table-bordered">
              <tr *ngFor="let loc of selectedProjectLocations">
                <td>{{loc.name}}</td>
              </tr>
            </table>
            <span *ngIf="selectedProjectLocations.length == 0" class="text-info">
              <i>No locations found</i>
            </span>
          </div>
        </div>
      </div>
      <span class="float-right">
        <button *ngIf="isShowContact" class="btn btn-warning" (click)="contactProject();">Request a change</button>&nbsp;
        <button class="btn btn-danger" (click)="closeModal('project-description');">Close</button>
      </span>
    </div>
  </div>
</jw-modal>

<div class="container-fluid view-area">
  <block-ui>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Find projects</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" [ngClass]="{disabledDiv: isTextReadOnly}">
        <div class="card">
          <div class="card-body">
            <form class="form-signin" #searchForm="ngForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="text" placeholder="{{inputTextHolder}}" class="form-control" id="title"
                      [(ngModel)]="model.title" (input)="filterProjectMatches()" name="title" #title onkeypress="return event.keyCode != 13;">
                    <span class="input-group-btn">
                      <button class="btn btn-outline-info btn-lg" type="button">
                        <i class="fa fa-search"></i>
                      </button>
                    </span>
                  </div>
                </div>
                <div class="col-md-6 small">
                  <table>
                    <tr>
                      <td>
                        <label>Active from:&nbsp;</label>
                      </td>
                      <td>
                        <input class="form-control" type="date" name="startDate" [(ngModel)]="model.startDate" #startDate="ngModel" (change)="filterProjectMatches()" />
                      </td>
                      <td>&nbsp;Active until:&nbsp;</td>
                      <td>
                        <input class="form-control" type="date" name="endDate" [(ngModel)]="model.endDate" #endDate="ngModel" (change)="filterProjectMatches()" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div *ngIf="!isAIMSLoading && !isIATILoading" class="row margin-top-10">
                <div class="col-lg-4 col-md-4">
                  <div class="margin-top-5">
                    <ng-multiselect-dropdown [placeholder]="'-- All IATI organization/s --'" 
                      [settings]="organizationsSettings"
                      [data]="iatiOrganizationsList"
                      [(ngModel)]="model.selectedIATIOrganizations" (onSelect)="onItemSelect()" (onDeSelect)="onItemDeSelect()"
                      (onSelectAll)="onItemSelectAll()" (onDeSelectAll)="onItemDeSelectAll()"
                      name="selectedIATIOrganizations" #selectedIATIOrganizations="ngModel">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="margin-top-5">
                    <select name="iati-sector-type" [(ngModel)]="model.iatiSectorType" (change)="getSectorsForIATIType()" #iatiSectorType="ngModel" class="form-control">
                      <option value="null">--IATI sector type--</option>
                      <option *ngFor="let type of iatiSectorTypesList" value="{{type.id}}">
                        {{type.typeName}}
                      </option>
                    </select>
                  </div>
                  <div [class.disable]="model.iatiSectorType == null || model.iatiSectorType == 'null'" class="margin-top-5">
                    <ng-multiselect-dropdown [placeholder]="'-- All IATI sector/s --'" 
                      [settings]="sectorsSettings"
                      [data]="iatiSectorsList"
                      [(ngModel)]="model.iatiSelectedSectors" (onSelect)="onItemSelect()" (onDeSelect)="onItemDeSelect()"
                      (onSelectAll)="onItemSelectAll()" (onDeSelectAll)="onItemDeSelectAll()"
                      name="iatiSelectedSectors" #selectedSectors="ngModel">
                    </ng-multiselect-dropdown>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="col">
                    <ng-multiselect-dropdown [placeholder]="'-- All AIMS organization/s --'" 
                      [settings]="organizationsSettings"
                      [data]="organizationsList"
                      [(ngModel)]="model.selectedOrganizations" (onSelect)="onItemSelect()" (onDeSelect)="onItemDeSelect()"
                      (onSelectAll)="onItemSelectAll()" (onDeSelectAll)="onItemDeSelectAll()"
                      name="selectedOrganizations" #selectedOrganizations="ngModel">
                    </ng-multiselect-dropdown>
                  </div>

                  <div class="col margin-top-5">
                    <select name="sectorLevel" [(ngModel)]="model.sectorLevel" (change)="manageSectorLevels()" #sectorLevel="ngModel" class="form-control">
                      <option value="null">--AIMS sector level--</option>
                      <option *ngFor="let level of sectorLevels" value="{{level.id}}">
                        {{level.level}}
                      </option>
                    </select>
                  </div>

                  <div [class.disable]="model.sectorLevel == null || model.sectorLevel == 'null'" class="col margin-top-5">
                    <ng-multiselect-dropdown [placeholder]="'-- All AIMS sector/s --'" 
                      [settings]="sectorsSettings"
                      [data]="sectorsList" [(ngModel)]="model.selectedSectors"
                      (onSelect)="onItemSelect()" (onDeSelect)="onItemDeSelect()" (onSelectAll)="onItemSelectAll()"
                      (onDeSelectAll)="onItemDeSelectAll()" name="selectedSectors"
                      #selectedSectors="ngModel">
                    </ng-multiselect-dropdown>
                  </div>

                </div>

                <div class="col-lg-4 col-md-4">
                  <div class="col margin-top-5">
                    <ng-multiselect-dropdown [placeholder]="'-- All location/s --'" 
                      [settings]="locationsSettings"
                      [data]="locationsList"
                      [(ngModel)]="model.selectedLocations" name="selectedLocations"
                      (onSelect)="onItemSelect()" (onDeSelect)="onItemDeSelect()" (onSelectAll)="onItemSelectAll()"
                      (onDeSelectAll)="onItemDeSelectAll()" #selectedLocations="ngModel">
                    </ng-multiselect-dropdown>
                  </div>

                  <!--<div class="col margin-top-5">
                      <select class="form-control" id="markerId" [(ngModel)]="model.markerId" name="markerId" (change)="changeMarkerForProjectFilters()"
                      title="Select a marker" #sectorId="ngModel">
                      <option value="0">--Select marker--</option>
                      <option *ngFor="let marker of markersList" [value]="marker.id">{{marker.fieldTitle}}
                      </option>
                    </select>
                  </div>
                  
                  <div class="col margin-top-5">
                    <ng-multiselect-dropdown [placeholder]="'-- All values --'" title="Marker value/s"
                    [settings]="markerValuesSettings"
                    [data]="markerValues" [(ngModel)]="model.selectedMarkerValues" 
                    (onSelect)="onMarkerValueSelect()" (onDeSelect)="onMarkerValueDeSelect()" (onSelectAll)="onMarkerValueSelectAll()"
                      (onDeSelectAll)="onMarkerValueDeSelectAll()"
                    name="selectedMarkerValues" #selectedMarkerValues="ngModel">
                  </ng-multiselect-dropdown>
                  </div>-->
                </div>
                
              </div>

              <div class="row margin-top-10">
                <div class="col-lg-4 col-md-4">
                  
                </div>
              </div>

              <div *ngIf="isAIMSLoading || isIATILoading">
                <span class="text-info">Setting up data...</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div id="nav-buttons" class="col-md-12 margin-top-10">
        <div class="float-right">
          <table>
            <tr>
              <td>
                <button *ngIf='countAIMSProjects() > 1 && countIATIProjects() == 0' [class.disable]='isSearchingProjects || !checkIfCanMerge()'
                  class="btn btn-warning btn-lg pull-right float-right text-uppercase" (click)="mergeProjects()">Merge
                  Projects</button>
              </td>
              <td>
                <button *ngIf="!isAIMSLoading && countAIMSProjects() == 1 && isProjectPermitted" [class.disable]='isSearchingProjects'
                  class="btn btn-success btn-lg float-right text-uppercase" (click)="editProject()">Edit
                  Project</button>
              </td>
              <td>
                <button *ngIf="selectedProjects.length == 0" class="btn btn-info btn-lg float-right text-uppercase"
                  (click)="proceedToDataEntry()">Manual entry only</button>

                <button *ngIf="selectedProjects.length > 0" [class.disable]='isSearchingProjects'
                  class="btn btn-info btn-lg float-right text-uppercase" (click)="proceedToDataEntry()">Proceed with
                  selected source projects</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="countAIMSProjects() > 1 && !checkIfCanMerge()" class="row">
      <div class="col-md-12">
        <div class="text-warning float-right">
          <b><i>
              You cannot merge, as there are projects selected for which you do not have permissions.
            </i></b>
        </div>
      </div>
    </div>
    <div class="row">
      <!--IATI Activity Box-->
      <div class="col-md-4 col-sm-6">
        <div class="card card-signin my-5 margin-top-5">
          <div class="card-body">
            <h5 class="card-title text-center">Matches from IATI</h5>
            <div>
              <div *ngIf="isIATILoading">
                Loading IATI projects...
              </div>
              <div *ngIf="!isIATILoading">
                <div *ngIf="filteredIatiProjects.length > 0" class="projects-list-box">
                  <table class="table table-bordered table-fixed-layout">
                    <tr
                      *ngFor="let project of filteredIatiProjects | paginate: { itemsPerPage: pagingSize, currentPage: d, id: 'd' }">
                      <td>
                        <div class="float-left">
                          <span class="overflow-wrap">
                            {{project.title | truncate : 30}}
                          </span>
                        </div>
                        <div class="float-right">
                          <button id="{{project.id}}" title="Show Project Description" class="btn btn-info btn-sm"
                            (click)="showProjectDescription($event)">View detail</button>
                          &nbsp;
                          <button [class.disable]="checkIfIATIEntered(project.title)" type="button" id="{{project.id}}"
                            class="btn btn-success btn-sm"
                            (click)="selectIATIProject($event)">{{checkIfIATIAdded(project.title)}}</button>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <pagination-controls (pageChange)="d = $event" id="d"></pagination-controls>
                </div>
  
                <span *ngIf="filteredIatiProjects.length == 0" class="text-info">
                  No matches from IATI.
                </span>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <!--Projects box from AIMS-->
      <div class="col-md-4 col-sm-6">
        <div class="card card-signin my-5 margin-top-5">
          <div class="card-body">
            <h5 class="card-title text-center">Matches from AIMS</h5>
              <div *ngIf="isAIMSLoading">
                Loading AIMS projects...
              </div>
              <div *ngIf="!isAIMSLoading">
                <div *ngIf="filteredAIMSProjects.length > 0" class="projects-list-box">
                  <table class="table table-bordered">
                    <tr
                      *ngFor="let project of filteredAIMSProjects | paginate: { itemsPerPage: pagingSize, currentPage: p, id: 'p' }">
                      <td>
                        <span class="float-left overflow-wrap">
                          {{project.title | truncate : 30}}
                        </span>
  
                        <span class="float-right margin-right-15">
                          <button type="button" id="vaims-{{project.id}}" class="btn btn-info btn-sm"
                            (click)="showAIMSProjectDescription($event)">View detail</button>
                          &nbsp;
                          <button
                            [class.disable]="checkAIMSAdded(project.title)"
                            type="button" id="saims-{{project.id}}" class="btn btn-success btn-sm"
                            (click)="selectAIMSProject($event)">Select</button>
                        </span>
                      </td>
                    </tr>
                  </table>
                  <pagination-controls (pageChange)="p = $event" id="p"></pagination-controls>
                </div>
  
                <span *ngIf="filteredAIMSProjects.length == 0" class="text-info">
                  No matches from AIMS
                </span>
              </div>
            
          </div>
        </div>
      </div>

      <!--Selected projects box-->
      <div class="col-md-4 col-sm-6">
        <div class="card card-signin my-5 margin-top-5">
          <div class="card-body">
            <h5 class="card-title text-center">Selected Projects</h5>
            <div>
              <table class="table table-bordered">
                <tr *ngFor="let project of selectedProjects">
                  <td>
                    <span class="float-left">
                      {{project.title}}<br />
                    </span>
                    <span class="float-right">
                      <button type="button" id="selected-{{project.id}}" class="btn btn-info btn-sm"
                        (click)="showSelectedProjectDescription($event)">Description</button>
                      &nbsp;
                      <button type="button" id="{{project.pid}}" class="btn btn-success btn-sm"
                        (click)="removeSelectedProject($event)">De-Select</button>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--End of Boxes row-->

  </block-ui>
</div>

<join-project-modal [projectId]="joinProjectId"></join-project-modal>

<error-modal message={{errorMessage}}></error-modal>

<jw-modal id="confirmation-modal">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg-info text-white">Confirmation Message</div>
        <div class="card-body text-info">
          You have not selected any of the projects. Are you sure you want to proceed?
        </div>
      </div><br />
      <button class="btn btn-info" (click)="proceedToDataEntry();">Yes Proceed</button>
      &nbsp;
      <button class="btn btn-danger" (click)="closeConfirmationModal();">No</button>
    </div>
  </div>
</jw-modal>

<info-modal message={{successMessage}}></info-modal>

<project-info-modal [project]="viewProject" [locations]="viewProjectLocations" [sectors]="viewProjectSectors"
  [funders]="viewProjectFunders" [implementers]="viewProjectImplementers" [documents]="viewProjectDocuments"
  [markers]="viewProjectFields" [isShowContact]="isShowContact" [projectId]="viewProjectId">
</project-info-modal>