<div class="container-fluid view-area">
  <div class="row">
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/sector-types']">Sector types list</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active btn-info" data-toggle="tab">{{btnText}}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
    <div class="card card-signin my-5">
      <div class="card-body">
        <div *ngIf="isError" class="alert alert-danger alert-dismissible">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          <strong>Error!</strong> {{errorMessage}}
        </div>
        <h5 class="card-title text-center">Sector type</h5>

        <form class="form-signin" (ngSubmit)="saveSectorType()" #sectorTypeForm="ngForm">
          <div class="form-group">
            <label for="text">Sector-Type name</label>
            <input type="text" class="form-control" id="typeName" [(ngModel)]="model.typeName" name="typeName"
              #typeName="ngModel" required>
            <div [hidden]="typeName.valid" class="alert alert-danger">
              Sector-Type name is required
            </div>
          </div>

          <div class="form-group">
            <label for="text">IATI source URL</label>
            <input type="url" class="form-control" id="sourceUrl" [(ngModel)]="model.sourceUrl" name="sourceUrl"
              #sourceUrl="ngModel">
            <div [hidden]="sourceUrl.valid" class="alert alert-danger">
              Enter valid url for IATI source
            </div>
          </div>

          <div *ngIf="(!isForEdit || model.isPrimary)" class="form-group">
            <div class="row">
              <div class="col-offset-2 col-md-9">
                <div [class.disable]="true" class="btn-group" data-toggle="buttons">
                  <button (click)="toggleDefault()" class="btn btn-success btn-sm">
                    <i *ngIf="!model.isPrimary" class="fa fa-square text-white"></i>
                    <i *ngIf="model.isPrimary" class="fa fa-check text-white"></i>
                  </button>
                  &nbsp;Is primary sector type?
                </div>
              </div>
            </div>
          </div>

          <!--<div class="form-group">
            <div class="row">
              <div class="col-offset-2 col-md-9">
                <div class="btn-group" data-toggle="buttons">
                  <button (click)="toggleSourceType()" class="btn btn-success btn-sm">
                    <i *ngIf="!model.isSourceType" class="fa fa-square text-white"></i>
                    <i *ngIf="model.isSourceType" class="fa fa-check text-white"></i>
                  </button>
                  &nbsp;Is source?
                </div>
              </div>
            </div>
          </div>-->

          <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase"
            type="submit">{{btnText}}</button>
        </form>
      </div>
    </div>
  </div>

</div>