<div class="container-fluid view-area-shrinked">

  <block-ui>
    <div class="container-fluid position-fixed stay-on-top background-white padding-top-10" >
      <div class="row">
        <div class="col-md-12 ">
          <ul class="nav nav-tabs">
  
            <li class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'project', 'text-info': currentTab != 'project'}" href="#"
                class="nav-link text-info" (click)="showProjects()" data-toggle="tab">Name/Description</a>
            </li>
            <li *ngIf="activeProjectId != 0" class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'funder', 'text-info': currentTab != 'funder'}" href="#"
                class="nav-link text-info" (click)="showFunders()" data-toggle="tab">Funders/Financing</a>
            </li>
            <li *ngIf="activeProjectId != 0" class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'implementer', 'text-info': currentTab != 'implementer'}" href="#"
                class="nav-link text-info" (click)="showImplementers()" data-toggle="tab">Implementers</a>
            </li>
            <li *ngIf="activeProjectId != 0" class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'sector', 'text-info': currentTab != 'sector'}" href="#"
                class="nav-link text-info" (click)="showSectors()" data-toggle="tab">Sectors</a>
            </li>
            <li *ngIf="activeProjectId != 0" class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'location', 'text-info': currentTab != 'location'}" href="#"
                class="nav-link text-info" (click)="showLocations()" data-toggle="tab">Locations</a>
            </li>
            <li *ngIf="activeProjectId != 0" class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'document', 'text-info': currentTab != 'document'}" href="#"
                class="nav-link text-info" (click)="showDocuments()" data-toggle="tab">Documents</a>
            </li>
            <li *ngIf="activeProjectId != 0" class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'disbursement', 'text-info': currentTab != 'disbursement'}"
                href="#" class="nav-link text-info" (click)="showDisbursements()" data-toggle="tab">Disbursements</a>
            </li>
            <li *ngIf="activeProjectId != 0" class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'customFields', 'text-info': currentTab != 'customFields'}"
                href="#" class="nav-link text-info" (click)="showCustomFields()" data-toggle="tab">Custom fields</a>
            </li>
            <li *ngIf="activeProjectId != 0" class="nav-item">
              <a [ngClass]="{'btn-info': currentTab == 'finish', 'text-info': currentTab != 'finish'}" href="#"
                class="nav-link text-info" (click)="showFinishProject()" data-toggle="tab">Finish Project</a>
            </li>
  
          </ul>
        </div>
      </div>
      
    </div>

    <div class="row text-center margin-top-50">
      <div *ngIf="showMessage" class="alert alert-info alert-dismissible">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <strong>Information!</strong> {{infoMessage}}
      </div>
    </div>

    <!--Data entry tab for projects-->
    <div *ngIf="currentTab == 'project'" class="row margin-top-50">

      <div *ngIf="isShowSources" class="col-md-6">
        <div class="col-md-12 col-sm-12">
          <div class="card card-signin my-5 margin-top-10">
            <div class="card-body">
              <h6 class="card-title text-center">Source projects</h6>

              <div class="iati-box">
                <table class="table table-bordered">
                  <tr *ngIf="isAimsLoading">
                    <span class="text-info">Wait loading aims data...</span>
                  </tr>
                  <tr *ngFor="let project of aimsProjects">
                    <td>
                      <span class="float-left">
                        <b>Project title: </b>{{project.title | truncate}} &nbsp;<button id="aimst-{{project.id}}"
                          class="btn btn-info btn-sm" (click)="enterProjectTitleAIMS($event)">Title
                          >></button>&nbsp;<a href="#" title="View Project Details" id="aproject-{{project.id}}"
                          (click)="viewAIMSProject($event)" class="text-info">View
                          Details</a><br />
  
                        <div class="margin-top-5">
                          <b>Start date: </b>{{project.startDate}}&nbsp;<button id="btnsdate-{{project.id}}"
                            (click)="enterStartDate($event)" class="btn btn-sm btn-success">>></button><br />
                        </div>
                        <div class="margin-top-5">
                          <b>End date: </b>{{project.endDate}}&nbsp;<button id="btnedate-{{project.id}}"
                            (click)="enterEndDate($event)" class="btn btn-sm btn-success">>></button><br />
                        </div>
                        <span class="badge badge-warning">AIMS</span>
                      </span>
                      <span class="float-right">
                        <button id="aimsd-{{project.id}}" class="btn btn-warning btn-sm"
                          (click)="enterProjectDescAIMS($event)">Description
                          >></button>
                      </span>
                    </td>
                  </tr>
  
                  <tr *ngIf="isIatiLoading">
                    <span class="text-info">Wait loading iati data...</span>
                  </tr>
                  <tr *ngFor="let project of iatiProjects">
                    <td>
                      <span class="float-left">
                        <b>Project title: </b>{{project.title | truncate}} &nbsp;<button id="iatit-{{project.id}}"
                          class="btn btn-info btn-sm" (click)="enterProjectTitleIATI($event)">Title
                          >></button>&nbsp;<a href="#" title="View Project Details" id="iproject-{{project.id}}"
                          (click)="viewIATIProject($event)" class="text-info">View
                          Details</a><br />
                        <div class="margin-top-5">
                          <b>Start date: </b>{{project.startDate}}&nbsp;<button *ngIf="project.startDate"
                            id="btnisdate-{{project.id}}" (click)="enterStartDateIATI($event)"
                            class="btn btn-sm btn-success">>></button><br />
                        </div>
                        <div class="margin-top-5">
                          <b>End date: </b>{{project.endDate}}&nbsp;<button *ngIf="project.endDate"
                            id="btniedate-{{project.id}}" (click)="enterEndDateIATI($event)"
                            class="btn btn-sm btn-success">>></button><br />
                        </div>
                        <span class="badge badge-warning">IATI</span>
                      </span>
                      <span class="float-right">
  
                        <button id="iatid-{{project.id}}" class="btn btn-warning btn-sm"
                          (click)="enterProjectDescIATI($event)">Description
                          >></button>
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div [ngClass]="{'col-md-6': isShowSources, 'col-md-12': !isShowSources}">
        <!--Data entry box-->
        <div class="col-md-12 col-sm-12">
          <div class="card card-signin my-5 margin-top-10">
            <div class="card-body">
              <form class="form-signin" (ngSubmit)="projectForm.form.valid && saveProject(projectForm)" #projectForm="ngForm">
                <div class="row">
                  <div class="col-md-12">
                    <span class="float-left">
                      <h6 class="card-title">New project</h6>
                    </span>
                    <span class="float-right">
                      <button class="btn btn-info" [class.disable]="isProjectBtnDisabled"
                        class="btn btn-info btn-block text-uppercase" type="submit">Next >></button>
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="title">Project title</label>
                  <input type="text" class="form-control" id="title" maxlength="1000" [(ngModel)]="model.title"
                    name="title" #title="ngModel" required>
                  <div *ngIf="projectForm.submitted" [hidden]="title.valid" class="alert alert-danger">
                    Project title is required
                  </div>
                </div>
                <div class="form-group">
                  <label for="title">Start date/month</label>
                  <div class="input-group">
                    <input type="date" class="form-control" placeholder="yyyy-mm-dd" name="startDate"
                      [(ngModel)]="model.startDate" #startDate="ngModel" required>
                  </div>
                  <div *ngIf="projectForm.submitted" [hidden]="startDate.valid" class="alert alert-danger">
                    Start date is required
                  </div>
                </div>

                <div class="form-group">
                  <label for="title">End date</label>
                  <div class="input-group">
                    <input type="date" class="form-control" placeholder="yyyy-mm-dd" name="endDate"
                      [(ngModel)]="model.endDate" #endDate="ngModel" required>
                  </div>
                  <div *ngIf="projectForm.submitted" [hidden]="endDate.valid" class="alert alert-danger">
                    End date is required
                  </div>
                </div>

                <div class="form-group">
                  <label for="title">Description <span class="text-info">(Max characters: {{descriptionLimit}} Left: {{descriptionLimitLeft}})</span></label>
                  <textarea class="form-control" id="description" (input)="getDescriptionLimitInfo()" [(ngModel)]="model.description" name="description"
                    #description="ngModel" rows="7" maxlength="{{descriptionLimit}}">
                      </textarea>
                </div>
                <!--<button [class.disable]="isProjectBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase"
                  type="submit">{{btnProjectText}}</button>-->
              </form>

            </div>
          </div>
        </div>

      </div>

    </div>
    <!--End of data entry tab-->

    <!--Sectors tab for projects-->
    <div *ngIf="currentTab == 'sector'" class="row margin-top-50">

      <div *ngIf="isShowSources" class="col-md-6 col-sm-6">

        <div class="col-md-12">
          <div class="card card-signin my-5 margin-top-10">
            <div class="card-body">
              <h6 class="card-title text-center">Source projects</h6>
              <div class="iati-box">
                <table class="table table-bordered iati-box">
                  <tr *ngFor="let project of aimsProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="aproject-{{project.id}}" (click)="viewAIMSProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">AIMS</span>
                      <table *ngIf="project.sectors.length > 0" class="table table-bordered">
                        <tr>
                          <th>Sector</th>
                          <th>Funds %</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let sector of project.sectors">
                          <td>
                            {{sector.sector}}
                          </td>
                          <td>
                            {{sector.fundsPercentage}}
                          </td>
                          <td [class.disable]="checkIfSectorAdded(sector.sector)">
                            <button id="asector-{{project.id}}-{{sector.sectorId}}" (click)="enterAIMSSector($event)"
                              class="btn btn-success btn-sm">Enter >></button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.sectors.length == 0"><i>No sectors available</i></div>
                    </td>
                  </tr>
                </table>
  
                <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
                  <tr *ngFor="let project of iatiProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="iproject-{{project.id}}" (click)="viewIATIProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">IATI</span>
                      <table *ngIf="project.sectors.length > 0" class="table table-bordered">
                        <tr>
                          <th>Sector</th>
                          <th>Funds %</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let sector of project.sectors">
                          <td>
                            {{sector.sectorName}}
                          </td>
                          <td>
                            {{sector.fundsPercentage}}
                          </td>
                          <td [class.disable]="checkIfSectorAdded(sector.sectorName)">
                            <button id="isector-{{project.id}}-{{sector.code}}" (click)="enterIATISector($event)"
                              class="btn btn-success btn-sm">Enter >></button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.sectors.length == 0"><i>No sectors available</i></div>
                    </td>
                  </tr>
                </table>
  
              </div>
              
            </div>
          </div>
        </div>

      </div>

      <!--Selected Sectors List-->
      <div [ngClass]="{'col-md-6': isShowSources, 'col-md-12': !isShowSources}">

        <div class="card card-signin my-5 margin-top-10 margin-bottom-10">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <span class="float-left">
                  <h6 class="card-title">Manage sectors</h6>
                </span>
                <span class="float-right">
                  <button class="btn btn-info" class="btn btn-info btn-block text-uppercase" (click)="showLocations()"
                    type="button">Next >></button>
                </span>
              </div>
            </div>

            <form class="form-signin" (ngSubmit)="projectSectorForm.form.valid && saveProjectSector(projectSectorForm)"
              #projectSectorForm="ngForm">

              <div class="form-group">
                <label for="sectorTypeId">Sector type</label>
                <select [class.disable]="isSectorTypeDisabled" class="form-control" id="sectorTypeId"
                  [(ngModel)]="sectorModel.sectorTypeId" name="sectorTypeId" #sectorTypeId="ngModel"
                  (change)="showSectorsForType()" required>
                  <option value="null">--Select sector type--</option>
                  <option *ngFor="let sType of sectorTypes" [value]="sType.id">{{sType.typeName}}</option>
                </select>
                <div *ngIf="projectSectorForm.submitted" [hidden]="sectorTypeId.valid" class="alert alert-danger">
                  Sector type is required
                </div>
              </div>

              <div [class.disable]="sectorModel.sectorTypeId == null" *ngIf="sectorEntryType == 'aims'" class="form-group">
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="Enter/Select sector" aria-label="Sector" [matAutocomplete]="auto"
                  name="sector" #sector="ngModel" [(ngModel)]="sectorModel.sectorObj" (ngModelChange)="filterSector()">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySectorFn.bind(this)">
                    <mat-option *ngFor="let sector of filteredSectors" [value]="sector">
                      <span>{{sector.sectorName}}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div *ngIf="sectorEntryType == 'iati'" class="form-group">
                <label for="sectorName">New sector</label>
                <input type="text" [readonly]="true" class="form-control" id="sectorName"
                  [(ngModel)]="sectorModel.sectorName" name="sectorName" #sectorName="ngModel" required>
                <div *ngIf="projectSectorForm.submitted" [hidden]="sectorName.valid" class="alert alert-danger">
                  Sector name is required
                </div>
              </div>

              <div *ngIf="(selectedSectorId || sectorModel.sectorName) && sectorModel.sectorTypeId != primarySectorTypeId"
                class="form-group">
                <label for="sectorName">{{defaultSectorType}}</label>
                <select class="form-control" id="mappingId" [(ngModel)]="sectorModel.mappingId" name="mappingId"
                  #mappingId="ngModel" required>
                  <option *ngFor="let sector of sectorMappings" [value]="sector.id">{{sector.sectorName}}</option>
                </select>
                <div class="margin-top-5" *ngIf="mappingsCount > 0">
                  <span *ngIf="showMappingManual" class="float-left text-success">
                    {{mappingsCount}} mapping/s found
                  </span>
                  <span *ngIf="showMappingAuto" class="float-left text-success">
                    Map manually
                  </span>
                  &nbsp;
                  <span *ngIf="showMappingManual" class="float-right">
                    <button type="button" class="btn btn-info btn-sm" (click)="setManualMappings()">Map manual</button>
                  </span>
                  <span *ngIf="showMappingAuto" class="float-right">
                    <button type="button" class="btn btn-warning btn-sm" (click)="setAutomaticMappings()">Map
                      auto</button>
                  </span>
                </div>
                <div *ngIf="mappingsCount == 0" class="text-warning"><i>No mappings found, map sector manually</i></div>
                <div *ngIf="projectSectorForm.submitted" [hidden]="mappingId.valid" class="alert alert-danger">
                  Mapping for sector is required
                </div>
              </div>

              <div class="form-group">
                <label for="fundsPercentage">Funds percentage</label>
                <input type="number" class="form-control" id="fundsPercentage" [(ngModel)]="sectorModel.fundsPercentage"
                  name="fundsPercentage" #fundsPercentage="ngModel" min="1" max="100" required>
                <div *ngIf="projectSectorForm.submitted" [hidden]="fundsPercentage.value > 0"
                  class="alert alert-danger">
                  Valid values for funds percentage are 1 - 100
                </div>
              </div>

              <button [class.disable]="isProjectSectorBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase"
                type="submit">{{btnProjectSectorText}}</button>

            </form>

          </div>
        </div>

        <div class="card card-signin">
          <div class="card-body iati-box">
            <h6 class="card-title text-center">Project sectors</h6>
            <table class="table table-bordered">
              <tr>
                <th>Sector</th>
                <th>Fund %</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let sector of currentProjectSectorsList">
                <td>{{sector.sector}}</td>
                <td>{{sector.fundsPercentage}}</td>
                <td>
                  <button id="dsec-{{activeProjectId}}-{{sector.sectorId}}" (click)="deleteProjectSector($event)"
                    class="btn btn-danger btn-sm">
                    <i id="idsec-{{activeProjectId}}-{{sector.sectorId}}" class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <span *ngIf="(sectorTotalPercentage > 100 || sectorTotalPercentage < 0)" class="float-left">
                    <i class="text-danger">Invalid percentage value.</i>
                  </span>
                  <span class="float-right">
                    <b>Total percentage: {{sectorTotalPercentage}} %</b>
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>

    </div>
    <!--End of data entry tab-->
    <!--End of Sectors Tab-->

    <!--Locations tab for projects-->
    <div *ngIf="currentTab == 'location'" class="row margin-top-50">

      <div *ngIf="isShowSources" class="col-md-6 col-sm-6">

        <div class="col-md-12">
          <div class="card card-signin my-5 margin-top-10">
            <div class="card-body">
              <h6 class="card-title text-center">Source projects</h6>

              <div class="iati-box">
                <table class="table table-bordered">
                  <tr *ngFor="let project of aimsProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="aproject-{{project.id}}" (click)="viewAIMSProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">AIMS</span>
                      <table *ngIf="project.locations.length > 0" class="table table-bordered">
                        <tr>
                          <th>Location</th>
                          <th>Funds %</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let location of project.locations">
                          <td>
                            {{location.location}}
                          </td>
                          <td>
                            {{location.fundsPercentage}}
                          </td>
                          <td [class.disable]="checkIfLocationAdded(location.location)">
                            <button id="alocation-{{project.id}}-{{location.id}}" (click)="enterAIMSLocation($event)"
                              class="btn btn-success btn-sm">Enter >></button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.locations.length == 0"><i>No locations available</i></div>
                    </td>
                  </tr>
                </table>
  
                <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
                  <tr *ngFor="let project of iatiProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="iproject-{{project.id}}" (click)="viewIATIProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">IATI</span>
                      <table *ngIf="project.locations.length > 0" class="table table-bordered">
                        <tr>
                          <th>Location</th>
                        </tr>
                        <tr *ngFor="let location of project.locations">
                          <td>
                            {{location.name}}
                          </td>
                          <!--<td [class.disable]="checkIfLocationAdded(location.name)">
                            <button id="ilocation-{{project.id}}-{{location.name}}" (click)="enterIATILocation($event)"
                              class="btn btn-success btn-sm">Enter >></button>
                          </td>-->
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.locations.length == 0"><i>No locations available</i></div>
                    </td>
                  </tr>
                </table>
              </div>
              
            </div>
          </div>
        </div>

      </div>

      <!--Selected Locations List-->
      <div [ngClass]="{'col-md-6': isShowSources, 'col-md-12': !isShowSources}">

        <div class="card card-signin my-5 margin-top-10 margin-bottom-10">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <span class="float-left">
                  <h6 class="card-title">Manage locations</h6>
                </span>
                <span class="float-right">
                  <button class="btn btn-info" class="btn btn-info btn-block text-uppercase" (click)="showDocuments()"
                    type="button">Next >></button>
                </span>
              </div>
            </div>

            <form class="form-signin"
              (ngSubmit)="projectLocationForm.form.valid && saveProjectLocation(projectLocationForm)"
              #projectLocationForm="ngForm">

              <div *ngIf="locationEntryType == 'aims'" class="form-group">
                <label for="locationId">AIMS Locations</label>
                <select class="form-control" id="locationId" [(ngModel)]="locationModel.locationId" name="locationId"
                  #locationId="ngModel" required>
                  <option *ngFor="let location of locationsList" [value]="location.id">{{location.location}}</option>
                </select>
                <div *ngIf="projectLocationForm.submitted" [hidden]="locationId.valid" class="alert alert-danger">
                  Location is required
                </div>
              </div>

              <div *ngIf="locationEntryType == 'iati'" class="form-group">
                <label for="location">New location</label>
                <input type="text" [readonly]="true" class="form-control" id="location"
                  [(ngModel)]="locationModel.location" name="location" #location="ngModel" required>
                <div *ngIf="projectLocationForm.submitted" [hidden]="location.valid" class="alert alert-danger">
                  Location is required
                </div>
              </div>

              <div class="form-group">
                <label for="fundsPercentage">Funds percentage</label>
                <input type="number" class="form-control" id="fundsPercentage"
                  [(ngModel)]="locationModel.fundsPercentage" name="fundsPercentage" #fundsPercentage="ngModel" min="1"
                  max="100" required>
                <div *ngIf="projectLocationForm.submitted" [hidden]="fundsPercentage.value > 0"
                  class="alert alert-danger">
                  Valid values for funds percentage are 1 - 100
                </div>
              </div>

              <button [class.disable]="isProjectLocationBtnDisabled"
                class="btn btn-lg btn-info btn-block text-uppercase" type="submit">{{btnProjectLocationText}}</button>

              <!--<button [class.disable]="isProjectLocationBtnDisabled"
                class="btn btn-lg btn-warning btn-block text-uppercase" type="button"
                (click)="resetLocationEntry()">Reset Location</button>-->
            </form>

          </div>
        </div>

        <div class="card card-signin">
          <div class="card-body iati-box">
            <h6 class="card-title text-center">Project locations</h6>
            <table class="table table-bordered">
              <tr>
                <th>Location</th>
                <th>Fund %</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let location of currentProjectLocationsList">
                <td>{{location.location}}</td>
                <td>{{location.fundsPercentage}}</td>
                <td>
                  <button id="dloc-{{activeProjectId}}-{{location.id}}" (click)="deleteProjectLocation($event)"
                    class="btn btn-danger btn-sm">
                    <i id="idloc-{{activeProjectId}}-{{location.id}}" class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <span *ngIf="(locationTotalPercentage > 100 || locationTotalPercentage < 0)" class="float-left">
                    <i class="text-danger">Invalid percentage value.</i>
                  </span>
                  <span class="float-right">
                    <b>Total percentage: {{locationTotalPercentage}} %</b>
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

    </div>
    <!--End of data entry tab-->
    <!--End of Locations Tab-->

    <!--Documents tab for projects-->
    <div *ngIf="currentTab == 'document'" class="row margin-top-50">

      <div *ngIf="isShowSources" class="col-md-6 col-sm-6">
        <div class="col-md-12">
          <div class="card card-signin my-5 margin-top-10">
            <div class="card-body">
              <h6 class="card-title text-center">Source projects</h6>

              <div class="iati-box">
                <table class="table table-bordered">
                  <tr *ngFor="let project of aimsProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="aproject-{{project.id}}" (click)="viewAIMSProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">AIMS</span>
                      <table *ngIf="project.documents.length > 0" class="table table-bordered">
                        <tr>
                          <th>Document title</th>
                          <th>Url</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let document of project.documents">
                          <td>
                            {{document.documentTitle}}
                          </td>
                          <td>
                            {{document.documentUrl}}
                          </td>
                          <td>
                            <button id="aDocument-{{project.id}}-{{document.id}}" (click)="enterAIMSDocument($event)"
                              class="btn btn-success btn-sm">Enter</button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.documents.length == 0"><i>No documents available</i></div>
                    </td>
                  </tr>
                </table>
  
                <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
                  <tr *ngFor="let project of iatiProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="iproject-{{project.id}}" (click)="viewIATIProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">IATI</span>
                      <table *ngIf="project.documents.length > 0" class="table table-bordered">
                        <tr>
                          <th>Document title</th>
                          <th>Document url</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let document of project.documents">
                          <td>
                            {{document.documentTitle}}
                          </td>
                          <td class="dont-break-out">
                            {{document.documentUrl}}
                          </td>
                          <td>
                            <button id="ddocument-{{project.id}}-{{document.id}}" (click)="enterIATIDocument($event)"
                              class="btn btn-success btn-sm">Enter</button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.documents.length == 0"><i>No documents available</i></div>
                    </td>
                  </tr>
                </table>
              </div>
              
            </div>
          </div>
        </div>

      </div>

      <!--Selected Documents List-->
      <div [ngClass]="{'col-md-6': isShowSources, 'col-md-12': !isShowSources}">

        <div class="card card-signin my-5 margin-top-10 margin-bottom-10">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <span class="float-left">
                  <h6 class="card-title">Manage documents & links</h6>
                </span>
                <span class="float-right">
                  <button class="btn btn-info" class="btn btn-info btn-block text-uppercase"
                    (click)="showDisbursements()" type="button">Next >></button>
                </span>
              </div>
            </div>
            <form class="form-signin" name="dForm"
              (ngSubmit)="projectDocumentForm.form.valid && saveProjectDocument(projectDocumentForm)"
              #projectDocumentForm="ngForm">

              <div class="form-group">
                <label for="documentTitle">Document title</label>
                <input type="text" class="form-control" id="documentTitle" [(ngModel)]="documentModel.documentTitle"
                  name="documentTitle" #documentTitle="ngModel" required>
                <div *ngIf="projectDocumentForm.submitted" [hidden]="documentTitle.valid" class="alert alert-danger">
                  Document title is required
                </div>
              </div>

              <div class="form-group">
                <label for="documentUrl">Document url</label>
                <input type="url" class="form-control" id="documentUrl" [(ngModel)]="documentModel.documentUrl"
                  name="documentUrl" #documentUrl="ngModel" required>
                <div *ngIf="projectDocumentForm.submitted" [hidden]="documentUrl.valid" class="alert alert-danger">
                  Document url is required
                </div>
              </div>

              <button [class.disable]="isProjectDocumentBtnDisabled"
                class="btn btn-lg btn-info btn-block text-uppercase" type="submit">Add document</button>
              <!--<button [class.disable]="isProjectDocumentBtnDisabled"
                class="btn btn-lg btn-warning btn-block text-uppercase" type="submit"
                (click)="resetDocumentEntry()">Reset document</button>-->
            </form>

          </div>
        </div>

        <div class="card card-signin">
          <div class="card-body iati-box">
            <h6 class="card-title text-center">Project documents and links</h6>
            <table class="table table-bordered">
              <tr>
                <th>Document title</th>
                <th>Url</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let document of currentProjectDocumentsList">
                <td>{{document.documentTitle}}</td>
                <td>{{document.documentUrl}}</td>
                <td>
                  <button id="ddocument-{{activeProjectId}}-{{document.id}}" (click)="deleteProjectDocument($event)"
                    class="btn btn-danger btn-sm">
                    <i id="idocument-{{activeProjectId}}-{{document.id}}" class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>

    </div>
    <!--End of data entry tab-->
    <!--End of Documents Tab-->

    <!--Funders tab for projects-->
    <div *ngIf="currentTab == 'funder'" class="row margin-top-50">

      <div *ngIf="isShowSources" class="col-md-6 col-sm-6">
        <div class="col-md-12">
          <div class="card card-signin my-5 margin-top-10">
            <div class="card-body">
              <h6 class="card-title text-center">Source projects</h6>

              <div class="iati-box">
                <table class="table table-bordered">
                  <tr *ngFor="let project of aimsProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="aproject-{{project.id}}" (click)="viewAIMSProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">AIMS</span>
  
                      <table *ngIf="project.funders.length > 0" class="table table-bordered">
                        <tr>
                          <th>Funder name</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let funder of project.funders">
                          <td>
                            {{funder.funder}}
                          </td>
                          <td [class.disable]="checkIfFunderAdded(funder.funder)">
                            <button id="aFunder-{{project.id}}-{{funder.funderId}}" (click)="enterAIMSFunder($event)"
                              class="btn btn-success btn-sm">Enter >></button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.funders.length == 0"><i>No funders from AIMS found.</i></div>
                    </td>
                  </tr>
                </table>
  
                <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
                  <tr *ngFor="let project of iatiProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="iproject-{{project.id}}" (click)="viewIATIProject($event)" class="text-info">View
                        Details</a><br />
  
                      <div class="badge badge-warning float-right">IATI</div>
                      <div class="btn-group">
                        <button (click)="showFunderTab(project.id)"
                          [ngClass]="{'active': checkIfFunderTabSelected(project.id)}" type="button"
                          class="btn btn-warning">Funders</button>
                        <button (click)="showBudgetTab(project.id)"
                          [ngClass]="{'active': checkIfBudgetTabSelected(project.id)}" type="button"
                          class="btn btn-warning">Budget</button>
                        <button (click)="showTransactionTab(project.id)"
                          [ngClass]="{'active': checkIfTransactionTabSelected(project.id)}" type="button"
                          class="btn btn-warning">Transactions</button>
                      </div>
  
                      <div *ngIf="project.isFunderVisible" class="margin-top-10">
                        <table *ngIf="project.funders.length > 0" class="table table-bordered margin-top-5">
                          <tr>
                            <th>Funder name</th>
                            <th>Action</th>
                          </tr>
                          <tr *ngFor="let funder of project.funders">
                            <td>
                              {{funder.name}}
                            </td>
                            <td [class.disable]="checkIfFunderAdded(funder.name)">
                              <button id="dfunder-{{project.id}}-{{funder.id}}" (click)="enterIATIFunder($event)"
                                class="btn btn-success btn-sm">Enter >></button>
                            </td>
                          </tr>
                        </table>
                        <div class="text-info" *ngIf="project.funders.length == 0">
                          <i>No funders to show.</i>
                        </div>
                      </div>
  
                      <div *ngIf="project.isBudgetVisible" class="margin-top-10">
                        <table *ngIf="project.budgets.length > 0" class="table table-bordered margin-top-5">
                          <tr>
                            <th>Amount</th>
                            <th>Currency</th>
                            <th>Dated</th>
                            <th>Action</th>
                          </tr>
                          <tr *ngFor="let budget of project.budgets">
                            <td [ngClass]="{'bg-warning': !budget.amount}">
                              {{displayNull(budget.amount)}}
                            </td>
                            <td [ngClass]="{'bg-warning': !budget.currency}">
                              {{displayNull(budget.currency)}}
                            </td>
                            <td [ngClass]="{'bg-warning': !budget.endDate}">
                              {{displayNull(budget.endDate)}}
                            </td>
                            <td>
                              <button id="dbudget-{{project.id}}-{{budget.id}}" (click)="enterIATIBudget($event)"
                                class="btn btn-success btn-sm">Enter >></button>
                            </td>
                          </tr>
                        </table>
                        <div class="text-info" *ngIf="project.budgets.length == 0">
                          <i>No budgets to show.</i>
                        </div>
                      </div>
  
                      <div *ngIf="project.isTransactionVisible" class="margin-top-10">
                        <table *ngIf="project.fundingTransactions.length > 0" class="table table-bordered margin-top-5">
                          <tr>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Dated</th>
                            <th>Action</th>
                          </tr>
                          <tr *ngFor="let transaction of project.fundingTransactions">
                            <td [ngClass]="{'bg-warning': !transaction.amount}">
                              {{displayNull(transaction.amount)}}
                            </td>
                            <td>
                              {{transaction.transactionType}}
                            </td>
                            <td [ngClass]="{'bg-warning': !transaction.dated}">
                              {{displayNull(transaction.dated)}}
                            </td>
                            <td>
                              <button id="dtransaction-{{project.id}}-{{transaction.id}}"
                                (click)="enterIATIFundingTransaction($event)" class="btn btn-success btn-sm">Enter
                                >></button>
                            </td>
                          </tr>
                        </table>
                        <div class="text-info" *ngIf="project.fundingTransactions.length == 0">
                          <i>No transactions to show.</i>
                        </div>
                      </div>
  
                    </td>
                  </tr>
                </table>
              </div>
              
            </div>
          </div>
        </div>

      </div>

      <!--Selected Funders List-->
      <div [ngClass]="{'col-md-6': isShowSources, 'col-md-12': !isShowSources}">

        <div class="card card-signin my-5 margin-top-10 margin-bottom-10">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <span class="float-left">
                  <h6 class="card-title">Manage funders</h6>
                </span>
                <span class="float-right">
                  <button class="btn btn-info" class="btn btn-info btn-block text-uppercase"
                    (click)="showImplementers()" type="button">Next >></button>
                </span>
              </div>
            </div>

            <div *ngIf="funderEntryType == 'aims'" class="row">
              <form class="example-form" [formGroup]='funderForm'>
                <div class="form-group">
                  <mat-form-field class="example-full-width margin-left-15">
                    <input matInput placeholder="Enter/Select Funder" aria-label="Funder" [matAutocomplete]="auto"
                      [formControl]="funderInput">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFunder.bind(this)">
                      <mat-option *ngFor="let org of filteredOrganizations | async" [value]="org">
                        <span>{{org.organizationName}}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </form>
            </div>
            <form class="form-signin" (ngSubmit)="projectFunderForm.form.valid && saveProjectFunder(projectFunderForm)"
              #projectFunderForm="ngForm">

              <div *ngIf="funderEntryType == 'iati'" class="form-group">
                <label for="funder">New funder</label>
                <input type="text" maxlength="200" class="form-control" id="funder" [(ngModel)]="funderModel.funder"
                  name="funder" readonly="readonly" #funder="ngModel" required>
                <div *ngIf="projectFunderForm.submitted" [hidden]="funder.valid" class="alert alert-danger">
                  Funder Name is required
                </div>
              </div>

              <div class="form-group">
                <label for="title">Funding date </label>
                <div class="input-group">
                  <input class="form-control" [readonly]="true" placeholder="yyyy-mm-dd" type="text" name="dated"
                    [(ngModel)]="funderModel.dated" [maxDate]="calendarMaxDate" (dateSelect)="getExchangeRates(1)"
                    ngbDatepicker #s="ngbDatepicker" required>
                  <div class="input-group-append">
                    <button class="btn btn-outline-warning calendar" (click)="s.toggle()" type="button"></button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="fundingType">Funding type</label>
                <select class="form-control" id="fundingTypeId" [(ngModel)]="funderModel.fundingTypeId"
                  name="fundingType" #fundingTypeId="ngModel" required>
                  <option *ngFor="let fType of fundingTypesList" [value]="fType.id">{{fType.fundingType}}</option>
                </select>
                <div *ngIf="projectFunderForm.submitted" [hidden]="fundingTypeId.valid" class="alert alert-danger">
                  Funding type is required
                </div>
              </div>

              <div class="form-group">
                <label for="documentUrl">Funding amount</label>
                <input type="text" pattern="^\d*(\.\d{0,2})?$" class="form-control" id="amount"
                  [(ngModel)]="funderModel.amount" (input)="calculateAmountInDefault(exRateFor.FUNDING)" name="amount" #amount="ngModel" maxlength="11" required>
                <div *ngIf="projectFunderForm.submitted" [hidden]="amount.value && amount.value > 0"
                  class="alert alert-danger">
                  Enter valid amount having optionally 2 decimal points
                </div>
              </div>

              <div class="form-group">
                <label for="currency">Funding currency</label>
                <select class="form-control" id="currency"
                  (change)="filterExRateSources(exRateFor.FUNDING)" [(ngModel)]="funderModel.currency" name="currency" #currency="ngModel"
                  required>
                  <option *ngFor="let currency of currencyList" [value]="currency.currency">
                    {{currency.currencyName}}
                  </option>
                </select>
                <div *ngIf="projectFunderForm.submitted" [hidden]="currency.valid" class="alert alert-danger">
                  Currency is required
                </div>
              </div>

              <div class="form-group">
                <label for="exrate-source">Ex. rate source</label>
                <select [class.disable]="funderModel.currency == null" class="form-control" id="esource" (change)="selectExRateSource(exRateFor.FUNDING)" [(ngModel)]="funderModel.exRateSource" 
                  name="esource" #esource="ngModel" required>
                  <option value="null">--Select ex-rate source</option>
                  <option *ngFor="let source of filteredExRateSources" [value]="source.id">{{source.value}}</option>
                </select>
                <div *ngIf="projectFunderForm.submitted" [hidden]="esource.valid" class="alert alert-danger">
                  Exchange rate source is required
                </div>
              </div>

              <div class="form-group">
                <label for="exchangeRate">Exchange rate of currency to 1 {{defaultCurrency}}</label>
                <input type="text" pattern="^\d*(\.\d{0,6})?$" [readonly]="isFundingExRateReadonly" class="form-control" id="exchangeRate"
                  [(ngModel)]="funderModel.exchangeRate" (input)="calculateAmountInDefault(exRateFor.FUNDING)" name="exchangeRate" maxlength="10"
                  #funderExchangeRate #exchangeRate="ngModel" required />
                <div *ngIf="funderModel.exRateDated">
                  <span class="text-warning"><i>Closest matching exchange rate found for the provided date is on {{formatToLongDate(funderModel.exRateDated)}}</i></span>
                </div>
                <div *ngIf="projectFunderForm.submitted" [hidden]="exchangeRate.valid" class="alert alert-danger">
                  Enter valid exchange rate for {{defaultCurrency}} (Max 6 decimal points)
                </div>
                <div [hidden]="defaultCurrency" class="alert alert-danger">
                  Default currency is not set. Please contact administrator
                </div>
              </div>

              <div class="form-group">
                <label for="calculated">Funding amount in {{defaultCurrency}}</label>
                <input type="number" [readonly]="true" class="form-control" id="calculatedAmount"
                  [(ngModel)]="funderModel.amountInDefaultCurrency" (input)="calculateAmountInDefault(1)" name="calculatedAmount" 
                  #calculatedAmount="ngModel" required />
              </div>

              <button [class.disable]="(isProjectFunderBtnDisabled || !defaultCurrency)"
                class="btn btn-lg btn-info btn-block text-uppercase" type="submit">Add Funder</button>
              <!--<button [class.disable]="isProjectFunderBtnDisabled"
                class="btn btn-lg btn-warning btn-block text-uppercase" type="button" (click)="resetFunderEntry()">Reset
                Funder</button>-->
            </form>

          </div>
        </div>

        <div class="card card-signin margin-top-5 margin-bottom-10">
          <div class="card-body iati-box">
            <h6 class="card-title text-center">Project funders</h6>
            <table class="table table-bordered table-condensed">
              <tr>
                <th>Funder</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Exchange rate</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let funder of currentProjectFundersList">
                <td>{{funder.funder}}</td>
                <td>{{funder.fundingType}}</td>
                <td>{{funder.amount | number:0}}</td>
                <td>{{funder.currency}}</td>
                <td>{{funder.exchangeRate}}</td>
                <td>
                  <button id="dfunder-{{activeProjectId}}-{{funder.funderId}}" (click)="deleteProjectFunder($event)"
                    class="btn btn-danger btn-sm">
                    <i id="ifunder-{{activeProjectId}}-{{funder.funderId}}" class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>

    </div>
    <!--End of data entry tab-->
    <!--End of Funders Tab-->

    <!--Implementers tab for projects-->
    <div *ngIf="currentTab == 'implementer'" class="row margin-top-50">

      <div *ngIf="isShowSources" class="col-md-6 col-sm-6">
        <div class="col-md-12">
          <div class="card card-signin my-5 margin-top-10">
            <div class="card-body">
              <h6 class="card-title text-center">Source projects</h6>

              <div class="iati-box">
                <table class="table table-bordered">
                  <tr *ngFor="let project of aimsProjects">
                    <td>
                      <b>Project title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="aproject-{{project.id}}" (click)="viewAIMSProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">AIMS</span>
                      <table *ngIf="project.implementers.length > 0" class="table table-bordered">
                        <tr>
                          <th>Implementer name</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let implementer of project.implementers">
                          <td>
                            {{implementer.implementer}}
                          </td>
                          <td [class.disable]="checkIfImplementerAdded(implementer.implementer)">
                            <button id="aImplementer-{{project.id}}-{{implementer.implementerId}}"
                              (click)="enterAIMSImplementer($event)" class="btn btn-success btn-sm">Enter >></button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.implementers.length == 0"><i>No implementers found.</i></div>
                    </td>
                  </tr>
                </table>
  
                <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
                  <tr *ngFor="let project of iatiProjects">
                    <td>
                      <b>Project Title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="iproject-{{project.id}}" (click)="viewIATIProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">IATI</span>
                      <table *ngIf="project.implementers.length > 0" class="table table-bordered">
                        <tr>
                          <th>Implementer Name</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let implementer of project.implementers">
                          <td>
                            {{implementer.name}}
                          </td>
                          <td [class.disable]="checkIfImplementerAdded(implementer.name)">
                            <button id="dimplementer-{{project.id}}-{{implementer.id}}"
                              (click)="enterIATIImplementer($event)" class="btn btn-success btn-sm">Enter >></button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.implementers.length == 0"><i>No implementers
                          found.</i></div>
                    </td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
        </div>

      </div>

      <!--Selected Implementers List-->
      <div [ngClass]="{'col-md-6': isShowSources, 'col-md-12': !isShowSources}">

        <div class="card card-signin my-5 margin-top-10 margin-bottom-10">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <span class="float-left">
                  <h6 class="card-title">Manage Implementers</h6>
                </span>
                <span class="float-right">
                  <button class="btn btn-info" class="btn btn-info btn-block text-uppercase" (click)="showSectors()"
                    type="button">Next >></button>
                </span>
              </div>
            </div>

            <div *ngIf="implementerEntryType == 'aims'" class="form-group">
              <div class="form-group">
                <form class="example-form" [formGroup]='implementerForm'>
                  <mat-form-field class="example-full-width margin-left-5">
                    <input matInput placeholder="Enter/Select Implementer" aria-label="Implementer"
                      [matAutocomplete]="auto" [formControl]="implementerInput">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayImplementer.bind(this)">
                      <mat-option *ngFor="let org of filteredImplementers | async" [value]="org">
                        <span>{{org.organizationName}}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
              </div>
            </div>

            <form class="form-signin"
              (ngSubmit)="projectImplementerForm.form.valid && saveProjectImplementer(projectImplementerForm)"
              #projectImplementerForm="ngForm">

              <!--<div *ngIf="implementerEntryType == 'aims'" class="form-group">
                <label for="implementerId">AIMS Implementers</label>
                <select class="form-control" id="implementerId" [(ngModel)]="implementerModel.implementerId"
                  name="implementerId" #implementerId="ngModel" required>
                  <option *ngFor="let implementer of organizationsList" [value]="implementer.id">
                    {{implementer.organizationName}}</option>
                </select>
                <div *ngIf="projectImplementerForm.submitted" [hidden]="implementerId.valid" class="alert alert-danger">
                  Implementer is required
                </div>
              </div>-->

              <div *ngIf="implementerEntryType == 'iati'" class="form-group">
                <label for="implementer">New Implementer</label>
                <input type="text" maxlength="200" class="form-control" id="implementer"
                  [(ngModel)]="implementerModel.implementer" name="implementer" readonly="readonly"
                  #implementer="ngModel" required>
                <div *ngIf="projectImplementerForm.submitted" [hidden]="implementer.valid" class="alert alert-danger">
                  Implementer Name is required
                </div>
              </div>

              <button [class.disable]="isProjectImplementerBtnDisabled"
                class="btn btn-lg btn-info btn-block text-uppercase" type="submit">Add Implementer</button>
              <!--<button [class.disable]="isProjectImplementerBtnDisabled"
                class="btn btn-lg btn-warning btn-block text-uppercase" type="button"
                (click)="resetImplementerEntry()">Reset Implementer</button>-->
            </form>

          </div>
        </div>

        <div class="card card-signin">
          <div class="card-body iati-box">
            <h6 class="card-title text-center">Project Implementers</h6>
            <table class="table table-bordered">
              <tr>
                <th>Implementer</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let implementer of currentProjectImplementersList">
                <td>{{implementer.implementer}}</td>
                <td>
                  <button id="dimplementer-{{activeProjectId}}-{{implementer.implementerId}}"
                    (click)="deleteProjectImplementer($event)" class="btn btn-danger btn-sm">
                    <i id="iimplementer-{{activeProjectId}}-{{implementer.implementerId}}" class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>

    </div>
    <!--End of data entry tab-->
    <!--End of Implementers Tab-->

    <!--Disbursements tab for projects-->
    <div *ngIf="currentTab == 'disbursement'" class="row margin-top-50">

      <div *ngIf="isShowSources" class="col-md-6 col-sm-6">
        <div  class="col-md-12">
          <div class="card card-signin my-5 margin-top-10">
            <div class="card-body">
              <h6 class="card-title text-center">Source projects</h6>

              <div class="iati-box">
                <table class="table table-bordered">
                  <tr *ngFor="let project of aimsProjects">
                    <td>
                      <b>Project Title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="aproject-{{project.id}}" (click)="viewAIMSProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">AIMS</span>
                      <table *ngIf="project.disbursements.length > 0" class="table table-bordered">
                        <tr>
                          <th>Month/Year</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let disbursement of project.disbursements">
                          <td>
                            {{disbursement.year}} / {{disbursement.month}}
                          </td>
                          <td>
                            {{disbursement.amount | number:0}}
                          </td>
                          <td>
                            <button id="adisbursement-{{project.id}}-{{disbursement.year}}-{{disbursement.month}}"
                              (click)="enterAIMSDisbursement($event)" class="btn btn-success btn-sm">Enter >></button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.disbursements.length == 0"><i>No disbursements available</i>
                      </div>
                    </td>
                  </tr>
                </table>
  
                <table *ngIf="iatiProjects.length > 0" class="table table-bordered">
                  <tr *ngFor="let project of iatiProjects">
                    <td>
                      <b>Project Title: </b>{{project.title | truncate}} <a href="#" title="View Project Details"
                        id="iproject-{{project.id}}" (click)="viewIATIProject($event)" class="text-info">View
                        Details</a><br />
                      <span class="badge badge-warning">IATI</span>
                      <table *ngIf="project.disbursementTransactions.length != 0" class="table table-bordered">
                        <tr>
                          <th>Type</th>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Dated</th>
                          <th>Action</th>
                        </tr>
                        <tr *ngFor="let disbursement of project.disbursementTransactions">
                          <td [ngClass]="{'bg-warning': !disbursement.transactionType}">
                            {{disbursement.transactionType}}
                          </td>
                          <td [ngClass]="{'bg-warning': !disbursement.currency}">
                            {{displayNull(disbursement.currency)}}
                          </td>
                          <td [ngClass]="{'bg-warning': !disbursement.amount}">
                            {{disbursement.amount | number:0}}
                          </td>
                          <td [ngClass]="{'bg-warning': !disbursement.dated}">
                            {{displayNull(disbursement.dated)}}
                          </td>
                          <td>
                            <button id="idisbursement-{{project.id}}-{{disbursement.id}}"
                              (click)="enterIATIDisbursement($event)" class="btn btn-success btn-sm">Enter >></button>
                          </td>
                        </tr>
                      </table>
                      <div class="text-info" *ngIf="project.transactions.length == 0"><i>No disbursements available</i>
                      </div>
                </table>
              </div>

            </div>
          </div>
        </div>

      </div>

      <!--Selected Disbursements List-->
      <div [ngClass]="{'col-md-6': isShowSources, 'col-md-12': !isShowSources}">

        <div class="card card-signin my-5 margin-top-10 margin-bottom-10">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <span class="float-left">
                  <h6 class="card-title">Manage disbursements</h6>
                </span>
                <span class="float-right">
                  <button class="btn btn-info" class="btn btn-info btn-block text-uppercase"
                    (click)="showCustomFields()" type="button">Next >></button>
                </span>
              </div>
            </div>
            <form class="form-signin"
              (ngSubmit)="disbursementForm.form.valid && saveProjectDisbursement(disbursementForm)"
              #disbursementForm="ngForm">

              <div class="form-group">
                <label for="title">Date </label>
                <div class="input-group">
                  <input class="form-control" [readonly]="true" placeholder="yyyy-mm-dd" type="text" name="dated"
                    [(ngModel)]="disbursementModel.dated" [maxDate]="calendarMaxDate" (dateSelect)="getExchangeRates(exRateFor.DISBURSEMENT)"
                    ngbDatepicker #dd="ngbDatepicker" required>
                  <div class="input-group-append">
                    <button class="btn btn-outline-warning calendar" (click)="dd.toggle()" type="button"></button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="text">Amount</label>
                <input type="text" pattern="^\d*(\.\d{0,2})?$" class="form-control" id="damount"
                  [(ngModel)]="disbursementModel.amount" name="damount" #damount="ngModel"
                  (input)="calculateAmountInDefault(exRateFor.DISBURSEMENT)" maxlength="11" required>
                <div *ngIf="disbursementForm.submitted" [hidden]="damount.valid" class="alert alert-danger">
                  Enter valid amount having optionally 2 decimal points
                </div>
              </div>

              <div class="form-group">
                <label for="currency">Currency</label>
                <select class="form-control" id="dcurrency"
                  [(ngModel)]="disbursementModel.currency" name="dcurrency" #dcurrency="ngModel"
                  (change)="filterExRateSources(exRateFor.DISBURSEMENT)" required>
                  <option *ngFor="let currency of currencyList" [value]="currency.currency">
                    {{currency.currencyName}}
                  </option>
                </select>
                <div *ngIf="disbursementForm.submitted" [hidden]="dcurrency.valid" class="alert alert-danger">
                  Currency is required
                </div>
              </div>

              <!--<div class="form-group">
                <label for="exrate-source">Ex. rate source</label>
                <select class="form-control" [class.disable]="disbursementModel.currency == null" id="desource" (change)="selectExRateSource(exRateFor.DISBURSEMENT)"
                  [(ngModel)]="disbursementModel.exRateSource" name="desource" #desource="ngModel" required>
                  <option *ngFor="let source of filteredExRateSources" [value]="source.id">{{source.value}}</option>
                </select>
                <div *ngIf="disbursementForm.submitted" [hidden]="desource.valid" class="alert alert-danger">
                  Exchange rate source is required
                </div>
              </div>-->

              <!--<div class="form-group">
                <label for="exchangeRate">Exchange rate of currency to 1 {{defaultCurrency}}</label>
                <input type="text" pattern="^\d*(\.\d{0,6})?$" (input)="calculateAmountInDefault(exRateFor.DISBURSEMENT)" [readonly]="isDisbursementExRateReadonly" pattern="^\d*(\.\d{0,6})?$" class="form-control" id="dexchangeRate"
                  [(ngModel)]="disbursementModel.exchangeRate" name="dexchangeRate" maxlength="10"
                  #disbursementExchangeRate #dexchangeRate="ngModel" required />
                  <div *ngIf="disbursementModel.exRateDated">
                    <span class="text-warning"><i>Closest matching exchange rate found for the provided date is on {{formatToLongDate(disbursementModel.exRateDated)}}</i></span>
                  </div>
                <div *ngIf="disbursementForm.submitted" [hidden]="dexchangeRate.valid" class="alert alert-danger">
                  Enter valid exchange rate for {{defaultCurrency}} (Max 6 decimal points)
                </div>
                <div [hidden]="defaultCurrency" class="alert alert-danger">
                  Default currency is not set. Please contact administrator
                </div>
              </div>-->

              <!--<div class="form-group">
                <label for="calculated">Funding amount in {{defaultCurrency}}</label>
                <input type="number" [readonly]="true" class="form-control" id="calculatedAmount"
                  [(ngModel)]="disbursementModel.amountInDefaultCurrency" (input)="calculateAmountInDefault(exRateFor.DISBURSEMENT)" name="calculatedAmount" 
                  #calculatedAmount="ngModel" required />
              </div>-->

              <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase"
                type="submit">Add
                disbursement</button>
              <!--<button [class.disable]="isBtnDisabled" (click)="resetDisbursementEntry()"
                class="btn btn-lg btn-warning btn-block text-uppercase" type="submit">Reset Disbursement</button>-->
            </form>

          </div>
        </div>

        <div class="card card-signin">
          <div class="card-body iati-box">
            <h6 class="card-title text-center">Project disbursements</h6>
            <table class="table table-bordered">
              <tr>
                <th>Dated</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Ex. rate</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let disbursement of currentProjectDisbursementsList">
                <td>{{formatDateUKStyle(disbursement.dated)}}</td>
                <td>{{disbursement.amount | number:0}}</td>
                <td>{{disbursement.currency}}</td>
                <td>{{disbursement.exchangeRate}}</td>
                <td>
                  <button id="ddis-{{activeProjectId}}-{{disbursement.id}}" (click)="deleteProjectDisbursement($event)"
                    class="btn btn-danger btn-sm">
                    <i id="idis-{{activeProjectId}}-{{disbursement.id}}" class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              <td colspan="5">
                <span class="float-left">
                  <span class="text-warning"><b>In {{defaultCurrency}}</b></span>&nbsp;
                  <b>Total funding: {{totalFunds}}</b>
                </span>
                <span class="float-right">
                  <b>Disbursements: {{totalDisbursements}}</b>
                </span>
              </td>
            </table>
          </div>
        </div>
      </div>

    </div>

    <!--Custom fields tab for projects-->
    <div *ngIf="currentTab == 'customFields'" class="row margin-top-50">
      <div class="col-md-6">
        <div *ngFor="let field of customFieldsList" class="card margin-top-10">
          <div class="card-header bg-info text-white">
            <h6>{{field.fieldTitle}}</h6>
          </div>
          <div class="card-body">
            <select class="form-control" *ngIf="checkFieldType(field.fieldType) == 'Dropdown'"
              [(ngModel)]="fieldModel.dropdownId" (change)="selectFieldValue(field.fieldType, -1, field.id, $event)"
              name="drp-{{field.id}}">
              <option value="null">--Select One--</option>
              <option *ngFor="let val of field.values" value="{{val.id}}">{{val.value}}</option>
            </select>

            <table *ngIf="checkFieldType(field.fieldType) == 'Radio'" class="table">
              <tr>
                <td *ngFor="let val of field.values">
                  <input type="radio" name="rd-options-{{field.id}}" id="rd-{{val.id}}" [value]="val.isSelected"
                    (click)="selectFieldValue(field.fieldType, val.id, field.id, $event)" />&nbsp;{{val.value}}
                </td>
              </tr>
            </table>

            <table *ngIf="checkFieldType(field.fieldType) == 'Checkbox'" class="table">
              <tr>
                <td *ngFor="let val of field.values">
                  <input type="checkbox" name="chk-{{val.id}}" value="{{val.id}}" [(ngModel)]="val.isSelected"
                    [checked]="checkIfSelected(field.id, val.id)"
                    (click)="selectFieldValue(field.fieldType, val.id, field.id, $event)" />&nbsp;{{val.value}}
                </td>
              </tr>
            </table>

            <div *ngIf="checkFieldType(field.fieldType) == 'Text'" class="form-group">
              <input class="form-control" type="text" maxlength="100" id="txt-{{fieldId}}"
                [(ngModel)]="fieldModel.newText"
                (blur)="selectFieldValue(field.fieldType, 1, field.id, $event, true)" />
            </div>

            <span class="float-right margin-top-10">
              <button class="btn btn-info btn-sm btn-block text-uppercase" (click)="saveProjectFields(field.id)"
                type="button">Save Fields</button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6">

        <div class="card card-signin my-5 margin-top-10">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <span class="float-left">
                  <h6 class="card-title">Project fields</h6>
                </span>
                <span class="float-right">
                  <button class="btn btn-info" class="btn btn-info btn-block text-uppercase"
                    (click)="showFinishProject()" type="button">Next >></button>
                </span>
              </div>
            </div>
            <table class="table table-bordered margin-top-10">
              <tr>
                <th>Field title</th>
                <th>Values</th>
                <th>Actions</th>
              </tr>
              <tr *ngFor="let field of currentProjectFieldsList">
                <td>{{field.fieldTitle}}</td>
                <td>{{displayFieldValues(field.values)}}</td>
                <td>
                  <button id="ffield-{{activeProjectId}}-{{field.customFieldId}}" (click)="deleteProjectField($event)"
                    class="btn btn-danger btn-sm">
                    <i id="ifield-{{activeProjectId}}-{{field.customFieldId}}" class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--End of data entry tab-->
    <!--End of Custom fields Tab-->

    <!--Finish tab for projects-->
    <div *ngIf="currentTab == 'finish'" class="row margin-top-50">

      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5 margin-top-10">
          <div class="card-body">
            <h6 class="card-title text-center">Finish Project</h6>

            <table class="table">
              <tr>
                <td>
                  <button class="btn btn-info btn-lg btn-block text-uppercase" (click)="viewCurrentProject()"
                    type="button">Review Project Detail</button>
                </td>
              </tr>

              <tr>
                <td>
                  <button class="btn btn-success btn-lg btn-block text-uppercase" (click)="finishProject()"
                    type="button">Finish Add/Edit Another</button>
                </td>
              </tr>

              <tr>
                <td>
                  <button class="btn btn-success btn-lg btn-block text-uppercase" (click)="deleteProject()"
                    type="button">Delete Project Request</button>
                </td>
              </tr>

              <tr>
                <td>
                  <button class="btn btn-warning btn-lg btn-block text-uppercase" (click)="goToHome()"
                    type="button">Finish
                    Project</button>
                </td>
              </tr>
            </table>

          </div>
        </div>
      </div>

    </div>
    <!--End of finish tab-->
    <!--End of data entry tab-->

  </block-ui>

</div>

<info-modal message="{{infoMessage}}"></info-modal>
<error-modal message="{{errorMessage}}"></error-modal>

<project-info-modal [project]="viewProject" [locations]="viewProjectLocations" [sectors]="viewProjectSectors"
  [funders]="viewProjectFunders" [disbursements]="viewProjectDisbursements" [implementers]="viewProjectImplementers" 
  [documents]="viewProjectDocuments" [markers]="viewProjectFields" [isShowContact]="isShowContact" [projectId]="aimsProjectId">
</project-info-modal>

<projecti-info-modal [project]="viewProject" [locations]="viewProjectLocations" [sectors]="viewProjectSectors"
  [funders]="viewProjectFunders" [implementers]="viewProjectImplementers" [documents]="viewProjectDocuments">
</projecti-info-modal>