<div class="container-fluid view-area">
  
<div class="row">
  <div class="col-md-6">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active btn-info" data-toggle="tab" >Locations list</a>
      </li>
      <li *ngIf="permissions.canEditLocation" class="nav-item">
        <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/manage-location']">Create new</a>
      </li>
    </ul>
  </div>
  <div class="col-md-6">
    <div class="input-group mb-3">
      <input type="text" class="form-control" placeholder="Enter location name" (input)="searchLocations()"
      [(ngModel)]="criteria" name="criteria" #criteriaModel="ngModel" onkeypress="return event.keyCode != 13;"
      maxlength="50" aria-describedby="basic-addon2">
      <div class="input-group-append">
        <button class="btn btn-outline-info" (click)="searchLocations()" type="button"><i class="fa fa-search"></i></button>
      </div>
    </div>
  </div>
</div>

<div class="row text-center" *ngIf="isLoading">
  <div class="col-md-12">
    <img src="assets/images/loader-md.gif" /><span>&nbsp;Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading" class="row">
  <div *ngIf="filteredLocationsList.length > 0" class="col margin-top-10">
    <table class="table table-bordered table-stripped">
      <tr>
        <th>Location name</th>
        <th *ngIf="permissions.canEditLocation">Actions</th>
      </tr>
      <tr *ngFor="let loc of filteredLocationsList | paginate: { itemsPerPage: pagingSize, currentPage: p }">
        <td>{{loc.location}}</td>
        <td *ngIf="permissions.canEditLocation">
          <a title="Edit" (click)="edit(loc.id)" class="btn btn-success"><i class="fa fa-edit color-white"></i></a>
          &nbsp;
          <a [class.disable]="loc.isUnAttributed" title="Delete" (click)="delete(loc.id)" class="btn btn-danger"><i class="fa fa-trash color-white"></i></a>
        </td>
      </tr>
    </table>
    <pagination-controls (pageChange)="p=$event"></pagination-controls>
  </div>
  <div *ngIf="filteredLocationsList.length == 0" class="col-md-12 text-info">
    <i>No locations to show</i>
  </div>
</div>
</div>
