import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funder-iati',
  templateUrl: './funder-iati.component.html',
  styleUrls: ['./funder-iati.component.css']
})
export class FunderIatiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
