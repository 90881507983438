<div class="container-fluid view-area">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/document-links']">Links to documents</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Create new</a>
          </li>
        </ul>
      </div>
      
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <div *ngIf="isError" class="alert alert-danger alert-dismissible">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Error!</strong> {{errorMessage}}
            </div>
            <h5 class="card-title text-center">Document link</h5>
  
            <form class="form-signin" (ngSubmit)="linkForm.form.valid && saveLink()" #linkForm="ngForm">
  
              <div class="form-group">
                <label for="title">Link title</label>
                <input type="text" class="form-control" id="title" [(ngModel)]="model.title" name="title"
                  #title="ngModel" maxlength="50" required>
                <div *ngIf="linkForm.submitted" [hidden]="title.valid" class="alert alert-danger">
                  Link title is required
                </div>
              </div>

              <div class="form-group">
                <label for="url">URL</label>
                <input type="url" class="form-control" id="url" [(ngModel)]="model.url" name="url"
                  #url="ngModel" required>
                <div *ngIf="linkForm.submitted" [hidden]="url.valid" class="alert alert-danger">
                  Url is required
                </div>
              </div>
  
              <button [class.disable]="isBtnDisabled" class="btn btn-lg btn-info btn-block text-uppercase" type="submit">{{btnText}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>