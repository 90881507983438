<div class="container-fluid view-area">

  <block-ui>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active btn-info" data-toggle="tab">Delete/Re-assing organization</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-info" data-toggle="tab" [routerLink]="['/organizations']">Organizations list</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <!--IATI Activity Box-->
      <div class="col-md-6 col-sm-6">
        <div class="card card-signin my-5">
          <div class="card-body iati-box">
            <h5 class="card-title text-center">Effected projects list</h5>
            <div *ngIf="isLoading">
              Loading projects...
            </div>
            <table *ngIf="projectsList.length > 0" class="table table-bordered">
              <tr *ngFor="let project of projectsList; let number = index">
                <td>
                  <span class="float-left">
                    <!--{{project.title | truncate}} <a href="#" title="View Project Details"
                      id="aproject-{{project.id}}" (click)="viewProjectDetail($event)" class="text-info">View
                      Details</a><br />-->
                      <span class="badge badge-info">{{number + 1}}</span> {{project.title}}
                  </span>
                </td>
              </tr>
            </table>
            <span *ngIf="projectsList.length == 0" class="text-info">
              No projects to show
            </span>
          </div>
        </div>
      </div>

      <!--Organizations box from AIMS-->
      <div class="col-md-6 col-sm-6">
        <div class="card card-signin my-5">
          <div class="card-body iati-box">
            <form class="form-signin" (ngSubmit)="organizationForm.form.valid && deleteAndMergeOrganization()"
              #organizationForm="ngForm">
              <div class="form-group">
                <label for="name">New mapping organization</label>
                <select class="form-control" id="organizationId" [(ngModel)]="model.organizationId"
                  name="organizationId" #organizationId="ngModel" required>
                  <option *ngFor="let org of organizationsList" [value]="org.id">
                    {{org.organizationName}}</option>
                </select>
                <!--<div *ngIf="organizationForm.submitted" [hidden]="organizationId.valid" class="alert alert-danger">
                  Organization is required
                </div>-->
              </div>

              <h5 class="card-title text-center">Delete confirmation</h5>
                <div class="form-group">
                  <p class="text-info">
                    You can assign the new organization to effected project/s. If you do not assign the project
                    and delete without assign, the selected organization will be deleted for all the effected projects.
                    Please review the settings before delete, as this action cannot be undone.
                  </p>
                </div>
                <button [class.disable]="isLoading" class="btn btn-lg btn-danger btn-block text-uppercase" type="submit">Delete Now</button>
              </form>
          </div>
        </div>
      </div>
    </div>

  </block-ui>
</div>

<error-modal message={{errorMessage}}></error-modal>
<project-info-modal [project]="viewProject" [locations]="viewProjectLocations" [sectors]="viewProjectSectors"
  [funders]="viewProjectFunders" [implementers]="viewProjectImplementers" [documents]="viewProjectDocuments">
</project-info-modal>